{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Buttons;

interface

uses
  Classes, SysUtils, WEBLib.Controls, Web, WEBLib.Graphics, WEBLib.StdCtrls,
  WEBlib.ComCtrls, WEBLib.ExtCtrls;

type

  TButtonLayout = (blGlyphLeft, blGlyphRight, blGlyphTop, blGlyphBottom);


  TCustomSpeedButton = class(TCustomControl)
  private
    FMaterialGlyph: TMaterialGlyph;
    FColor: TColor;
    FIntColor: TColor;
    FDown: boolean;
    FFlat: boolean;
    FGroupIndex: integer;
    FAllowAllUp: boolean;
    FGlyph: TURLPicture;
    FLayout: TButtonLayout;
    FGlyphURL: String;
    procedure SetMaterialGlyph(const AValue: TMaterialGlyph);
    procedure SetColorEx(const AValue: TColor);
    procedure SetIntColor(const AValue: TColor);
    procedure SetGroupIndex(const AValue: integer);
    procedure SetDown(const AValue: boolean);
    procedure SetFlat(const Value: boolean);
    procedure SetGlyph(const Value: TURLPicture);
    procedure SetGlyphURL(const Value: String);
  protected
    procedure SetCaption(const AValue: string); override;
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure UpdateGroup; virtual;
    procedure DoMouseLeave; override;
    procedure DoMouseEnter; override;
    procedure MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer); override;
    procedure GlyphChanged(Sender: TObject);
    property AllowAllUp: boolean read FAllowAllUp write FAllowAllUp;
    property Color: TColor read FColor write SetColorEx;
    property IntColor: TColor read FIntColor write SetIntColor;
    property Down: boolean read FDown write SetDown;
    property Flat: boolean read FFlat write SetFlat default false;
    property Glyph: TURLPicture read FGlyph write SetGlyph;
    property GlyphURL: String read FGlyphURL write SetGlyphURL;
    property GroupIndex: integer read FGroupIndex write SetGroupIndex;
    property Layout: TButtonLayout read FLayout write FLayout default blGlyphLeft;
    property MaterialGlyph: TMaterialGlyph read FMaterialGlyph write SetMaterialGlyph;
  public
    destructor Destroy; override;
    procedure CreateInitialize; override;
    function CanFocus: Boolean; override;
  end;

  TSpeedButton = class(TCustomSpeedButton)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property AllowAllUp;
    property Caption;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Flat;
    property Glyph;
    property GlyphURL;
    property GroupIndex;
    property Height;
    property Hint;
    property Layout;
    property Margins;
    property MaterialGlyph;
    property ShowHint;
    property Visible;
    property Width;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;


  TWebSpeedButton = class(TSpeedButton);

  TCustomBitBtn = class(TCustomControl)
  private
    FMaterialGlyph: TMaterialGlyph;
    FLayout: TButtonLayout;
    FFlat: boolean;
    FGlyph: TURLPicture;
    FColor: TColor;
    FIntColor: TColor;
    FAlignment: TAlignment;
    FGlyphURL: String;
    procedure SetFlat(const Value: boolean);
    procedure SetGlyph(const Value: TURLPicture);
    procedure SetLayout(const Value: TButtonLayout);
    procedure SetMaterialGlyph(const Value: TMaterialGlyph);
    procedure SetColorEx(const Value: TColor);
    procedure SetIntColor(const Value: TColor);
    procedure SetAlignment(const Value: TAlignment);
    procedure SetGlyphURL(const Value: String);
  protected
    procedure SetCaption(const AValue: string); override;
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure GlyphChanged(Sender: TObject);
    property Alignment: TAlignment read FAlignment write SetAlignment default taCenter;
    property Color: TColor read FColor write SetColorEx;
    property IntColor: TColor read FIntColor write SetIntColor;
    property Flat: boolean read FFlat write SetFlat default false;
    property Layout: TButtonLayout read FLayout write SetLayout default blGlyphLeft;
    property MaterialGlyph: TMaterialGlyph read FMaterialGlyph write SetMaterialGlyph;
    property Glyph: TURLPicture read FGlyph write SetGlyph;
    property GlyphURL: String read FGlyphURL write SetGlyphURL;
  public
    destructor Destroy; override;
    procedure CreateInitialize; override;
  end;

  TBitBtn = class(TCustomBitBtn)
  published
    property Align;
    property AlignWithMargins;
    property Alignment;
    property Anchors;
    property Caption;
    property Color;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Flat;
    property Glyph;
    property GlyphURL;
    property Height;
    property Hint;
    property Layout;
    property MaterialGlyph;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Visible;
    property Width;
    property OnClick;
    property OnDblClick;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebBitBtn = class(TBitBtn);

  TCustomToolBar = class(TCustomControl)
  protected
    function CreateElement: TJSElement; override;
  public
    procedure CreateInitialize; override;
    property Color default $CFCFCF;
  end;

  TToolBar = class(TCustomToolBar)
  published
    property Align;
    property AlignWithMargins;
    property Color;
    property Enabled;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Height;
    property Hint;
    property Left;
    property Margins;
    property ShowHint;
    property Top;
    property Visible;
    property Width;
    property OnClick;
    property OnDblClick;
  end;

  TWebToolBar = class(TToolBar);


  TRichEditBtn = (reFont, reFontSize, reBold, reItalic, reUnderline, reStrikeThrough,
    reAlignLeft, reAlignCenter, reAlignRight, reUnorderedList, reOrderedList, reForegroundColor, reBackgroundColor);

  TRichEditButtonSet = set of TRichEditBtn;

  TRichEditToolBar = class(TToolBar)
  private
    FBold: TSpeedButton;
    FItalic: TSpeedButton;
    FUnderline: TSpeedButton;
    FStrikeThrough: TSpeedButton;
    FAlignLeft: TSpeedButton;
    FAlignCenter: TSpeedButton;
    FAlignRight: TSpeedButton;
    FUList: TSpeedButton;
    FOList: TSpeedButton;
    FRichEdit: TRichEdit;
    FFnt: TFontPicker;
    FFntSize: TFontSizePicker;
    FFgClr: TColorPicker;
    FBkClr: TColorPicker;
    FOnSpeedButtonClick: TNotifyEvent;
    FTextColor: TColor;
    FBackgroundColor: TColor;
    FVisibleButtons: TRichEditButtonSet;
    FHints: TStrings;
    procedure SetRichEdit(const AValue: TRichEdit);
    function GetRichEdit: TRichEdit;
    procedure SetTextColor(const Value: TColor);
    procedure SetBackgroundColor(const Value: TColor);
    procedure SetVisibleButtons(const Value: TRichEditButtonSet);
    procedure SetHints(const Value: TStrings);
  protected
    procedure CreateButton(var btn: TSpeedButton; BtnID, Glyph, Hint: string);
    procedure HandleSpeedButtonClick(Sender: TObject); virtual;
    procedure FgSelect(Sender: TObject);
    procedure BkSelect(Sender: TObject);
    procedure FntChange(Sender: TObject);
    procedure FntSizeChange(Sender: TObject);
    procedure UpdateButtons; virtual;
    procedure Loaded; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    property BackgroundColor: TColor read FBackgroundColor write SetBackgroundColor;
    property FontPicker: TFontPicker read FFnt;
    property FontSizePicker: TFontSizePicker read FFntSize;
  published
    property Hints: TStrings read FHints write SetHints;
    property RichEdit: TRichEdit read GetRichEdit write SetRichEdit;
    property TextColor: TColor read FTextColor write SetTextColor;
    property VisibleButtons: TRichEditButtonSet read FVisibleButtons write SetVisibleButtons;
    property OnSpeedButtonClick: TNotifyEvent read FOnSpeedButtonClick write FOnSpeedButtonClick;
  end;

  TWebRichEditToolBar = class(TRichEditToolBar);

  TToggleButtonStyle = (tsRectangular, tsRounded);

  TToggleButton = class(TCustomControl)
  private
    FChecked: boolean;
    FLabel: TJSHTMLElement;
    FInput: TJSHTMLElement;
    FSlider: TJSHTMLElement;
    FStyle: TToggleButtonStyle;
    procedure SetStyle(const Value: TToggleButtonStyle);
    function GetChecked: boolean;
    procedure SetChecked(const Value: boolean);
  protected
    function HandleDoClick(Event: TJSMouseEvent): Boolean; override;
    procedure UpdateElement; override;
    function CreateElement: TJSElement; override;
    procedure Loaded; override;
  public
    procedure CreateInitialize; override;
  published
    property Checked: boolean read GetChecked write SetChecked;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Style: TToggleButtonStyle read FStyle write SetStyle default tsRectangular;
    property Visible;
    property OnClick;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnEnter;
    property OnExit;
  end;

  TWebToggleButton = class(TToggleButton);


implementation

uses
  WEBLib.WebTools;

// https://stackoverflow.com/questions/1720320/how-to-dynamically-create-css-class-in-javascript-and-apply
// https://www.w3schools.com/howto/howto_css_switch.asp

{ TCustomSpeedButton }

procedure TCustomSpeedButton.CreateInitialize;
begin
  inherited;
  FColor := clNone;
  FIntColor := clNone;
  FGroupIndex := 0;
  FDown := false;
  FFlat := false;
  FGlyph := TURLPicture.Create;
  FGlyph.OnChange := GlyphChanged;
  FGlyph.OnDataChange := GlyphChanged;
  FLayout := blGlyphLeft;
end;

function TCustomSpeedButton.CanFocus: Boolean;
begin
  Result := false;
end;

function TCustomSpeedButton.CreateElement: TJSElement;
begin
  if FFlat then
    Result := document.createElement('SPAN')
  else
    Result := document.createElement('BUTTON');
end;

procedure TCustomSpeedButton.SetCaption(const AValue: string);
begin
  if (Caption <> AValue) then
  begin
    inherited SetCaption(AValue);
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetColorEx(const AValue: TColor);
begin
  if FColor <> AValue then
  begin
    FColor := AValue;
    FIntColor := AValue;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetDown(const AValue: boolean);
begin
  if (FDown <> AValue) then
  begin
    FDown := AValue;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetFlat(const Value: boolean);
begin
  if (FFlat <> Value) then
  begin
    FFlat := Value;
    RecreateElement;
  end;
end;

procedure TCustomSpeedButton.SetIntColor(const AValue: TColor);
begin
  if FIntColor <> AValue then
  begin
    FIntColor := AValue;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetMaterialGlyph(const AValue: TMaterialGlyph);
begin
  if FMaterialGlyph <> AValue then
  begin
    FMaterialGlyph := AValue;
    UpdateElement;
  end;
end;

procedure TCustomSpeedButton.SetGlyph(const Value: TURLPicture);
begin
  FGlyph.Assign(Value);
  UpdateElement;
end;

procedure TCustomSpeedButton.SetGlyphURL(const Value: String);
begin
  if FGlyphURL <> Value then
  begin
    FGlyphURL := Value;
    RecreateElement;
  end;
end;

procedure TCustomSpeedButton.SetGroupIndex(const AValue: integer);
begin
  FGroupIndex := AValue;
end;

procedure TCustomSpeedButton.UpdateElement;
var
  s,inactive,noptr,icn,imgsrc: string;
  clr: TColor;
begin
  inherited;

  if not IsUpdating and (MaterialGlyph <> '') then
    AddControlLink('googlematerial', 'https://fonts.googleapis.com/icon?family=Material+Icons');

  if Assigned(ElementHandle) then
  begin
    if Down then
      clr := clGray
    else
      clr := FIntColor;

    if FFlat then
    begin
      if clr <> clNone then
        ElementHandle.style.setProperty('background-color', ColorToHTML(clr))
      else
        ElementHandle.style.setProperty('background-color', '');
    end;

    ElementHandle.style.setProperty('vertical-align','middle');

//    if Visible then
//      ElementHandle.style.setProperty('display','table');

    ElementHandle.style.setProperty('overflow','hidden');
    ElementHandle.style.setProperty('padding','0px');
    ElementHandle.style.setProperty('text-align','center');

    s := '';
    inactive := '';
    noptr := '';

    if not Enabled then
      inactive := ' md-light md-inactive';

    noptr := 'pointer-events:none';

    if FMaterialGlyph <> '' then
      icn := '<i class="material-icons'+ inactive +'" style="'+noptr+'">' + FMaterialGlyph + '</i>';

    if FGlyph.Data <> '' then
      imgsrc :=  'data:image/jpeg;base64,' + HexImageDecodeAsBase64(FGlyph.Data)
    else
    if FGlyph.FileName <> '' then
      imgsrc := FGlyph.FileName
    else
    if FGlyphURL <> '' then
      imgsrc := FGlyphURL;

    if imgsrc <> '' then
      icn := '<img style="'+noptr+';vertical-align:middle;" src="'+ imgsrc + '">';

    case Layout of
    blGlyphLeft: s := '<table border="0" align="center" style="'+noptr+'"><tr><td>'+icn+'</td><td>'+Caption+'</td></tr></table>';
    blGlyphRight:  s := '<table border="0" align="center" style="'+noptr+'"><tr><td>'+Caption+'</td><td>'+icn+'</td></tr></table>';
    blGlyphTop: s := '<table border="0" align="center" style="'+noptr+'"><tr><td>'+icn+'</td></tr><tr><td>'+Caption+'</td></tr></table>';
    blGlyphBottom:  s := '<table border="0" align="center" style="'+noptr+'"><tr><td>'+Caption+'</td></tr><tr><td>'+icn+'</td></tr></table>';
    end;

    ElementHandle.innerHTML := s;
  end;
end;

procedure TCustomSpeedButton.UpdateGroup;
var
  i: integer;
begin
  if Assigned(Parent) then
  begin
    for i := 0 to Parent.ControlCount - 1 do
    begin
      if (Parent.Controls[i] <> Self) and (Parent.Controls[i] is TSpeedButton) and ((Parent.Controls[i] as TSpeedButton).GroupIndex = GroupIndex) then
        (Parent.Controls[i] as TSpeedButton).Down := false;
    end;
  end;
end;

procedure TCustomSpeedButton.DoMouseLeave;
begin
  inherited;
  IntColor := FColor;
end;

procedure TCustomSpeedButton.GlyphChanged(Sender: TObject);
begin
  UpdateElement;
end;

destructor TCustomSpeedButton.Destroy;
begin
  FGlyph.Free;
  inherited;
end;

procedure TCustomSpeedButton.DoMouseEnter;
begin
  inherited;
  if Enabled then
    IntColor := clSilver;
end;

procedure TCustomSpeedButton.MouseUp(Button: TMouseButton; Shift: TShiftState; X,Y: Integer);
begin
  inherited;
  if Enabled then
    IntColor := clSilver;
end;

procedure TCustomSpeedButton.MouseDown(Button: TMouseButton; Shift: TShiftState; X,Y: Integer);
begin
  inherited;
  if not Enabled then
    Exit;

  IntColor := clGray;

  if GroupIndex > 0 then
  begin
    if Down and AllowAllUp then
      Down := false
    else
    begin
      Down := true;
      UpdateGroup;
    end;
  end;
end;

{ TCustomToolBar }

function TCustomToolBar.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

procedure TCustomToolBar.CreateInitialize;
begin
  inherited;
  Color := $CFCFCF;
  ControlStyle := ControlStyle + [csAcceptsControls];
end;

{ TRichEditToolBar }

procedure TRichEditToolBar.CreateInitialize;
begin
  inherited;
  Width := 24*11 + 150 + 50;

  FFnt := TFontPicker.Create(Self);
  FFnt.Parent := Self;
  FFnt.Width := 150;
  FFnt.Align := alLeft;
  FFnt.OnChange := FntChange;

  FFntSize := TFontSizePicker.Create(Self);
  FFntSize.Parent := Self;
  FFntSize.Width := 50;
  FFntSize.Align := alLeft;
  FFntSize.PickerMode := fmRelativeSize;
  FFntSize.OnChange := FntSizeChange;

  FHints := TStringList.Create;
  FHints.Add('Bold');
  FHints.Add('Italic');
  FHints.Add('Underline');
  FHints.Add('Strike Throught');

  FHints.Add('Text Color');
  FHints.Add('Background Color');
  FHints.Add('Align lef');
  FHints.Add('Align center');
  FHints.Add('Align right');
  FHints.Add('Numbered list');
  FHints.Add('List');

  CreateButton(FBold, ElementID+'_bold', '&#xE238;',FHints.Strings[0]);
  CreateButton(FItalic, ElementID+'_italic', '&#xE23F;',FHints.Strings[1]);
  CreateButton(FUnderline, ElementID+'_underline', '&#xE249;',FHints.Strings[2]);
  CreateButton(FStrikeThrough, ElementID+'_strikethrough', '&#xE257;',FHints.Strings[3]);

  FFgClr := TColorPicker.Create(Self);
  FFgClr.Parent := Self;
  FFgClr.Align := alLeft;
  FFgClr.Hint := FHints.Strings[4];
  FFgClr.Width := 32;
  FFgClr.Height := 24;
  FFgClr.OnSelect := FgSelect;

  FBkClr := TColorPicker.Create(Self);
  FBkClr.Parent := Self;
  FBkClr.Align := alLeft;
  FBkClr.Hint := FHints.Strings[5];
  FBkClr.Width := 32;
  FBkClr.Height := 24;
  FBkClr.OnSelect := BkSelect;

  CreateButton(FAlignLeft, ElementID+'_alignleft', '&#xE236;',FHints.Strings[6]);
  CreateButton(FAlignCenter, ElementID+'_aligncenter', '&#xE234;',FHints.Strings[7]);
  CreateButton(FAlignRight, ElementID+'_alignright', '&#xE237;',FHints.Strings[8]);

  CreateButton(FOList, ElementID+'_olist','&#xE242;',FHints.Strings[9]);
  CreateButton(FUList, ElementID+'_ulist','&#xE241;',FHints.Strings[10]);

  FVisibleButtons := [reFont, reFontSize, reBold, reItalic, reUnderline, reStrikeThrough,
    reAlignLeft, reAlignCenter, reAlignRight, reUnorderedList, reOrderedList, reForegroundColor, reBackgroundColor];

  RichEdit := nil;
end; 

destructor TRichEditToolBar.Destroy;
begin
  FHints.Free;
  inherited;
end;

procedure TRichEditToolBar.CreateButton(var btn: TSpeedButton; BtnID, Glyph, Hint: string);
begin
  btn := TSpeedButton.Create(BtnID);
  btn.Parent := Self;
  btn.MaterialGlyph := Glyph;
  btn.Width := 32;
  btn.Height := 24;
  btn.Align := alLeft;
  btn.Flat := true;
  btn.Hint := Hint;
  btn.ShowHint := Hint <> '';
  btn.Enabled := true;
  btn.OnClick := HandleSpeedButtonClick;
end;

procedure TRichEditToolBar.SetBackgroundColor(const Value: TColor);
begin
  FBackgroundColor := Value;
  FBkClr.Color := Value;
end;

procedure TRichEditToolBar.SetHints(const Value: TStrings);
begin
  FHints.Assign(Value);
end;

procedure TRichEditToolBar.SetRichEdit(const AValue: TRichEdit);
begin
  if Assigned(AValue) then
  begin
    FRichEdit := AValue;
    FRichEdit.Owner := Self;
  end;
end;

procedure TRichEditToolBar.SetTextColor(const Value: TColor);
begin
  FTextColor := Value;
  FFgClr.Color := Value;
end;

procedure TRichEditToolBar.SetVisibleButtons(const Value: TRichEditButtonSet);
begin
  FVisibleButtons := Value;
  UpdateButtons;
end;

procedure TRichEditToolBar.UpdateButtons;
begin
  if IsUpdating then
    Exit;

  FBold.Visible := reBold in FVisibleButtons;
  FItalic.Visible := reItalic in VisibleButtons;
  FUnderline.Visible := reUnderline in VisibleButtons;
  FStrikeThrough.Visible := reStrikeThrough in VisibleButtons;
  FAlignLeft.Visible := reAlignLeft in VisibleButtons;
  FAlignCenter.Visible := reAlignCenter in VisibleButtons;
  FAlignRight.Visible := reAlignRight in VisibleButtons;
  FUList.Visible := reUnorderedList in VisibleButtons;
  FOList.Visible := reOrderedList in VisibleButtons;
  FFnt.Visible := reFont in VisibleButtons;
  FFntSize.Visible := reFontSize in VisibleButtons;
  FFgClr.Visible := reForegroundColor in VisibleButtons;
  FBkClr.Visible := reBackgroundColor in VisibleButtons;

  while FHints.Count < 13 do
    FHints.Add('');

  FBold.Hint := FHints.Strings[0];
  FItalic.Hint := FHints.Strings[1];
  FUnderline.Hint := FHints.Strings[2];
  FStrikeThrough.Hint := FHints.Strings[3];
  FAlignLeft.Hint := FHints.Strings[4];
  FAlignCenter.Hint := FHints.Strings[5];
  FAlignRight.Hint := FHints.Strings[6];
  FUList.Hint := FHints.Strings[7];
  FOList.Hint := FHints.Strings[8];
  FFgClr.Hint := FHints.Strings[9];
  FBkClr.Hint := FHints.Strings[10];
end;

function TRichEditToolBar.GetRichEdit: TRichEdit;
begin
  Result := FRichEdit;
end;

procedure TRichEditToolBar.FgSelect(Sender: TObject); 
begin
  RichEdit.SelAttributes.Color := FFgClr.Color;
end;

procedure TRichEditToolBar.BkSelect(Sender: TObject); 
begin
  RichEdit.SelAttributes.BackColor := FBkClr.Color;
end;

procedure TRichEditToolBar.FntChange(Sender: TObject);
begin
  RichEdit.SelAttributes.Name := FFnt.Items[FFnt.ItemIndex];
end;

procedure TRichEditToolBar.FntSizeChange(Sender: TObject);
begin
  RichEdit.SelAttributes.Height := FFntSize.ItemIndex + 1;
end;

procedure TRichEditToolBar.HandleSpeedButtonClick(Sender: TObject);
var
  BID: string;
begin
  if Assigned(RichEdit) then
  begin
    BID := TControl(Sender).GetID;

    if BID = GetID + '_bold' then
    begin
      if fsBold in RichEdit.SelAttributes.Style then
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style - [fsBold]
      else
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style + [fsBold];
    end
    else if BID = GetID + '_italic' then
    begin
      if fsItalic in RichEdit.SelAttributes.Style then
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style - [fsItalic]
      else
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style + [fsItalic];
    end
    else if BID = GetID + '_underline' then
    begin
      if fsUnderline in RichEdit.SelAttributes.Style then
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style - [fsUnderline]
      else
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style + [fsUnderline];
    end
    else if BID = GetID + '_strikethrough' then
    begin
      if fsStrikeOut in RichEdit.SelAttributes.Style then
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style - [fsStrikeOut]
      else
        RichEdit.SelAttributes.Style := RichEdit.SelAttributes.Style + [fsStrikeOut];
    end
    else if BID = GetID + '_alignleft' then
      RichEdit.SelAttributes.Alignment := taleftJustify
    else if BID = GetID + '_aligncenter' then
      RichEdit.SelAttributes.Alignment := taCenter
    else if BID = GetID + '_alignright' then
      RichEdit.SelAttributes.Alignment := taRightJustify
    else if BID = GetID + '_olist' then
      RichEdit.SelAttributes.OrderedList := not RichEdit.SelAttributes.OrderedList
    else if BID = GetID + '_ulist' then
      RichEdit.SelAttributes.UnOrderedList := not RichEdit.SelAttributes.UnOrderedList;
  end;

  if Assigned(OnSpeedButtonClick) then
    OnSpeedButtonClick(Self);
end;


procedure TRichEditToolBar.Loaded;
begin
  inherited;
  UpdateButtons;
end;

{ TToggleButton }

function TToggleButton.CreateElement: TJSElement;
begin
  Result := document.createElement('span');
  FLabel := TJSHTMLElement(document.createElement('label'));
  FInput := TJSHTMLElement(document.createElement('input'));
  FSlider := TJSHTMLElement(document.createElement('span'));
  FInput.setAttribute('type','checkbox');

  Result.appendChild(FLabel);
  FLabel.appendChild(FInput);
  FLabel.appendChild(FSlider);
end;

procedure TToggleButton.CreateInitialize;
var
  css: string;
begin
  inherited;

  css := '.switch {'+
    'position: relative;'+
    'display: inline-block;'+
    'width: 44px;'+
    'height: 22px;'+
  '}'+

  '.switch input {display:none;}'+

  '.slider {'+
    'position: absolute;'+
    'cursor: pointer;'+
    'top: 0;'+
    'left: 0;'+
    'right: 0;'+
    'bottom: 0;'+
    'background-color: #ccc;'+
    '-webkit-transition: .4s;'+
    'transition: .4s;'+
  '}'+

  '.slider:before {'+
    'position: absolute;'+
    'content: "";'+
    'height: 14px;'+
    'width: 14px;'+
    'left: 4px;'+
    'bottom: 4px;'+
    'background-color: white;'+
    '-webkit-transition: .4s;'+
    'transition: .4s;'+
  '}'+

  'input:checked + .slider {'+
    'background-color: #2196F3;'+
  '}'+

  'input:focus + .slider {'+
    'box-shadow: 0 0 1px #2196F3;'+
  '}'+

  'input:checked + .slider:before {'+
    '-webkit-transform: translateX(22px);'+
    '-ms-transform: translateX(22px);'+
    'transform: translateX(22px);'+
  '}'+

  '/* Rounded sliders */'+
  '.slider.round {'+
    'border-radius: 22px;'+
  '}'+

  '.slider.round:before {'+
    'border-radius: 50%;'+
  '}';

  AddControlStyle(css);

  Width := 48;
  Height := 24;
end;

function TToggleButton.GetChecked: boolean;
begin
  if Assigned(Container) then
    FChecked := TJSHTMLInputElement(FInput).checked;

  Result := FChecked;
end;

function TToggleButton.HandleDoClick(Event: TJSMouseEvent): Boolean;
begin
  ElementEvent := Event;
  StopPropagation;
  if Event.target  = FInput then
    Click;
  Result := True;
  ElementEvent := nil;
end;

procedure TToggleButton.Loaded;
begin
  inherited;
end;

procedure TToggleButton.SetChecked(const Value: boolean);
begin
  FChecked := Value;
  UpdateElement;
end;

procedure TToggleButton.SetStyle(const Value: TToggleButtonStyle);
begin
  if (FStyle <> Value) then
  begin
    FStyle := Value;
    UpdateElement;
  end;
end;

procedure TToggleButton.UpdateElement;
begin
  inherited;
  if not IsUpdating then
  begin
    InjectCSS;

    FLabel.setAttribute('class','switch');

    TJSHTMLInputElement(FInput).checked := FChecked;

    if Style = tsRectangular then
      FSlider.setAttribute('class','slider')
    else
      FSlider.setAttribute('class','slider round');
  end;
end;

{ TCustomBitBtn }

//constructor TCustomBitBtn.Create(AOwner: TComponent);
//begin
//  inherited Create(AOwner);
//end;

function TCustomBitBtn.CreateElement: TJSElement;
begin
  if FFlat then
    Result := document.createElement('SPAN')
  else
    Result := document.createElement('BUTTON');
end;

procedure TCustomBitBtn.CreateInitialize;
begin
  inherited;
  FColor := clNone;
  FIntColor := clNone;
  FFlat := false;
  Width := 80;
  Height := 25;
  FAlignment := taCenter;
  FGlyph := TURLPicture.Create;
  FGlyph.OnChange := GlyphChanged;
  FGlyph.OnDataChange := GlyphChanged;
end;

destructor TCustomBitBtn.Destroy;
begin
  FGlyph.Free;
  inherited;
end;

procedure TCustomBitBtn.GlyphChanged(Sender: TObject);
begin
  UpdateElement;
end;


procedure TCustomBitBtn.SetCaption(const AValue: string);
begin
  if (Caption <> AValue) then
  begin
    inherited SetCaption(AValue);
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetColorEx(const Value: TColor);
begin
  if (FColor <> Value) then
  begin
    FColor := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetFlat(const Value: boolean);
begin
  if (FFlat <> Value) then
  begin
    FFlat := Value;
    RecreateElement;
  end;
end;

procedure TCustomBitBtn.SetGlyph(const Value: TURLPicture);
begin
  FGlyph.Assign(Value);
  UpdateElement;
end;

procedure TCustomBitBtn.SetGlyphURL(const Value: String);
begin
  if FGlyphURL <> Value then
  begin
    FGlyphURL := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetIntColor(const Value: TColor);
begin
  FIntColor := Value;
end;

procedure TCustomBitBtn.SetAlignment(const Value: TAlignment);
begin
  if (FAlignment <> Value) then
  begin
    FAlignment := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetLayout(const Value: TButtonLayout);
begin
  if (FLayout <> Value) then
  begin
    FLayout := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.SetMaterialGlyph(const Value: TMaterialGlyph);
begin
  if (FMaterialGlyph <> Value) then
  begin
    FMaterialGlyph := Value;
    UpdateElement;
  end;
end;

procedure TCustomBitBtn.UpdateElement;
var
  s,inactive,noptr,matgl,imgsrc: string;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if (Color <> clNone) and not Flat then
      ElementHandle.style.setProperty('background-color', ColorToHTML(Color))
    else
      ElementHandle.style.setProperty('background-color', '');

    ElementHandle.style.setProperty('vertical-align','middle');
    if Visible then
      ElementHandle.style.setProperty('display','table');
    ElementHandle.style.setProperty('overflow','hidden');
    ElementHandle.style.setProperty('padding','0px');

    case Alignment of
      taLeftJustify: ElementHandle.style.setProperty('text-align','left');
      taCenter: ElementHandle.style.setProperty('text-align','center');
      taRightJustify: ElementHandle.style.setProperty('text-align','right');
    end;

    s := '';
    inactive := '';
    noptr := '';
    matgl := '';

    if not Enabled then
      inactive := ' md-light md-inactive';

    noptr := 'pointer-events:none';

    if FMaterialGlyph <> '' then
      matgl := '<i class="material-icons'+ inactive +'" style="vertical-align:middle;'+noptr+'">' + FMaterialGlyph + '</i>';

//    if (FMaterialGlyph <> '') and (Caption <> '') then
//      s := s + '&nbsp;';

    if (Caption <> '') then
      s := '<span style="'+noptr+'">' + Caption + '</span>';

    if FGlyph.Data <> '' then
      imgsrc :=  'data:image/jpeg;base64,' + HexImageDecodeAsBase64(FGlyph.Data)
    else
    if FGlyph.FileName <> '' then
      imgsrc := FGlyph.FileName
    else
    if FGlyphURL <> '' then
      imgsrc := FGlyphURL;

    case Layout of
    blGlyphLeft:
      begin
        if matgl <> '' then
          s := matgl + '&nbsp;' + s;

        if imgsrc <> '' then
          s := '<img style="vertical-align:middle;" src="'+ imgsrc + '">&nbsp;' + s;
      end;

    blGlyphRight:
      begin
        if matgl <> '' then
          s := s + '&nbsp;' + matgl;

        if imgsrc <> '' then
           s := s+ '&nbsp;<img src="'+ imgsrc + '">';
      end;

    blGlyphTop:
      begin
        if matgl <> '' then
          s := matgl + '<br>' + s;

        if imgsrc <> '' then
           s := '<img src="'+ imgsrc + '"><br>' + s;
      end;

    blGlyphBottom:
      begin
        if matgl <> '' then
          s := s+ '<br>' + matgl;

        if imgsrc <> '' then
           s := s + '<br><img src="'+ imgsrc + '">';
      end;
    end;

    ElementHandle.innerHTML := s;
  end;
end;

end.
