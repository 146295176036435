unit Bcl.Json.Common;

{$I bcl.inc}

interface

uses
  Types,
  {$IFDEF PAS2JS}
  JS;
  {$ELSE}
  Bcl.Json.Classes;
  {$ENDIF}

type
{$IFDEF PAS2JS}
  TJElement = JSValue;
  TJObject = TJSObject;
  TJArray = TJSArray;
{$ELSE}
  TJElement = Bcl.Json.Classes.TJElement;
  TJObject = Bcl.Json.Classes.TJObject;
  TJArray = Bcl.Json.Classes.TJArray;
{$ENDIF}

function JValue(const Value: string): TJElement; overload;
function JValue(const Value: Integer): TJElement; overload;
{$IFNDEF PAS2JS}
function JValue(const Value: Int64): TJElement; overload;
{$ENDIF}
function JValue(const Value: Double): TJElement; overload;
function JValue(const Value: Boolean): TJElement; overload;

function JToInt(Value: TJElement): Integer;
function JToStr(Value: TJElement): string;
function JToBool(Value: TJElement): Boolean;
function AsJObject(Value: TJElement): TJObject;
function AsJArray(Value: TJElement): TJArray;

function JObjectKeys(Value: TJObject): TStringDynArray;

implementation

function JValue(const Value: string): TJElement; overload;
begin
  {$IFDEF PAS2JS}
  Result := Value;
  {$ELSE}
  Result := TJPrimitive.Create(Value);
  {$ENDIF}
end;

function JValue(const Value: Integer): TJElement; overload;
begin
  {$IFDEF PAS2JS}
  Result := Value;
  {$ELSE}
  Result := TJPrimitive.Create(Value);
  {$ENDIF}
end;

{$IFNDEF PAS2JS}
function JValue(const Value: Int64): TJElement; overload;
begin
  Result := TJPrimitive.Create(Value);
end;
{$ENDIF}

function JValue(const Value: Double): TJElement; overload;
begin
  {$IFDEF PAS2JS}
  Result := Value;
  {$ELSE}
  Result := TJPrimitive.Create(Value);
  {$ENDIF}
end;

function JValue(const Value: Boolean): TJElement; overload;
begin
  {$IFDEF PAS2JS}
  Result := Value;
  {$ELSE}
  Result := TJPrimitive.Create(Value);
  {$ENDIF}
end;

function JToBool(Value: TJElement): Boolean;
begin
  {$IFDEF PAS2JS}
  Result := Boolean(Value);
  {$ELSE}
  Result := Value.AsBoolean;
  {$ENDIF}
end;

function JToInt(Value: TJElement): Integer;
begin
  {$IFDEF PAS2JS}
  Result := Integer(Value);
  {$ELSE}
  Result := Value.AsInteger;
  {$ENDIF}
end;

function JToStr(Value: TJElement): string;
begin
  {$IFDEF PAS2JS}
  Result := string(Value);
  {$ELSE}
  Result := Value.AsString;
  {$ENDIF}
end;

function AsJObject(Value: TJElement): TJObject;
begin
  {$IFDEF PAS2JS}
  Result := TJObject(Value);
  {$ELSE}
  Result := Value.AsObject;
  {$ENDIF}
end;

function AsJArray(Value: TJElement): TJArray;
begin
  {$IFDEF PAS2JS}
  Result := TJArray(Value);
  {$ELSE}
  Result := Value.AsArray;
  {$ENDIF}
end;

function JObjectKeys(Value: TJObject): TStringDynArray;
begin
  {$IFDEF PAS2JS}
  Result := TJSObject.keys(Value);
  {$ELSE}
  REsult := TJObject.keys(Value)
  {$ENDIF}
end;

end.
