{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Forms;

interface

uses
  Classes, Types, SysUtils, WEBLib.Graphics, WEBLib.Controls, WEBLib.Modules, WEBLib.Lang,
  Web, JS, WEBLib.ClientConnector
  {$IFDEF SINGLEINSTANCE}
  , WEBLib.WebSocketClient
  {$ENDIF}
  ;

const
  idOK       = 1;
  idCancel   = 2;
  idAbort    = 3;
  idRetry    = 4;
  idIgnore   = 5;
  idYes      = 6;
  idNo       = 7;
  idClose    = 8;
  idHelp     = 9;
  idTryAgain = 10;
  idContinue = 11;
  mrNone     = 0;
  mrOk       = idOk;
  mrCancel   = idCancel;
  mrAbort    = idAbort;
  mrRetry    = idRetry;
  mrIgnore   = idIgnore;
  mrYes      = idYes;
  mrNo       = idNo;
  mrClose    = idClose;
  mrHelp     = idHelp;
  mrTryAgain = idTryAgain;
  mrContinue = idContinue;
  mrAll      = mrContinue + 1;
  mrNoToAll  = mrAll + 1;
  mrYesToAll = mrNoToAll + 1;

  FORMCAPTIONHEIGHT = 22;

  rgiFirst = -1024;
  rgiLast  = -513;
  igiFirst = 256;
  igiLast  = 511;

type
  TDragDockObject = class;
  TCustomForm = class;

  TConstraintSize = 0..MaxInt;
  TCloseAction = (caNone, caHide, caFree, caMinimize);
  TModalResult = Integer;
  TFormStyle = (fsNormal, fsStayOnTop);
  TNavigationTarget = (ntBlank, ntPage);
  TBiDiMode = (bdLeftToRight, bdRightToLeft, bdRightToLeftNoAlign, bdRightToLeftReadingOnly);
  TGestureID = rgiFirst..igiLast;
  TInteractiveGestureFlag = (gfBegin, gfInertia, gfEnd);
  TInteractiveGestureFlags = set of TInteractiveGestureFlag;
  TMouseActivate = (maDefault, maActivate, maActivateAndEat, maNoActivate, maNoActivateAndEat);

  TSmallPoint  = record
    x, y: SmallInt;
  end;

  TAlignInfo = record
    AlignList: TList;
    ControlIndex: Integer;
    Align: TAlign;
    Scratch: Integer;
  end;

  TGestureEventInfo = record
    GestureID: TGestureID;
    Location: TPoint;
    Flags: TInteractiveGestureFlags;
    Angle: Double;
    InertiaVector: TSmallPoint;
    Distance: Integer;
    TapLocation: TSmallPoint;
  end;

  TOnPaintEvent = TNotifyEvent;
  TCloseEvent = procedure(Sender: TObject; var Action: TCloseAction) of object;
  TCloseQueryEvent = procedure(Sender: TObject; var CanClose: Boolean) of object;
  TBeforeUnloadEvent = procedure(Sender: TObject; var AMessage: string) of object;
  TAlignInsertBeforeEvent = function(Sender: TWinControl; C1, C2: TControl): Boolean of object;
  TAlignPositionEvent = procedure(Sender: TWinControl; Control: TControl;
    var NewLeft, NewTop, NewWidth, NewHeight: Integer;
    var AlignRect: TRect; AlignInfo: TAlignInfo) of object;
  TCanResizeEvent = procedure(Sender: TObject; var NewWidth, NewHeight: Integer;
    var Resize: Boolean) of object;
  TConstrainedResizeEvent = procedure(Sender: TObject; var MinWidth, MinHeight,
    MaxWidth, MaxHeight: Integer) of object;
  TContextPopupEvent = procedure(Sender: TObject; MousePos: TPoint; var Handled: Boolean) of object;
  TDockDropEvent = procedure(Sender: TObject; Source: TDragDockObject;
    X, Y: Integer) of object;
  TDockOverEvent = procedure(Sender: TObject; Source: TDragDockObject;
    X, Y: Integer; State: TDragState; var Accept: Boolean) of object;
  TGestureEvent = procedure(Sender: TObject; const EventInfo: TGestureEventInfo;
     var Handled: Boolean) of object;
  TGetSiteInfoEvent = procedure(Sender: TObject; DockClient: TControl;
    var InfluenceRect: TRect; MousePos: TPoint; var CanDock: Boolean) of object;
  TMouseActivateEvent = procedure(Sender: TObject; Button: TMouseButton;
    Shift: TShiftState; X, Y: Integer; HitTest: Integer; var MouseActivate: TMouseActivate) of object;
  TMouseWheelUpDownEvent = procedure(Sender: TObject; Shift: TShiftState;
    MousePos: TPoint; var Handled: Boolean) of object;
  TStartDockEvent = procedure(Sender: TObject;
    var DragObject: TDragDockObject) of object;
  TUnDockEvent = procedure(Sender: TObject; Client: TControl;
    NewTarget: TWinControl; var Allow: Boolean) of object;

  TModalResultProc = reference to procedure(AValue: TModalResult);
  TFormCreatedProc = reference to procedure(AForm: TObject);

  TWindowState = (wsNormal, wsMinimized, wsMaximized);

  TPopupMode = (pmNone, pmAuto, pmExplicit);
  TPopupClose = (pcOnDeactivate, pcNever);
  TPosition = (poDesigned, poDefault, poDefaultPosOnly, poDefaultSizeOnly, poScreenCenter, poDesktopCenter, poMainFormCenter, poOwnerFormCenter);

  TApplicationErrorType = (aeSilent, aeDialog, aeAlert, aeFooter);

  TFormBorderStyle = (fbNone, fbSingle, fbSizeable, fbDialog);
  TFormBorder = fbNone..fbSingle;

  TCSSLibrary = (cssNone, cssBootstrap);

  TCustomSizeConstraints = class(TPersistent)
  private
    FControl: TControl;
    FMaxHeight: TConstraintSize;
    FMaxWidth: TConstraintSize;
    FMinHeight: TConstraintSize;
    FMinWidth: TConstraintSize;
    FOnChange: TNotifyEvent;
    procedure SetConstraints(AIndex: Integer; AValue: TConstraintSize);
  protected
    procedure Change; virtual;
    procedure AssignTo(ADest: TPersistent); override;
    property Control: TControl read FControl;
    property MaxHeight: TConstraintSize index 0 read FMaxHeight write SetConstraints default 0;
    property MaxWidth: TConstraintSize index 1 read FMaxWidth write SetConstraints default 0;
    property MinHeight: TConstraintSize index 2 read FMinHeight write SetConstraints default 0;
    property MinWidth: TConstraintSize index 3 read FMinWidth write SetConstraints default 0;
  public
    constructor Create(AControl: TControl); virtual;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TSizeConstraints = class(TCustomSizeConstraints)
  published
    property MaxHeight;
    property MaxWidth;
    property MinHeight;
    property MinWidth;
  end;

  TDragDockObject = class(TObject)
  strict private
    FBrush: TBrush;
    FDockRect: TRect;
    FDropAlign: TAlign;
    FDropOnControl: TControl;
    FEraseDockRect: TRect;
    FEraseWhenMoving: Boolean;
    FFloating: Boolean;
    FFrameWidth: Integer;
  public
    constructor Create(AControl: TControl);
    destructor Destroy; override;
    procedure Assign(Source: TDragObject);
    property Brush: TBrush read FBrush write FBrush;
    property DockRect: TRect read FDockRect write FDockRect;
    property DropAlign: TAlign read FDropAlign;
    property DropOnControl: TControl read FDropOnControl;
    property EraseDockRect: TRect read FEraseDockRect write FEraseDockRect;
    property EraseWhenMoving: Boolean read FEraseWhenMoving;
    property Floating: Boolean read FFloating write FFloating;
    property FrameWidth: Integer read FFrameWidth;
  end;

  TCustomForm = class(TWinControl)
  private
    FDesignContainer: TJSElement;
    FLayer: TJSElement;
    FPopup: Boolean;
    FFormFileName: string;
    FFormContent: string;
    FFormContainer: string;
    FFormElement: string;
    FFormStyle: TFormStyle;
    FOnCreate: TNotifyEvent;
    FOnResize: TNotifyEvent;
    FOnShow: TNotifyEvent;
    FModalResult: TModalResult;
    FOnPaint: TOnPaintEvent;
    FOnDeactivate: TNotifyEvent;
    FOnCloseQuery: TCloseQueryEvent;
    FOnClose: TCloseEvent;
    FModalProc: TModalResultProc;
    FCreatedProc: TFormCreatedProc;
    FWindowState: TWindowState;
    FCaption: string;
    FIsResizing: boolean;
    FOnScroll: TNotifyEvent;
    FOnUnload: TNotifyEvent;
    FOnBeforeUnload: TBeforeUnloadEvent;
    FPopupMode: TPopupMode;
    FPopupClose: TPopupClose;
    FPosition: TPosition;
    FActiveControl: TWinControl;
    FMdx, FMdy: integer;
    FDlgX, FDlgY: integer;
    FCaptured: boolean;
    FDown: boolean;
    FHasCaption: boolean;
    FMoveSpan: TJSHTMLElement;
    FCaptionElement: TJSHTMLElement;
    FBorder: TFormBorderStyle;
    FShadow: boolean;
    FTimerID: integer;
    FOrigWidth, FOrigHeight: integer;
    FOnDestroy: TNotifyEvent;
    FPopupOpacity: single;
    FCreating: boolean;
    FLoadedPtr: pointer;
    FUnloadPtr: pointer;
    FBeforeUnloadPtr: pointer;
    FResizePtr: pointer;
    FScrollPtr: pointer;
    FDocMouseUpPtr: pointer;
    FDocMouseMovePtr: pointer;
    FTitleDownPtr: pointer;
    FDoClickPtr: pointer;
    FKeyDownPtr: pointer;
    FCSSLibrary: TCSSLibrary;
    FNoHTML: boolean;
    FPrevActiveForm: TCustomForm;
    FElementCaptionClassName: TElementClassName;
    FMenu: TCustomControl;
    procedure SetModalResult(const Value: TModalResult);
    function GetFormStyle: TFormStyle;
    procedure SetFormStyle(const Value: TFormStyle);
    function GetCanvas: TCanvas;
    procedure SetCaption(const AValue: string);
    procedure SetActiveControl(const Value: TWinControl);
    procedure SetShadow(const Value: boolean);
    procedure SetBorder(const Value: TFormBorderStyle);
    procedure SetFormContainer(const Value: string);
    function GetActiveControl: TWinControl;
  protected
    function HandleLoaded(Event: TEventListenerEvent): boolean; virtual;
    function HandleScroll(Event: TEventListenerEvent): boolean; virtual;
    function HandleUnload(Event: TEventListenerEvent): boolean; virtual;
    function HandleBeforeUnload(Event: TEventListenerEvent): boolean; virtual;
    function HandleResize(Event: TEventListenerEvent): boolean; virtual;
    function HandleDocMouseMove(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDocMouseUp(Event: TJSMouseEvent): Boolean; virtual;
    function HandleTitleDown(Event: TJSMouseEvent): Boolean; virtual;

    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    procedure Resize; reintroduce; virtual;
    procedure CreateControl; override;
    procedure DoClose(var CloseAction: TCloseAction); virtual;
    procedure BindEvents; override;
    procedure UnbindEvents; override;
    procedure DoCreate; virtual;
    procedure DoResize; virtual;
    procedure DoShow; virtual;
    procedure Paint; virtual;
    function HandleDoClick(Event: TJSMouseEvent): Boolean; reintroduce; virtual;
    function HandleKeyDown(Event: TJSKeyBoardEvent): Boolean; virtual;
    procedure HandleDoResize; virtual;
    function GetWidth: Integer; override;
    function GetHeight: Integer; override;
    function GetLeft: Integer; override;
    function GetTop: Integer; override;
    function GetClientRect: TRect; override;
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    function ContainerElement: TJSElement; override;
    function FormContainerElement: TJSElement; virtual;
    function GetElementBindHandle: TJSEventTarget; override;
    function GetElementHandle: TJSHTMLElement; override;
    function CloseQuery: Boolean; virtual;
    procedure LoadDFMValues; virtual;
    procedure Init; virtual;
    property FormContent: string read FFormContent write FFormContent;
    property WindowState: TWindowState read FWindowState write FWindowState;
    function IsFocused: Boolean; override;
    property PopupMode: TPopupMode read FPopupMode write FPopupMode default pmNone;
    property PopupClose: TPopupClose read FPopupClose write FPopupClose default pcOnDeactivate;
    property Position: TPosition read FPosition write FPosition default poDefaultPosOnly;
    function GetUniqueComponentName(AComponent: TComponent): string;
    function CreateLayer: TJSHTMLElement;
    function CreateCaption: TJSHTMLElement;
  public
    procedure CreateInitialize; override;
    constructor Create(id: string); overload; override;
    constructor Create(id: string; var AReference); overload; virtual;
    constructor Create(AOwner: TComponent); overload; override;
    constructor CreateNew(AOwner: TComponent; Dummy: Integer = 0); overload; virtual;
    constructor CreateNew(AFileName: string); overload; virtual;
    constructor CreateNew; overload; virtual;
    constructor CreateNew(AProc: TFormCreatedProc); overload; virtual;
    constructor CreateNew(AElementID: string; AProc: TFormCreatedProc); overload; virtual;
    constructor CreateDialog(ACaption: string); virtual;
    constructor CreateDialogNew(ACaption: string); virtual;

    destructor Destroy; override;
    procedure Close; virtual;
    procedure PreventDefault; reintroduce;
    property ActiveControl: TWinControl read GetActiveControl write SetActiveControl;
    property ModalResult: TModalResult read FModalResult write SetModalResult;
    property Canvas: TCanvas read GetCanvas;
    property CaptionElement: TJSHTMLElement read FCaptionElement;
    property ElementCaptionClassName: TElementClassName read FElementCaptionClassName write FElementCaptionClassName;
    procedure SetBounds(X, Y, AWidth, AHeight: Integer); override;
    procedure SelectFirst;
    procedure SelectNext(CurControl: TControl; GoForward: boolean; CheckTabStop: boolean);
    procedure Show;
    function ShowModal: Integer; overload; virtual;
    function ShowModal(AProc: TModalResultProc): TModalResult; overload; virtual;
    property Border: TFormBorderStyle read FBorder write SetBorder;
    property Caption: string read FCaption write SetCaption;
    property Color;
    property CSSLibrary: TCSSLibrary read FCSSLibrary write FCSSLibrary;
    property FormContainer: string read FFormContainer write SetFormContainer;
    property FormFileName: string read FFormFileName write FFormFileName;
    property FormStyle: TFormStyle read GetFormStyle write SetFormStyle;
    property IsResizing: boolean read FIsResizing;
    property Menu: TCustomControl read FMenu write FMenu;
    property Popup: boolean read FPopup write FPopup;
    property PopupOpacity: single read FPopupOpacity write FPopupOpacity;
    property Shadow: boolean read FShadow write SetShadow;
    property OnBeforeUnload: TBeforeUnloadEvent read FOnBeforeUnload write FOnBeforeUnload;
    property OnClick;
    property OnDblClick;
    property OnClose: TCloseEvent read FOnClose write FOnClose;
    property OnCloseQuery: TCloseQueryEvent read FOnCloseQuery write FOnCloseQuery;
    property OnCreate: TNotifyEvent read FOnCreate write FOnCreate;
    property OnDestroy: TNotifyEvent read FOnDestroy write FOnDestroy;
    property OnDeactivate: TNotifyEvent read FOnDeactivate write FOnDeactivate;
    property OnPaint: TOnPaintEvent read FOnPaint write FOnPaint;
    property OnResize: TNotifyEvent read FOnResize write FOnResize;
    property OnScroll: TNotifyEvent read FOnScroll write FOnScroll;
    property OnShow: TNotifyEvent read FOnShow write FOnShow;
    property OnUnload: TNotifyEvent read FOnUnload write FOnUnload;
  end;

  TFormClass = class of TForm;
  TForm = class(TCustomForm)
  public
    property ClientHeight;
    property ClientWidth;
    property PopupMode;
    property Position;
  published
    property Align;
    property Caption;
    property Color;
    property CSSLibrary;
    property ElementClassName;
    property ElementPosition;
    property Font;
    property FormContainer;
    property FormStyle;
    property Menu;
    property TabOrder;
    property TabStop;
    property OnClick;
    property OnClose;
    property OnCloseQuery;
    property OnCreate;
    property OnDblClick;
    property OnDeactivate;
    property OnResize;
    property OnShow;
    property OnScroll;
    property OnPaint;
    property OnTouchStart;
    property OnTouchMove;
    property OnTouchEnd;
    property OnUnload;
  end;

  TWebForm = TForm;

  TPopupMenu = class(TComponent)
  end;

  TTouchManager = class(TComponent)
  end;

  TCustomFrame = class(TCustomHTMLDiv)
  private
    FAlign: TAlign;
    FAutoScroll: Boolean;
    FAutoSize: Boolean;
    FBiDiMode: TBiDiMode;
    FConstraints: TSizeConstraints;
    FCtl3D: Boolean;
    FDockSite: Boolean;
    FDragCursor: TCursor;
    FDragKind: TDragKind;
    FDragMode: TDragMode;
    FOnAlignInsertBefore: TAlignInsertBeforeEvent;
    FOnAlignPosition: TAlignPositionEvent;
    FOnCanResize: TCanResizeEvent;
    FOnConstrainedResize: TConstrainedResizeEvent;
    FOnContextPopup: TContextPopupEvent;
    FOnDockDrop: TDockDropEvent;
    FOnDockOver: TDockOverEvent;
    FOnEndDock: TEndDragEvent;
    FOnGesture: TGestureEvent;
    FOnGetSiteInfo: TGetSiteInfoEvent;
    FOnMouseActivate: TMouseActivateEvent;
    FOnMouseWheelDown: TMouseWheelUpDownEvent;
    FOnMouseWheelUp: TMouseWheelUpDownEvent;
    FOnStartDock: TStartDockEvent;
    FOnUnDock: TUnDockEvent;
    FPadding: TPadding;
    FParentBackground: Boolean;
    FParentBiDiMode: Boolean;
    FParentCtl3D: Boolean;
    FPopupMenu: TPopupMenu;
    FTouch: TTouchManager;
    FTouchManager: TTouchManager;
    procedure setConstraints(const AValue: TSizeConstraints);
    procedure SetPopupMenu(AValue: TPopupMenu);
    procedure SetTouchManager(AValue: TTouchManager);
  protected
    procedure LoadDFMValues; virtual;
    property Align: TAlign read FAlign write FAlign default alNone;
    property AutoScroll: Boolean read FAutoScroll write FAutoScroll default False;
    property AutoSize: Boolean read FAutoSize write FAutoSize default False;
    property BiDiMode: TBiDiMode read FBiDiMode write FBiDiMode stored False;
    property Constraints: TSizeConstraints read FConstraints write setConstraints;
    property Ctl3D: Boolean read FCtl3D write FCtl3D stored False;
    property DockSite: Boolean read FDockSite write FDockSite default False;
    property DragCursor: TCursor read FDragCursor write FDragCursor default crDrag;
    property DragKind: TDragKind read FDragKind write FDragKind default dkDrag;
    property DragMode: TDragMode read FDragMode write FDragMode default dmManual;
    property Padding: TPadding read FPadding write FPadding;
    property ParentBackground: Boolean read FParentBackground write FParentBackground;
    property ParentBiDiMode: Boolean read FParentBiDiMode write FParentBiDiMode default True;
    property ParentCtl3D: Boolean read FParentCtl3D write FParentCtl3D default True;
    property PopupMenu: TPopupMenu read FPopupMenu write SetPopupMenu;
    property Touch: TTouchManager read FTouch write SetTouchManager;
    property OnAlignInsertBefore: TAlignInsertBeforeEvent read FOnAlignInsertBefore write FOnAlignInsertBefore;
    property OnAlignPosition: TAlignPositionEvent read FOnAlignPosition write FOnAlignPosition;
    property OnCanResize: TCanResizeEvent read FOnCanResize write FOnCanResize;
    property OnConstrainedResize: TConstrainedResizeEvent read FOnConstrainedResize write FOnConstrainedResize;
    property OnContextPopup: TContextPopupEvent read FOnContextPopup write FOnContextPopup;
    property OnDockDrop: TDockDropEvent read FOnDockDrop write FOnDockDrop;
    property OnDockOver: TDockOverEvent read FOnDockOver write FOnDockOver;
    property OnEndDock: TEndDragEvent read FOnEndDock write FOnEndDock;
    property OnGesture: TGestureEvent read FOnGesture write FOnGesture;
    property OnGetSiteInfo: TGetSiteInfoEvent read FOnGetSiteInfo write FOnGetSiteInfo;
    property OnMouseActivate: TMouseActivateEvent read FOnMouseActivate write FOnMouseActivate;
    property OnMouseWheelDown: TMouseWheelUpDownEvent read FOnMouseWheelDown write FOnMouseWheelDown;
    property OnMouseWheelUp: TMouseWheelUpDownEvent read FOnMouseWheelUp write FOnMouseWheelUp;
    property OnStartDock: TStartDockEvent read FOnStartDock write FOnStartDock;
    property OnUnDock: TUnDockEvent read FOnUnDock write FOnUnDock;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure LoadFromForm;
  end;

  TFrame = class(TCustomFrame)
  published
    property Align;
    property Anchors;
    property AutoScroll;
    property AutoSize;
    property BiDiMode;
    property Constraints;
    property DockSite;
    property DoubleBuffered;
    property DragCursor;
    property DragKind;
    property DragMode;
    property Enabled;
    property Color nodefault;
    property Ctl3D;
    property Font;
    property Padding;
    property ParentBackground default True;
    property ParentBiDiMode;
    property ParentColor;
    property ParentCtl3D;
    property ParentDoubleBuffered;
    property ParentFont;
    property ParentShowHint;
    property PopupMenu;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Touch;
    property Visible;
    property OnAlignInsertBefore;
    property OnAlignPosition;
    property OnCanResize;
    property OnClick;
    property OnConstrainedResize;
    property OnContextPopup;
    property OnDblClick;
    property OnDockDrop;
    property OnDockOver;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDock;
    property OnEndDrag;
    property OnEnter;
    property OnExit;
    property OnGesture;
    property OnGetSiteInfo;
    property OnMouseActivate;
    property OnMouseDown;
    property OnMouseEnter;
    property OnMouseLeave;
    property OnMouseMove;
    property OnMouseUp;
    property OnMouseWheel;
    property OnMouseWheelDown;
    property OnMouseWheelUp;
    property OnResize;
    property OnStartDock;
    property OnStartDrag;
    property OnUnDock;
  end;

  TAppplicationError = record
    AMessage: string;
    AFile: string;
    ALineNumber: integer;
    AColNumber: integer;
    AStack: string;
    AError: TJSObject;
  end;

  TOnlineStatus = (osOnline, osOffline);

  TAppplicationErrorEvent = procedure(Sender: TObject; AError: TAppplicationError; var Handled: boolean) of object;

  TApplicationHashChangeEvent = procedure(Sender: TObject; AHash: string; var Handled: boolean) of object;

  TApplicationOnlineChangeEvent = procedure(Sender: TObject; AStatus: TOnlineStatus) of object;

  TApplicationCallBackEvent = procedure(Sender: TObject; AQuery: string) of object;

  TApplication = class(TControl)
  private
    FMainFormOnTaskBar: Boolean;
    FCanCreateForm: Boolean;
    FAppInitializing: Boolean;
    FLastReq: TJSXMLHttpRequest;
    {$IFDEF SINGLEINSTANCE}
    FWebSocket: TSocketClient;
    {$ENDIF}
    FActiveForm: TCustomForm;
    FMainForm: TCustomForm;
    FInitFormClassName: string;
    FFormStack: TList;
    FParameters: TStrings;
    FIsRedirect: boolean;
    FLanguage: TUILanguage;
    FOnImageCacheReady: TNotifyEvent;
    FOnError: TAppplicationErrorEvent;
    FOnHashChange: TApplicationHashChangeEvent;
    FAutoFormRoute: boolean;
    FErrorType: TApplicationErrorType;
    FClientConnector: TClientConnector;
    FOnOnlineChange: TApplicationOnlineChangeEvent;
    FThemeTextColor: TColor;
    FThemeColor: TColor;
    FThemeButtonClassName: string;
    FOnOAuthToken: TApplicationCallBackEvent;
    FOnOAuthCallBack: TApplicationCallBackEvent;
    FOnFontCacheReady: TNotifyEvent;
    function DoFormLoad(Event: TEventListenerEvent): boolean;
    function DoFormAbort(Event: TEventListenerEvent): boolean;
    function DoHandleError(Event: TJSErrorEvent): boolean;
    function DoHashChange(Event: TEventListenerEvent): boolean;
    function DoErrorClose(Event: TJSMOuseEvent): boolean;
    function DoUpdateOnlineStatus(Event: TEventListenerEvent): boolean;
    procedure SetLanguage(const Value: TUILanguage);
    function GetIsOnline: boolean;
    {$IFDEF SINGLEINSTANCE}
    procedure DoGetDataReceived(Sender: TObject; Origin: string; Data: TJSObject);
    procedure DoGetConnected(Sender: TObject);
    {$ENDIF}
  protected
    procedure CreateNewForm(AForm: TCustomForm; HTML: string); overload; virtual;
    function GetFormExtension: string;
    function GetAuthorizationPageHTML(const AAuthorizationSuccess: Boolean): string;
    procedure ReloadForm;
    procedure ActivateChildScripts(AElement: TJSHTMLElement);
  public
    procedure PushForm(AForm: TCustomForm);
    function PopForm: TCustomForm;
    procedure LockForm(AForm: TCustomForm);
    procedure UnLockForm(AForm: TCustomForm);
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function CreateNewForm(AInstanceClass: TFormClass): TCustomForm; overload;
    function CreateNewForm(AInstanceClass: TFormClass; AElementID: string): TCustomForm; overload;
    procedure CreateForm(AInstanceClass: TFormClass; var AReference); overload;
    procedure CreateForm(AInstanceClass: TFormClass; AElementID: string; var AReference); overload;
    procedure CreateForm(AInstanceClass: TFormClass; AElementID: string; var AReference; AProc: TFormCreatedProc); overload;
    procedure CreateForm(AInstanceClass: TDataModuleClass; var AReference); overload;
    procedure CreateForm(AInstanceClass: TFormClass; AElement: TJSHTMLElement; var AReference); overload;
    procedure LoadForm(AForm: TCustomForm; AFormFile: string); virtual;
    procedure ChangeCSS(id: string; href:string);
    procedure InsertCSS(id, href: string);
    procedure RemoveCSS(id: string);
    procedure Initialize;
    procedure InitFormatSettings(const BrowserLocale: string);
    procedure ReceiveMessageFromClient(const AMessage: string);
    procedure RouteForm(AParameter: string);
    procedure Run;
    procedure RunScript(Source: string);
    procedure Navigate(const AURL: string; ATarget: TNavigationTarget = ntBlank);
    procedure Download(const AURL: string);
    procedure DownloadTextFile(const AText: string; AFileName: string = '');
    procedure DownloadBinaryFile(const Data: TJSUint8Array; AFileName: string = '');
    function EXEName: string;
    function NeedsFormRouting: Boolean;
    function ObjectURL(AFile: TJSHTMLFile): string;
    property AutoFormRoute: boolean read FAutoFormRoute write FAutoFormRoute;
    property ErrorType: TApplicationErrorType read FErrorType write FErrorType;
    property IsOnline: boolean read GetIsOnline;
    property ActiveForm: TCustomForm read FActiveForm;
    property MainForm: TCustomForm read FMainForm;
    property MainFormOnTaskBar: boolean read FMainFormOnTaskBar write FMainFormOnTaskBar;
    property Parameters: TStrings read FParameters;
    property Language: TUILanguage read FLanguage write SetLanguage;
    property ThemeColor: TColor read FThemeColor write FThemeColor;
    property ThemeTextColor: TColor read FThemeTextColor write FThemeTextColor;
    property ThemeButtonClassName: string read FThemeButtonClassName write FThemeButtonClassName;
    property OnError: TAppplicationErrorEvent read FOnError write FOnError;
    property OnImageCacheReady: TNotifyEvent read FOnImageCacheReady write FOnImageCacheReady;
    property OnFontCacheReady: TNotifyEvent read FOnFontCacheReady write FOnFontCacheReady;
    property OnHashChange: TApplicationHashChangeEvent read FOnHashChange write FOnHashChange;
    property OnOnlineChange: TApplicationOnlineChangeEvent read FOnOnlineChange write FOnOnlineChange;
    property OnOAuthCallBack: TApplicationCallBackEvent read FOnOAuthCallBack write FOnOAuthCallBack;
    property OnOAuthToken: TApplicationCallBackEvent read FOnOAuthToken write FOnOAuthToken;
    property ClientConnector: TClientConnector read FClientConnector write FClientConnector;
  end;

function GetParentForm(AControl: TControl): TCustomForm;
procedure Log(v: JSValue); overload;
procedure Log(arr: array of JSValue); overload;

var
  Application: TApplication;
  HandShakeScript: TJSHTMLScriptElement;
  VSIDE: TJSObject;

implementation

uses
  WEBLib.Dialogs, WEBLib.WebTools, WEBLib.JSON, Math;

const
  cBodyTag = 'body';
  cHTMLExt = '.html';

procedure Log(v: JSValue);
begin
  asm
    console.log(v);
  end;
end;

{$HINTS OFF}
procedure Log(arr: array of JSValue);
var
  i: integer;
  s,su: string;
begin
  s := '[';
  for i := 0 to length(arr) - 1 do
  begin
    asm
      function isPrimitive(test) {
        return (test !== Object(test));
      };

      if (isPrimitive(arr[i])) {
         var su = arr[i].toString();
         if (s != "[") { s = s + ","; }
         s = s + su;
         }
      else
      {
        console.log(arr[i]);
         if (s != "[") { s = s + ","; }
         s = s + "#object";
      }
    end;
  end;

  s := s + ']';

  log(s);
end;
{$HINTS ON}

function GetParentForm(AControl: TControl): TCustomForm;
var
  FOwner: TComponent;
begin
  Result := nil;

  FOwner := AControl;

  while Assigned(FOwner) and not (FOwner is TCustomForm) do
  begin
    FOwner := FOwner.Owner;
  end;

  if Assigned(FOwner) and (FOwner is TCustomForm) then
    Result := (FOwner as TCustomForm);
end;


{ TCustomForm }

procedure TCustomForm.BindEvents;
begin
  inherited;
  window.addEventListener('resize', FResizePtr);
  window.addEventListener('load', FLoadedPtr);
  document.addEventListener('scroll', FScrollPtr);
  window.addEventListener('unload', FUnloadPtr);
  window.addEventListener('beforeunload', FBeforeUnloadPtr);
  window.addEventListener('keydown', FKeyDownPtr);
end;

procedure ReleaseForm(AForm: TCustomForm);
begin
  AForm.Free;
  AForm := nil;
end;

procedure TCustomForm.ClearMethodPointers;
begin
  inherited;
  FLoadedPtr := nil;
  FUnloadPtr := nil;
  FBeforeUnloadPtr := nil;
  FResizePtr := nil;
  FScrollPtr := nil;
  FDocMouseUpPtr := nil;
  FDocMouseMovePtr := nil;
  FTitleDownPtr := nil;
  FDoClickPtr := nil;
  FKeyDownPtr := nil;
end;

procedure TCustomForm.Close;
var
  lAction: TCloseAction;
  el: TJSElement;
begin
  if CloseQuery then
  begin
    UnbindEvents;
    ClearControls;

    lAction := caHide;

    if FTimerID <> -1 then
    begin
      window.clearInterval(FTimerID);
      FTimerID := -1;
    end;

    if Assigned(OnClose) then
      OnClose(Self, lAction);

    if lAction <> caNone then
    begin
      if Assigned(FLayer) then
      begin
        if Container.parentNode = FLayer then
        begin
          FLayer.removeChild(Container);
        end
        else
        begin
          if Container.parentNode.parentNode = FLayer then
            FLayer.removeChild(Container.parentNode);
        end;

        Container := nil;
        if FormContainerElement = FLayer.parentNode then
        begin
          FormContainerElement.removeChild(FLayer);
        end;
        FLayer := nil;
      end;

      if (FormFileName = '') then
      begin
        if (FFormElement <> '') and Assigned(Container) then
        begin
          Container.innerHTML := '';
          Container := nil;
        end
        else
          if Assigned(Container) then
          begin
            if FormContainerElement = Container.parentNode then
              FormContainerElement.removeChild(Container);
            Container := nil;
          end;
      end
      else
      begin
        if FFormElement <> '' then
        begin
          Container.innerHTML := '';
          Container := nil;
        end
      end;

      if (FFormElement <> FormContainer) and (FFormElement <> '') then
      begin
        el := document.getElementById(FFormElement);
        if Assigned(el) then
          el.innerHTML := '';
      end;

      DoClose(lAction);

      if (lAction = caFree) and not (csDestroying in ComponentState) then
      begin
        ReleaseForm(Self);
        if Application.FMainForm = Self then
          Application.FMainForm := nil;
      end;

      Application.FActiveForm := FPrevActiveForm;

      Visible := False;
    end;
  end;
end;

function TCustomForm.CloseQuery: Boolean;
begin
  Result := True;
  if Assigned(FOnCloseQuery) then
    FOnCloseQuery(Self, Result);
end;

function TCustomForm.ContainerElement: TJSElement;
begin
  Result := FormContainerElement;
end;

constructor TCustomForm.Create(AOwner: TComponent);
begin
  FNoHTML := false;
  FCreating := true;
  inherited;
  FFormFileName := '';
  FPopupMode := pmNone;
  FPopupClose := pcOnDeactivate;
  FPopup := True;
  FPopupOpacity := 1.0;
end;

function TCustomForm.CreateCaption: TJSHTMLElement;
var
  BarHeight: string;
begin
  BarHeight := '22';
  FCaptionElement := TJSHTMLElement(document.createElement('DIV'));
  FCaptionElement.innerHTML := Caption;
  FCaptionElement.style.setProperty('background-color', ColorToHTML(Application.ThemeColor));
  FCaptionElement.style.setProperty('color', ColorToHTML(Application.ThemeTextColor));
  FCaptionElement.style.setProperty('cursor', 'move');
  //title.style.setProperty('padding', Padding + 'px');
  FCaptionElement.style.setProperty('height', BarHeight + 'px');
  FCaptionElement.style.setProperty('line-height', BarHeight + 'px');
  FCaptionElement.style.setProperty('border-bottom', '1px solid black');
  FCaptionElement.style.setProperty('padding-left','4px');

  FCaptionElement.style.setProperty('-moz-user-select','none');
  FCaptionElement.style.setProperty('-webkit-user-select','none');
  FCaptionElement.style.setProperty('-ms-user-select','none');
  FCaptionElement.style.setProperty('user-select','none');
  FCaptionElement.style.setProperty('-o-user-select','none');

  if ElementCaptionClassName <> '' then
    FCaptionElement.setAttribute('class',ElementCaptionClassName);

  FCaptionElement.innerHTML := FCaption;
  FCaptionElement.addEventListener('mousedown', FTitleDownPtr);
  FHasCaption := true;

  Result := FCaptionElement;
end;

procedure TCustomForm.CreateControl;
begin
  if not Visible and not FPopup then
    Exit;
  inherited;
end;

function TCustomForm.CreateLayer: TJSHTMLElement;
begin
  Result := TJSHTMLElement(document.createElement('SPAN'));
  Result.style.setProperty('top', '0');
  Result.style.setProperty('left', '0');
  Result.style.setProperty('right', '0');
  Result.style.setProperty('bottom', '0');

  Result.style.setProperty('webkit-user-select', 'none');
  Result.style.setProperty('moz-user-select', 'none');
  Result.style.setProperty('khtml-user-select', 'none');
  Result.style.setProperty('ms-user-select', 'none');
  Result.style.setProperty('user-select', 'none');
  Result.style.setProperty('position', 'absolute');
end;

function TCustomForm.CreateElement: TJSElement;
var
  eh,caph: TJSHTMLElement;

begin
  if not FPopup and Assigned(FormContainerElement) then
  begin
    Result := FormContainerElement;
    Exit;
  end;

  //if FPopup then
  begin
    Result := document.createElement('DIV');

    FLayer := CreateLayer;
    document.body.appendChild(FLayer);

    eh := TJSHTMLElement(FLayer);

    if FPopupClose = pcOnDeactivate then
    begin
      // ensure method pointers exist
      GetMethodPointers;
      eh.addEventListener('click', FDoClickPtr);
    end;

    eh.style.setProperty('z-index', '999999');

    if (Border = fbSizeable) then
    begin
      TJSHTMLElement(Result).style.setProperty('resize','both');
      TJSHTMLElement(Result).style.setProperty('overflow','auto');
    end;

    FHasCaption := false;

    if (Border in [fbSizeable, fbDialog]) then
    begin
      caph := CreateCaption;
      Result.appendChild(caph);
    end;
  end;
end;

procedure TCustomForm.LoadDFMValues;
begin
end;

procedure TCustomForm.Init;
begin
end;

function TCustomForm.IsFocused: Boolean;
begin
  // form is not focused only controls
  Result := false;
end;

procedure TCustomForm.Resize;
var
  i: integer;
  neww, newh: integer;
begin
  FIsResizing := true;

  neww := window.innerWidth;
  newh := window.innerHeight-6;

  UpdateControlSize(neww, newh);

  AlignControl(Self);

  DoResize;

  FIsResizing := false;

  if not IsUpdating then
  begin
    if (OrigRect.Left = -1) and (OrigRect.Top = -1) then
      InitAnchoring;

    for i := 0 to ControlCount - 1 do
      Controls[i].UpdateAnchoring;
  end;
end;

procedure TCustomForm.SetActiveControl(const Value: TWinControl);
begin
  FActiveControl := Value;
  if Assigned(FActiveControl) then
    FActiveControl.SetFocus;
end;

procedure TCustomForm.SetBorder(const Value: TFormBorderStyle);
begin
  if (FBorder <> Value) then
  begin
    FBorder := Value;
    if Assigned(Container) then
      AlignControl(Self);
  end;
end;

procedure TCustomForm.SetBounds(X, Y, AWidth, AHeight: Integer);
begin
  if (csDesigning in ComponentState) then
  begin
    Resize;
    SetBoundsInt(X, Y, AWidth, AHeight);
  end
  else
    inherited SetBounds(X, Y, AWidth, AHeight);
end;

procedure TCustomForm.SetCaption(const AValue: string);
begin
  if FCaption <> AValue then
  begin
    FCaption := AValue;
    UpdateElement;
  end;
end;

procedure TCustomForm.SetFormContainer(const Value: string);
var
  el: TJSElement;
begin
  FFormContainer := Value;

  // update container element
  el := document.getElementById(Value);
  if Assigned(el) then
    Container := el;
end;

procedure TCustomForm.SetFormStyle(const Value: TFormStyle);
begin
  FFormStyle := Value;
end;

procedure TCustomForm.SetModalResult(const Value: TModalResult);
begin
  FModalResult := Value;
  if (FModalResult <> mrNone) then
    Close;
end;

procedure TCustomForm.SetShadow(const Value: boolean);
begin
  FShadow := Value;
  UpdateElement;
end;

procedure TCustomForm.SelectFirst;
begin
  if ControlCount > 0 then
    Controls[0].SetFocus;
end;


procedure TCustomForm.SelectNext(CurControl: TControl; GoForward: boolean; CheckTabStop: boolean);
var
  i: integer;
begin
  if ControlCount > 0 then
  begin
    for i := 0 to ControlCount - 1 do
    begin
      if Controls[i] = CurControl then
      begin
        if GoForward then
        begin
          if i + 1 <= ControlCount - 1  then
            Controls[i + 1].SetFocus
          else
            Controls[0].SetFocus;
        end
        else
        begin
          if i - 1 >= 0 then
            Controls[i - 1].SetFocus
          else
            Controls[ControlCount - 1].SetFocus;
        end;
        break;
      end;
    end;
  end;
end;

procedure TCustomForm.Show;
var
  l,t,i: integer;
  dr: TJSDOMRect;
begin
  if FCreating and FPopup then
  begin
    FCreating := false;
    UpdateElement;
  end;

  if (FormFileName = '') then
  begin
    if not Assigned(Container) then
    begin
      if FPopup then
      begin
        Container := CreateElement;
        FLayer.appendChild(Container);
        TJSHTMLElement(FLayer).style.setProperty('background-color','silver');
      end
      else
      begin
        Container := CreateLayer;
        document.body.appendChild(Container);
      end;
    end
    else
    begin
      if FPopup then
      begin
        if Position = poScreenCenter then
        begin
          l := Round((window.innerWidth - Width)/2);
          t := Round((window.innerHeight - Height)/2);
          FLeft := l;
          FTop := t;
        end;
        TJSHTMLElement(Container).style.setProperty('left',inttostr(Left)+'px');
        TJSHTMLElement(Container).style.setProperty('top',inttostr(Top)+'px');
        FLayer.appendChild(Container)
      end
      else
      begin
        if Assigned(FLayer) then
        begin
          FLayer.appendChild(Container)
        end
        else
        begin
          document.body.appendChild(Container);
        end;
      end;
    end;

    if FPopup then
    begin
      if (Border = fbSizeable) then
      begin
        dr := Container.getBoundingClientRect;
        FOrigWidth := Round(dr.Right - dr.Left);
        FOrigHeight := Round(dr.Bottom - dr.Top);
        FTimerID := window.setInterval(@HandleDoResize, 100);
      end
      else
      begin
        TJSHTMLElement(Container).style.setProperty('resize','');
        TJSHTMLElement(Container).style.setProperty('overflow','');
      end;
    end;
  end;

  Visible := True;

  DoShow;

  // Auto focus control on form
  for i := 0 to ControlCount - 1 do
  begin
    if (Controls[i].TabOrder = 0) and (Controls[i].TabStop) then
      Controls[i].SetFocus;
  end;
end;

function TCustomForm.ShowModal: Integer;
begin
  Result := ShowModal(nil);
end;

function TCustomForm.ShowModal(AProc: TModalResultProc): TModalResult;
begin
  // create runtime form without HTML
  if FNoHTML then
  begin
    FPrevActiveForm := Application.ActiveForm;
    Application.FActiveForm := Self;
    Application.CreateNewForm(Self, '');
  end;

  Result := mrNone;
  FNoHTML := false;
  FModalProc := AProc;
  ModalResult := mrNone;
  Show;
end;

procedure TCustomForm.UnbindEvents;
begin
  inherited;

  if Assigned(FLayer) and (FPopupClose = pcOnDeactivate) then
    FLayer.removeEventListener('click', FDoClickPtr);

  window.removeEventListener('resize', FResizePtr);
  window.removeEventListener('load', FLoadedPtr);
  document.removeEventListener('scroll', FScrollPtr);
  window.removeEventListener('unload', FUnloadPtr);
  window.removeEventListener('beforeunload', FBeforeUnloadPtr);
  window.removeEventListener('keywown', FKeyDownPtr);
end;

procedure TCustomForm.UpdateElement;
var
  clr: string;
begin
  if FCreating then
    Exit;

  inherited;

  if IsUpdating then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if (FCaption <> '') then
    begin
      if not FPopup then
        window.document.title := FCaption
      else
      begin
        if Assigned(FCaptionElement) then
          FCaptionElement.innerHTML := Caption;
      end;
    end;

    clr := ColorToHtml(Color);

    if Assigned(Container) then
    begin
      if (Color <> clNone) and (ElementClassName = '') then
        TJSHTMLElement(Container).style.setProperty('background-Color',clr);

      if (ElementClassName = '') and not Popup then
      begin
        // make sure body stretches over the entire page
        //TJSHTMLElement(Container).style.setProperty('height','100%');
        asm
          document.documentElement.style.setProperty('height','100%');
        end;
      end;
    end;

    if FPopup and not Assigned(FormContainerElement) then
    begin
      if FShadow then
      begin
        TJSHTMLElement(ElementHandle).style.setProperty('box-shadow','5px 5px 5px gray')
      end
      else
        TJSHTMLElement(ElementHandle).style.setProperty('box-shadow','');

      TJSHTMLElement(ElementHandle).style.setProperty('border','1px solid gray');
    end
    else
    begin
      TJSHTMLElement(ElementHandle).style.setProperty('box-shadow','');
    end;
  end;
end;

procedure TCustomForm.Paint;
begin
  //
end;

procedure TCustomForm.PreventDefault;
begin
  ElementEvent.preventDefault();
end;

function TCustomForm.HandleResize(Event: TEventListenerEvent): boolean;
begin
  if Visible then
  begin
    if not Assigned(Container) then
      CreateControl;

    Resize;
  end;

  Result := true;
end;

function TCustomForm.GetActiveControl: TWinControl;
var
  i: integer;
begin
  Result := FActiveControl;


  for i := 0 to ControlCount - 1 do
  begin
    if Controls[i].ElementHandle = document.activeElement then
    begin
      Result := TWinControl(Controls[i]);
      break;
    end;

  end;
end;

function TCustomForm.GetCanvas: TCanvas;
begin
  Result := nil;
end;

function TCustomForm.GetClientRect: TRect;
begin
  Result := inherited GetClientRect;

  if FHasCaption then
    Result.Top := Result.Top + FORMCAPTIONHEIGHT;
end;

function TCustomForm.GetElementBindHandle: TJSEventTarget;
begin
  if (csDesigning in ComponentState) then
  begin
    Result := TJSEventTarget(window);
    Exit;
  end;

  if (FormContainer <> '') then
  begin
    Result := TJSEventTarget(window);
  end
  else
  begin
    if not FPopup then
    begin
      Result := TJSEventTarget(window);
    end
    else
      Result := inherited GetElementBindHandle;
  end;
end;

function TCustomForm.GetElementHandle: TJSHTMLElement;
begin
  if FPopup then
    Result := inherited GetElementHandle
  else
    Result := TJSHTMLElement(FormContainerElement);
end;

function TCustomForm.GetFormStyle: TFormStyle;
begin
  Result := FFormStyle;
end;

function TCustomForm.GetHeight: Integer;
var
  d: integer;
  s: string;
  css: TJSCSSStyleDeclaration;
  el: TJSHTMLElement;
  dr: TJSDOMRect;
begin
  if (FFormElement <> '') then
  begin
    el := TJSHTMLElement(document.getElementById(FFormElement));
    if Assigned(el) then
    begin
      dr := el.getBoundingClientRect;
      Result := Round(dr.bottom - dr.top);
      Exit;
    end
  end;

  if (ElementHandle = document.body) then
  begin
    css := window.getComputedStyle(ElementHandle);
    s := css.getPropertyValue('margin-top');
    s := Copy(s,1,Length(s)- 2);
    d := StrToInt(s) - 2; // div 2;
    Result := window.innerHeight - Max(0,d);
  end
  else
  begin
    if FPopup then
      Result := inherited GetHeight
    else
    begin
      Result := Round(ElementHandle.offsetHeight);
      if Result = 0 then
        Result:= window.innerHeight;
    end;
  end;
end;

function TCustomForm.GetLeft: Integer;
var
  el: TJSHTMLElement;
  dr: TJSDOMRect;
begin
  if FPopup then
  begin
    Result := inherited GetLeft
  end
  else
  begin
    if (FFormElement <> '') then
    begin
      el := TJSHTMLElement(document.getElementById(FFormElement));
      if Assigned(el) then
      begin
        dr := el.getBoundingClientRect;
        Result := Round(dr.left);
      end;
    end
    else
    begin
      el := nil;
      if (FormContainer <> '') then
        el := TJSHTMLElement(document.getElementById(FormContainer));

      if not Assigned(el) then
         el := ElementHandle; 

      Result := Round(el.offsetLeft);
    end;
  end;
end;

procedure TCustomForm.GetMethodPointers;
begin
  inherited;

  if FLoadedPtr = nil then
  begin
    FLoadedPtr := @HandleLoaded;
    FUnloadPtr := @HandleUnLoad;
    FBeforeUnloadPtr := @HandleBeforeUnload;
    FResizePtr := @HandleResize;
    FScrollPtr := @HandleScroll;
    FDocMouseUpPtr := @HandleDocMouseUp;
    FDocMouseMovePtr := @HandleDocMouseMove;
    FTitleDownPtr := @HandleTitleDown;
    FDoClickPtr := @HandleDoClick;
    FKeyDownPtr := @HandleKeyDown;
  end;
end;

function TCustomForm.GetTop: Integer;
var
  el: TJSHTMLElement;
  dr: TJSDOMRect;
begin
  if FPopup then
    Result := inherited GetTop
  else
  begin
    if (FFormElement <> '') then
    begin
      el := TJSHTMLElement(document.getElementById(FFormElement));
      if Assigned(el) then
      begin
        dr := el.getBoundingClientRect;
        Result := Round(dr.top);
      end;
    end
    else
    begin
      el := nil;
      if (FormContainer <> '') then
        el := TJSHTMLElement(document.getElementById(FormContainer));

      if not Assigned(el) then
          el := ElementHandle; 
      Result := Round(el.offsetTop);
    end; 
  end;
end;

function TCustomForm.GetUniqueComponentName(AComponent: TComponent): string;
begin
  Result := ClassName + '_' + FindUniqueName(AComponent.ClassName);
end;

function TCustomForm.GetWidth: Integer;
var
  dr: TJSDOMRect;
  el: TJSHTMLElement;
//  s: string;
//  d: integer;
//  css: TJSCSSStyleDeclaration;
begin
  if (FFormElement <> '') then
  begin
    el := TJSHTMLElement(document.getElementById(FFormElement));
    if Assigned(el) then
    begin
      dr := el.getBoundingClientRect;
      Result := Round(dr.right - dr.left);
      Exit;
    end
  end;

  if (ElementHandle = document.body) then
  begin
    {
    css := window.getComputedStyle(ElementHandle);
    s := css.getPropertyValue('margin-left');
    s := Copy(s,1,Length(s) - 2);
    d := StrToInt(s) - 2;
    Result := window.innerWidth - d + 4;
    }
    Result := window.innerWidth;
  end
  else
  begin
    if FPopup then
      Result := inherited GetWidth
    else
    begin
      Result := Round(ElementHandle.offsetWidth);
      if Result = 0 then
        Result := window.innerWidth;
    end;
  end;
end;

function TCustomForm.HandleBeforeUnload(Event: TEventListenerEvent): boolean;
var
  msg: string;
begin
  msg := '';
  if Assigned(OnBeforeUnload) then
    OnBeforeUnload(Self,msg);

    if msg <> '' then
    begin
      asm
        Event.returnValue = msg;
        return msg;
      end;
    end;

  Result := true;
end;

function TCustomForm.HandleDoClick(Event: TJSMouseEvent): Boolean;
begin
  Event.stopPropagation;

  if FPopupClose = pcOnDeactivate then
    Close;

  Result := True;
end;

function TCustomForm.HandleDocMouseMove(Event: TJSMouseEvent): Boolean;
var
  deltax,deltay: double;
  el: TJSHTMLElement;
  l,t: integer;
begin
  if FDown then
  begin
    deltax := Event.screenX - FMdx;
    deltay := Event.screenY - FMdy;

    if FHasCaption then
      el := TJSHTMLElement(Container.parentNode)
    else
      el := TJSHTMLElement(Container);

    el.style.setProperty('transform','');
    el.style.setProperty('position','absolute');

    l := round(FDlgX + deltax);
    t := round(FDlgY + deltay);

    el.style.setProperty('left', inttostr(l)+'px');
    el.style.setProperty('top', inttostr(t)+'px');

    FLeft := l;
    FTop := t;

//    el := TJSHTMLElement(FCancel);
//    el.style.setProperty('position', 'absolute');
//    el.style.setProperty('top', '0');
//    el.style.setProperty('right', '0');
  end;

  Result := true;
end;

function TCustomForm.HandleDocMouseUp(Event: TJSMouseEvent): Boolean;
begin
  if FDown then
  begin
    FCaptured := false;
    FDown := false;
    document.body.removeEventListener('mousemove', FDocMouseMovePtr);
    document.body.removeEventListener('mouseup', FDocMouseUpPtr);
    if Assigned(FMoveSpan) then
      document.body.removeChild(FMoveSpan);
    FMoveSpan := nil;
  end;
  Result := true;
end;

procedure TCustomForm.HandleDoResize;
var
  dr: TJSDOMRect;
  neww,newh: integer;
begin
  dr := Container.getBoundingClientRect;

  neww := Round(dr.Right - dr.Left);
  newh := Round(dr.Bottom - dr.Top);

  if (neww <> FOrigWidth) or (newh <> FOrigHeight) then
  begin
    Width := neww;
    Height := newh;

    dr := Container.getBoundingClientRect;
    FOrigWidth := Round(dr.Right - dr.Left);
    FOrigHeight := Round(dr.Bottom - dr.Top);

    AlignControl(Self);
  end;
end;


function TCustomForm.HandleKeyDown(Event: TJSKeyBoardEvent): Boolean;
var
  k: Word;
begin
  k := GetKeyCode(Event.Key);
  if not Assigned(k) then
    Exit;
  if (k = VK_ESCAPE) and not (csDesigning in ComponentState) then
  begin
    Close;
  end;
  Result := true;
end;

function TCustomForm.HandleTitleDown(Event: TJSMouseEvent): Boolean;
var
  r: TJSDOMRect;
begin
  Event.StopPropagation;
  Event.PreventDefault;

  FPopupClose := pcNever;

  FMdx := Round(Event.screenX);
  FMdy := Round(Event.screenY);

  if FHasCaption then
    r := TJSHTMLElement(Container.parentNode).getBoundingClientRect()
  else
    r := TJSHTMLElement(Container).getBoundingClientRect();

  FDlgX := Round(int(r.left));
  FDlgY := Round(int(r.top));

  FDown := true;

  if not FCaptured then
  begin
    FCaptured := true;
    FMoveSpan := TJSHTMLElement(document.createElement('SPAN'));
    FMoveSpan.style.setProperty('top', '0');
    FMoveSpan.style.setProperty('left', '0');
    FMoveSpan.style.setProperty('right', '0');
    FMoveSpan.style.setProperty('bottom', '0');
    FMoveSpan.style.setProperty('position', 'absolute');

    document.body.appendChild(FMoveSpan);

    document.body.addEventListener('mousemove', FDocMouseMovePtr);
    document.body.addEventListener('mouseup', FDocMouseUpPtr);
  end;

  Result := true;
end;

procedure TCustomForm.DoClose(var CloseAction: TCloseAction);
begin
  if Assigned(FModalProc) and (CloseAction <> caNone) then
  begin
    FModalProc(FModalResult);
    FModalProc := nil;
  end;
end;

procedure TCustomForm.DoCreate;
begin
  BeginUpdate;
  LoadDFMValues;
  Loaded;

  if Assigned(OnCreate) then
    OnCreate(Self);

  FCreating := false;

  EndUpdate;

  UpdateChildren(Self);

  AlignControl(Self);

  DoResize;
end;

function TCustomForm.HandleScroll(Event: TEventListenerEvent): boolean;
begin
  if Assigned(OnScroll) then
    OnScroll(Self);

  Result := true;
end;

procedure TCustomForm.DoShow;
begin
  if Assigned(OnShow) then
    OnShow(Self);
end;

function TCustomForm.HandleUnload(Event: TEventListenerEvent): boolean;
begin
  if Assigned(OnUnload) then
    OnUnload(Self);
  Result := true;
end;

function TCustomForm.FormContainerElement: TJSElement;
begin
  if (csDesigning in ComponentState) then
  begin
    if Assigned(FDesignContainer) then
      Result := FDesignContainer
    else
      Result := document.body;
    Exit;
  end;

  if (FormContainer <> '') then
  begin
    //ElementID := FormContainer;
    Result := document.getElementById(FormContainer);
    if not Assigned(Result) then
      Result := document.body;
  end
  else
  begin
    Result := document.body;
  end;
end;

function TCustomForm.HandleLoaded(Event: TEventListenerEvent): boolean;
begin
  Resize;
  InitAnchoring;
  Result := true;
end;

procedure TCustomForm.DoResize;
begin
  if Assigned(OnResize) then
    OnResize(Self);
end;

destructor TCustomForm.Destroy;
begin
  if Visible then
    Close;

  if Assigned(OnDestroy) then
    OnDestroy(Self);

  inherited;

  if Assigned(FLayer) then
  begin
    if FormContainerElement = FLayer.parentNode then
      FormContainerElement.removeChild(FLayer);
    FLayer := nil;
  end;
end;

constructor TCustomForm.Create(id: string);
begin
  FNoHTML := false;
  FCreating := true;
  FFormContainer := id;
  inherited Create(id);
  FFormElement := '';
  FModalResult := mrNone;
  FFormStyle := fsNormal;
  DoCreate;
end;

constructor TCustomForm.Create(id: string; var AReference);
begin
  FCreating := true;
  FFormContainer := id;
  inherited Create(id);
  AReference := Self;
  FFormElement := '';
  FModalResult := mrNone;
  FFormStyle := fsNormal;
  DoCreate;
end;

procedure TCustomForm.CreateInitialize;
begin
  inherited;
  FModalResult := mrNone;
  FFormStyle := fsNormal;
  FBorder := fbSizeable;
  FShadow := true;
  FTimerID := -1;
  FCaptionElement := nil;
  Color := clWhite;
  TJSEventTarget(window).addEventListener('load', FLoadedPtr);
end;

constructor TCustomForm.CreateNew(AOwner: TComponent; Dummy: Integer = 0);
begin
  Create(AOwner);
end;

constructor TCustomForm.CreateDialog(ACaption: string);
var
  AFileName: string;
begin
  FCreating := true;
  AFileName := ClassType.UnitName + cHTMLExt;
  inherited Create(cBodyTag);
  Caption := ACaption;
  Popup := true;
  Border := fbDialog;
  FormFileName := AFileName;

  Application.LoadForm(Self, AFileName);
end;

constructor TCustomForm.CreateDialogNew(ACaption: string);
var
  AFileName: string;
begin
  FNoHTML := true;
  FCreating := true;
  AFileName := ClassType.UnitName + cHTMLExt;
  inherited Create(cBodyTag);
  Caption := ACaption;
  Popup := true;
  Border := fbDialog;
  FormFileName := AFileName;
end;

constructor TCustomForm.CreateNew;
var
  AFileName: string;
begin
  FCreating := true;
  AFileName := ClassType.UnitName + cHTMLExt;
  inherited Create(cBodyTag);
  FormFileName := AFileName;
  Application.LoadForm(Self, AFileName);
end;

constructor TCustomForm.CreateNew(AFileName: string);
begin
  FCreating := true;
  inherited Create(cBodyTag);
  FormFileName := AFileName;
  Application.LoadForm(Self, AFileName);
end;

constructor TCustomForm.CreateNew(AProc: TFormCreatedProc);
begin
  FCreatedProc := AProc;
  CreateNew;
end;

constructor TCustomForm.CreateNew(AElementID: string; AProc: TFormCreatedProc);
var
  AFileName: string;
begin
  FCreating := true;
  inherited Create(AElementID);
  FCreatedProc := AProc;
  FFormElement := AElementID;
  AFileName := ClassType.UnitName + cHTMLExt;
  Application.LoadForm(Self, AFileName);
end;


{ TApplication }

function TApplication.CreateNewForm(AInstanceClass: TFormClass): TCustomForm;
begin
  Result := AInstanceClass.Create(cBodyTag);
end;

function TApplication.CreateNewForm(AInstanceClass: TFormClass; AElementID: string): TCustomForm;
var
  el: TJSHTMLElement;
  AForm: TForm;
begin
  el := TJSHTMLElement(document.getElementById(AElementID));

  AForm := AInstanceClass.Create(AElementID);
  AForm.FDesignContainer := el;
  AForm.FormContainer := AElementID;
  AForm.FFormElement := AElementID;
  AForm.CreateControl;
  AForm.Container := el;
  AForm.Init;

  Result := AForm;
end;

procedure TApplication.LoadForm(AForm: TCustomForm; AFormFile: string);
begin
  AForm.FPrevActiveForm := FActiveForm;

  FActiveForm := AForm;

  if FMainForm = nil then
    FMainForm := AForm;

  FLastReq := TJSXMLHttpRequest.new;
  FLastReq.addEventListener('load', @DoFormLoad);
  FLastReq.addEventListener('abort', @DoFormAbort);
  FLastReq.open('GET', AFormFile);
  FLastReq.send;
end;

procedure TApplication.Navigate(const AURL: string; ATarget: TNavigationTarget );
begin
  if ATarget = ntBlank then
    window.open(AURL, '_blank')
  else
    window.location.href := AURL;
end;

function TApplication.NeedsFormRouting: Boolean;
var
  s: string;
begin
  Result := HasQueryParam('form', s);
  if Result then
    RouteForm(s);
end;

function TApplication.ObjectURL(AFile: TJSHTMLFile): string;
var
  res: string;
begin
  asm
    res = URL.createObjectURL(AFile);
  end;
  Result := res;
end;

procedure TApplication.CreateForm(AInstanceClass: TFormClass; AElementID: string; var AReference);
begin
  CreateForm(AInstanceClass, AElementID, AReference, nil);
end;

procedure TApplication.CreateForm(AInstanceClass: TFormClass; AElement: TJSHTMLElement; var AReference); overload;
begin
  CreateForm(AInstanceClass, AElement.ID, AReference, nil);
end;

procedure TApplication.CreateForm(AInstanceClass: TFormClass; AElementID: string; var AReference; AProc: TFormCreatedProc);
var
  LFileName: string;

  function DoStatusCreate(Event: TEventListenerEvent): boolean;
  var
    LElem: TJSHTMLElement;
    LForm: TForm;
    LResponse: string;
    LIsBody: boolean;
  begin
    // HTML of the form being loaded
    asm
      LResponse = Event.target.responseText;
    end;

    LIsBody := AElementID = cBodyTag;

    if LIsBody then
      LElem := TJSHTMLElement(document.body)
    else
      LElem := TJSHTMLElement(document.getElementById(AElementID));

    LElem.innerHTML := LResponse;

    LForm := AInstanceClass.Create(AElementID, AReference);
    LForm.FormFileName := LFileName;

    if LForm.FormContainer = '' then
      LForm.FormContainer := AElementID;

    if not LIsBody then
    begin
      LForm.FFormElement := AElementID;
      LForm.Container := LElem;
    end;

    LForm.CreateControl;
    LForm.Init;

    // set previous active form
    LForm.FPrevActiveForm := FActiveForm;

    FActiveForm := LForm;

    if FMainForm = nil then
      FMainForm := LForm;

    if Assigned(AProc) then
      AProc(LForm);

    Result := true;

    ActivateChildScripts(LElem);

    FActiveForm.DoShow;
    // force resize
    FActiveForm.Resize;
  end;

begin
  if FIsRedirect then
    Exit;

  if FAppInitializing then
  begin
    if FCanCreateForm then
      FCanCreateForm := False
    else
      Exit;
  end;

  LFileName := AInstanceClass.UnitName + GetFormExtension;
  FLastReq := TJSXMLHttpRequest.new;
  FLastReq.addEventListener('load', @DoStatusCreate);
  FLastReq.open('GET', lFileName);
  {$IFDEF DEBUG}
  FLastReq.setRequestHeader('Cache-Control', 'no-cache');
  {$ENDIF}
  FLastReq.send;
end;

procedure TApplication.ActivateChildScripts(AElement: TJSHTMLElement);
begin
  // activate possible child scripts
  asm
    function nodeScriptReplace(node) {
            if ( nodeScriptIs(node) === true ) {
                    node.parentNode.replaceChild( nodeScriptClone(node) , node );
            }
            else {
                    var i        = 0;
                    var children = node.childNodes;
                    while ( i < children.length ) {
                            nodeScriptReplace( children[i++] );
                    }
            }

            return node;
    }
    function nodeScriptIs(node) {
            return node.tagName === 'SCRIPT';
    }
    function nodeScriptClone(node){
            var script  = document.createElement("script");
            script.text = node.innerHTML;
            for( var i = node.attributes.length-1; i >= 0; i-- ) {
                    script.setAttribute( node.attributes[i].name, node.attributes[i].value );
            }
            return script;
    }
    nodeScriptReplace(AElement);
  end;
end;

procedure TApplication.ReloadForm;
var
  lFileName: string;

  function DoStatusCreate(Event: TEventListenerEvent): boolean;
  var
    i: integer;
    ctl: TControl;
    s: string;
    sl: TStringList;
    response: string;

  begin
    asm
      response = Event.target.responseText;
    end;

    sl := TStringList.Create;

    // persist control state
    for i := FActiveForm.ControlCount - 1 downto 0 do
    begin
      ctl := FActiveForm.Controls[i];
      ctl.PersistInHTML;
      ctl.ElementHandle.Id := ctl.Name;
      s := ctl.Name +'='+ctl.ElementHandle.outerHTML;
      sl.Add(s);
    end;

    for i := FActiveForm.ControlCount - 1 downto 0 do
    begin
      ctl := FActiveForm.Controls[i];
      FActiveForm.RemoveComponent(ctl);
      ctl.Free;
    end;

    FActiveForm.ClearControls;
    FActiveForm.UnbindEvents;

    // HTML of the form being loaded
    document.body.innerHTML := response;

    FActiveForm.CreateControl;
    FActiveForm.DoCreate;
    FActiveForm.Init;

    // persist control state
    for i := FActiveForm.ControlCount - 1 downto 0 do
    begin
      ctl := FActiveForm.Controls[i];
      s := sl.Values[ctl.Name];
      ctl.ElementHandle.outerHTML := s;
      ctl.Container := document.getElementById(ctl.Name);
      ctl.BindEvents;
    end;

    sl.Free;

    FActiveForm.DoShow;

    Result := true;
  end;

begin
  lFileName := FActiveForm.UnitName + GetFormExtension;

  FLastReq := TJSXMLHttpRequest.new;
  FLastReq.addEventListener('load', @DoStatusCreate);
  FLastReq.open('GET', lFileName);
  {$IFDEF DEBUG}
  FLastReq.setRequestHeader('Cache-Control', 'no-cache');
  {$ENDIF}
  FLastReq.send;
end;

procedure TApplication.RemoveCSS(id: string);
var
  styleElement: TJSElement;
begin
  styleElement := document.getElementById(id);
  if Assigned(styleElement) then
  begin
    styleElement.parentNode.removeChild(styleElement);
  end;
end;

procedure TApplication.RouteForm(AParameter: string);
var
  frm: TCustomForm;
  fc: TFormClass;
begin
  fc := TFormClass(GetClass(AParameter));
  Application.CreateForm(fc, frm);
end;

procedure TApplication.CreateForm(AInstanceClass: TFormClass; var AReference);
begin
  CreateForm(AInstanceClass, cBodyTag, AReference, nil);
end;

procedure TApplication.CreateForm(AInstanceClass: TDataModuleClass; var AReference);
var
  lModule: TDataModule;
begin
  lModule := AInstanceClass.Create(Self);
  AReference := lModule;
end;

procedure TApplication.CreateNewForm(AForm: TCustomForm; HTML: string);
var
  eh,op,span,childspan,formspan: TJSHTMLElement;
  l,t,w,h: integer;
  LCreatedProc: TFormCreatedProc;
  LCaptionDiv: TJSHTMLElement;
  LCaptionHeight: integer;
begin
  if FMainForm = nil then
    FMainForm := AForm;

  span := nil;
  formspan := nil;
  LCaptionHeight := 0;

  // form hosted in designated container
  if not FActiveForm.Popup and (FActiveForm.FFormElement <> '') then
  begin
    FActiveForm.FLayer := nil;
    eh := TJSHTMLElement(document.getElementById(FActiveForm.FFormElement));
    if Assigned(eh) then
    begin
      eh.innerHTML := HTML;
      if (FActiveForm.ElementClassname = '') then
        eh.style.setProperty('background-color',ColorToHTML(FActiveForm.Color));
    end;
  end
  else
  begin
    FActiveForm.ClearControls;
    // transparent mouse blocking layer
    FActiveForm.FLayer := FActiveForm.CreateLayer;
    document.body.appendChild(FActiveForm.FLayer);
    eh := TJSHTMLElement(FActiveForm.FLayer);

    // insert opacity layer
    if FActiveForm.Popup and (FActiveForm.PopupOpacity < 1) then
    begin
      op := FActiveForm.CreateLayer;
      op.style.setProperty('background-color', 'black');
      op.style.setProperty('opacity', DoubleToHTML(FActiveForm.PopupOpacity));
      op.style.setProperty('z-index', '9998');
      eh.appendChild(op);
    end;

    span := TJSHTMLElement(document.createElement('SPAN'));
    span.style.setProperty('z-index', '9999');

    if FActiveForm.ElementClassName <> '' then
      span.setAttribute('class',FActiveForm.ElementClassName);

    if FActiveForm.Shadow then
      span.style.setProperty('box-shadow','3px 3px 3px silver');

    eh.appendChild(span);

    if (FActiveForm.Border = fbDialog) then
    begin
      FActiveForm.GetMethodPointers;
      LCaptionDiv := FActiveForm.CreateCaption;
      span.appendChild(LCaptionDiv);
      childspan := TJSHTMLElement(document.createElement('SPAN'));
      childspan.style.setProperty('position','absolute');
      childspan['id']:= 'childspan';
      span.appendChild(childspan);
      formspan := childspan;
      LCaptionHeight := 22;
    end
    else
    begin
      formspan := span;
    end;

    formspan.innerHTML := HTML;
    FActiveForm.Container := formspan;
  end;

  FActiveForm.Init;
  FActiveForm.LoadDFMValues;

  if Assigned(FActiveForm.FLayer) then
  begin
    // ensure full screen background is white or adopts the form elementclassname when it is not popup
    if not FActiveForm.Popup then
    begin
      if (FActiveForm.ElementClassName = '') then
        eh.style.setProperty('background-color','white')
      else
      eh.setAttribute('class',FActiveForm.ElementClassName);
    end;
  end;


  // position popup form centered
  if Assigned(span) and (FActiveForm.FormContainer = '') and FActiveForm.Popup then
  begin
    span.style.setProperty('position', 'absolute');

    l := Round((window.innerWidth - FActiveForm.Width)/2);
    t := Round((window.innerHeight - FActiveForm.Height)/2);
    w := FActiveForm.Width;
    h := FActiveForm.Height + LCaptionHeight;

    span.style.setProperty('background-color',ColorToHTML(FActiveForm.Color));
    span.style.setProperty('border','1px Black solid');

    span.style.setProperty('top',IntTostr(t)+'px');
    span.style.setProperty('left',IntToStr(l)+'px');
    span.style.setProperty('width',IntToStr(w)+'px');
    span.style.setProperty('height',IntToStr(h)+'px');
  end;

  if Assigned(formspan) then
    ActivateChildScripts(formspan);

  if Assigned(FActiveForm.FCreatedProc) then
  begin
    LCreatedProc := FActiveForm.FCreatedProc;
    FActiveForm.FCreatedProc := nil;
    LCreatedProc(FActiveForm);
  end;

  if Assigned(FActiveForm.OnCreate) then
    FActiveForm.OnCreate(FActiveForm);

  FActiveForm.Realign;
  FActiveForm.Loaded;
  FActiveForm.InitAnchoring;
  FActiveForm.UpdateChildAnchoring;
  FActiveForm.FCreating := false;
  FActiveForm.UpdateElement;

  if not FActiveForm.EventsBound then
    FActiveForm.BindEvents;

  FActiveForm.DoShow;
end;

function TApplication.DoFormLoad(Event: TEventListenerEvent): boolean;
var
  LResponse: string;
begin
  asm
    LResponse = Event.target.responseText;
  end;

  CreateNewForm(FActiveForm, LResponse);
  Result := true;
end;

function TApplication.DoHandleError(Event: TJSErrorEvent): boolean;
var
  err: TAppplicationError;
  el,sp,x: TJSHTMLElement;
  msg,s: string;
begin
  err.AMessage := Event.message;
  err.AFile := Event.filename;
  err.ALineNumber := Event.lineno;
  err.AColNumber := Event.colno;
  err.AError := Event.error;
  err.AStack := '';
  s := '';
  // try to get stack & RTL exception message
  if Assigned(Event.error) then
  begin

    asm
      if (Event.error.stack) {
        err.AStack = Event.error.stack;
      }
      if (Event.error.fMessage) {
        err.AMessage = Event.error.fMessage;
      }
    end;

    asm
      function objToString (obj) {
      var str = '';
      for (var p in obj) {
          if (obj.hasOwnProperty(p)) {
              str += p + '::' + obj[p] + '\n';
          }
      }
      return str;
      }
      s = objToString(Event.error);
    end;
  end;


  Result := false;

  msg := 'ERROR<br>' + err.AMessage + ' | ' + s + err.AStack + '<BR> at '+err.AFile + ' [' +inttostr(err.ALineNumber)+':'+inttostr(err.AColNumber)+']';

  case ErrorType of
  aeFooter:
    begin
      el := TJSHTMLElement(document.getElementById('tmserrormessage'));

      if Assigned(el) then
      begin
        (el.firstChild as TJSHTMLElement).innerHTML := msg;
      end
      else
      begin
        el := TJSHTMLElement(document.createElement('DIV'));
        el.setAttribute('id','tmserrormessage');
        el.style.setProperty('position','absolute');
        el.style.setProperty('font-family','Courier');
        el.style.setProperty('font-size','8pt');
        el.style.setProperty('bottom','0');
        el.style.setProperty('width','100%');
        el.style.setProperty('height','100px');
        el.style.setProperty('background','#ff0000');
        el.style.setProperty('color','#ffffff');
        TJSHTMLElement(document.body).style.setProperty('padding','0');
        TJSHTMLElement(document.body).style.setProperty('margin','0');
        sp := TJSHTMLElement(document.createElement('SPAN'));
        sp.style.setProperty('margin-left','4px');
        sp.style.setProperty('float','left');
        sp.style.setProperty('overflow','hidden');
        sp.style.setProperty('display','block');
        sp.innerHTML := msg;
        el.appendChild(sp);

        x := TJSHTMLElement(document.createElement('SPAN'));
        x.style.setProperty('font-family','Courier');
        x.style.setProperty('font-size','8pt');
        x.style.setProperty('font-weight','bold');
        x.style.setProperty('position','absolute');
        x.style.setProperty('width','20px');
        x.style.setProperty('vertical-align','top');
        x.style.setProperty('cursor','pointer');
        x.style.setProperty('text-align','right');
        x.style.setProperty('right','4px');
        x.style.setProperty('display','block');
        x.innerHTML := 'X';
        x.onClick := @DoErrorClose;
        el.appendChild(x);

        document.body.appendChild(el);
      end;
    end;
  aeAlert: window.alert(msg);
  aeDialog:
    begin
        AddControlLink('googlematerial', 'https://fonts.googleapis.com/icon?family=Material+Icons');
      MessageDlg(msg, mtError, [mbOK]);
    end;
  end;

  if Assigned(OnError) then
    OnError(Self, err, Result);
end;

function TApplication.DoHashChange(Event: TEventListenerEvent): boolean;
var
  s: string;
  fc: TFormClass;
  frm: TCustomForm;
  bdy: TJSHTMLElement;
  Handled: boolean;

begin
  Result := true;

  if not AutoFormRoute then
    Exit;

  asm
    s  = location.hash;
  end;

  // remove hash sign to get class name
  Delete(s,1,1);

  Handled := false;

  if Assigned(OnHashChange) then
    OnHashChange(Self, s, Handled);

  if Handled then
    Exit;

  if s = '' then
    s := FInitFormClassName;

  if s <> '' then
  begin
    if FInitFormClassName = '' then
      FInitFormClassName := FActiveForm.ClassName;

    fc := TFormClass(GetClass(s));

    if Assigned(fc) then
    begin
      bdy := TJSHTMLElement(document.body);
      CreateForm(fc, bdy, frm);
    end;
  end;
end;

function TApplication.DoUpdateOnlineStatus(Event: TEventListenerEvent): boolean;
begin
  Result := true;

  if Assigned(OnOnlineChange) then
  begin
    if window.navigator.onLine then
      OnOnlineChange(Self, osOnline)
    else
      OnOnlineChange(Self, osOffline);
  end;
end;

procedure TApplication.Download(const AURL: string);
begin
  asm
    window.location.href = AURL;
  end;
end;

procedure TApplication.DownloadTextFile(const AText: string; AFileName: string = '');
begin
  asm
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(AText));
    if (AFileName != ''){
      element.setAttribute('download', AFileName);
    }
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  end;
end;

function TApplication.EXEName: string;
begin
  Result := window.document.location.href;
end;

procedure TApplication.DownloadBinaryFile(const Data: TJSUint8Array; AFileName: string = '');
begin
  asm
    var element = document.createElement('a');
    var blob = new Blob([Data], {type: "octet/stream"})
    var url = window.URL.createObjectURL(blob);
    element.href = url;
    if (AFileName != ''){
      element.setAttribute('download', AFileName);
    }
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  end;
end;

function TApplication.GetAuthorizationPageHTML(const AAuthorizationSuccess: Boolean): string;
const
  sExternalBrowserAuthorizationOK = '<div class="text2">Application succesfully authorized</div>You can close this browser window';
  sExternalBrowserAuthorizationFailed = '<div class="text2">Application authorization failed</div><br/>Please try again.';
  LB = #13#10;

  ImageFail = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeA'+
  'AAACXBIWXMAAA7DAAAOwwHHb6hkAAABtElEQVR4nO2bUY7CMAxEhz0NB+EgrDjXCg6yB+E4+9NKCAiN'+
  '7XHcjf1+ED+Z6ahNHScFiqIoBNwvp+v9crpG+3hG6+sgFQFwXv7ejj+/31JBDyy+ugN4ElkJD8Hqqyu'+
  'AhohYjA3D12YAGyIiMSYsXx8D6BTpFmPB9NUMQCjSJcaA7ettAEqRTTErHr5eAjCKfBSz4OXryzhgiz'+
  'OzWCJd/Fs8HoFHzHeCtxf2JNgt3MMID8zXoMpAtDarEDIbidJklMJUQ6MDty6GNDSNRdxtluWwhReDU'+
  'fONKADAx2jkZCsOAOAaXn4pY2let6oAAN/qTIG61lAHAOwmBFO1aQoACA/BXGqbAwDCQqCsOCkBAMND'+
  'oC23aQEAw0Kg9hqoAQDuIdAbLfQAALcQXFptLgEA9BDc+oxeLbF/Qz0C7AFTT4KpX4OpC6HUpXDqxVD'+
  'q5fBOLn5lbEMkdUssdVM0dVs89cZI6q2x1JujqbfHUx+Q2MPFj/LidUqsKajB05NXS4y6Zl/GurHGe6'+
  'QOSpLF5jgqqxSb67C0UGzO4/KdYnN/MLEhluOTmYZY+MWvDPU122dzRVHk5g+X6Lw5aVkK9AAAAABJRU5ErkJggg==';

  ImageSuccess = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaX'+
  'HeAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACC0lEQVR4nO2Zv3GDMBSHv+QyQEbICM4EOW+Q9BS4o2QTU'+
  '9LZBQNkg6zgETyCR0iRkOMIAv3Xk0+/zsiI931nI3iCkpKSkpKSkpKSkpJ7SDN0x2bo3nW//xCymNhp'+
  'hu4E1MAN2PdVe9k6524ETODHXIHXvmpva+c9BqwpWhbgAV6Ar61zsxeggB+z+x1XJmsBG/Bj6mboWtV'+
  'gtvcATfhpPvqq/ZwfzFKABTwoVobsBFjCj7kyWxmyugc4wsPCypCNAA/wY/L7C3iEP/dVe5geEC8gJD'+
  'wIFxAaHgQLiAEPQgXEggeBAmLCgzABseFBkIAU8CBEQCp4ECAgJTwYChibCzYXWpmv9jCVFTwYCJgVa'+
  '31BxXwucapFS4CiWOsLS4EHDQEbxRoXIAkeNgRoFqtdiDR4WBFgWOxmQRLhQdEQsSi2bobuqBqUCg8L'+
  'vwDHYg991Z49zjeNd3iYCfBU7J8E6fAAT7PPOw9znpqhA3hDODz8F7Dnp2vqKmJ1O8ogQeFh+R7wjB8'+
  'JrgkOD4plUICEKPCw/hyQSkI0eNh+EowtISo86L0LxJIQHR703wZDS0gCD2b9gFASksGDeUfIt4Sk8G'+
  'DRE/QoITk8WDZFPUgQAQ8OXWEHCWLgwbEtbiFBFDx42BcwkCAOHjxtjGhIEAkPHneGViSIhQfPW2MLE'+
  'kTDQ4C9wYmEi3T4kpKSkm+5ax+YhPsUTQAAAABJRU5ErkJggg==';

  PlaceHolderImage = '#PLACEHOLDERIMAGE#';
  PlaceHolderText = '#PLACEHOLDERTEXT#';
  PlaceHolderColor = '#PLACEHOLDERCOLOR#';

  AuthHTMLTemplate =
  '<!doctype html>'+LB+
  '<html lang="en">'+LB+
  '<head>'+LB+
  '  <title>Authentication Result</title>'+LB+
  '  <meta name="viewport" content="width=device-width, initial-scale=1">'+LB+
  '</head>'+LB+
  '<body>'+LB+
  '<style>'+LB+
  '  hr'+LB+
  '  {'+LB+
  '    border: none;'+LB+
  '    height: 1px;'+LB+
  '    background-color: rgb(171, 171, 171);'+LB+
  '  }'+LB+
  '  div.container'+LB+
  '  {'+LB+
  '    position: fixed;'+LB+
  '    font-family: Arial;'+LB+
  '    font-size: 12pt;'+LB+
  '    max-width: 100%;'+LB+
  '    max-height: 100%;'+LB+
  '    top: 50%;'+LB+
  '    left: 50%;'+LB+
  '    transform: translate(-50%, -50%);'+LB+
  '  }'+LB+
  '  div.section'+LB+
  '  {'+LB+
  '    display: inline-block;'+LB+
  '    margin: 15px;'+LB+
  '    padding: 5px;'+LB+
  '    float: left;'+LB+
  '  }'+LB+
  '  div.image img'+LB+
  '  {'+LB+
  '    height: 100%;'+LB+
  '  }'+LB+
  '  div.text'+LB+
  '  {'+LB+
  '    padding-top: 15px;'+LB+
  '  }'+LB+
  '  div.text2'+LB+
  '  {'+LB+
  '    font-size: 18pt;'+LB+
  '    color: #PLACEHOLDERCOLOR#;'+LB+
  '  }'+LB+
  '  span.title'+LB+
  '  {'+LB+
  '    font-size: 26px;'+LB+
  '  }'+LB+
  '</style>'+LB+
  '  <div class="container">'+LB+
  '  <div class="section image">'+LB+
  '    <img src="#PLACEHOLDERIMAGE#">'+LB+
  '  </div>'+LB+
  '  <div class="section text">'+LB+
  '  #PLACEHOLDERTEXT#'+LB+
  '  </div>'+LB+
  '  </div>'+LB+
  ' </body>'+LB+
  '</html>';

  function ReplacePlaceHolders(APlaceHolders: array of string; APlaceHolderValues: array of string): string;
  var
    I: Integer;
    s: string;
  begin
    s := AuthHTMLTemplate;
    for I := 0 to Length(APlaceHolders) - 1 do
      s := StringReplace(s, APlaceHolders[I], APlaceHolderValues[I], [rfReplaceAll]);

    Result := s;
  end;

begin
  if AAuthorizationSuccess then
    Result := ReplacePlaceHolders([PlaceHolderImage, PlaceHolderText, PlaceHolderColor], [ImageSuccess, sExternalBrowserAuthorizationOK, 'rgb(104, 164, 144)'])
  else
    Result := ReplacePlaceHolders([PlaceHolderImage, PlaceHolderText, PlaceHolderColor], [ImageFail, sExternalBrowserAuthorizationFailed, 'rgb(216, 99, 68)']);
end;

function TApplication.GetFormExtension: string;
begin
  if Language <> lNone then
    Result := '_' + GetLanguageISO639_1Code(Language) + cHTMLExt
  else
    Result := cHTMLExt;
end;

function TApplication.GetIsOnline: boolean;
begin
  Result := window.navigator.onLine;
end;

{$IFDEF SINGLEINSTANCE}
procedure TApplication.DoGetDataReceived(Sender: TObject; Origin: string;
  Data: TJSObject);
begin
  if Data.toString = '#reload' then
  begin
    asm
      location.reload();
    end;
  end;
end;

procedure TApplication.DoGetConnected(Sender: TObject);
var
  url,agent:string;
begin
  url := window.location.href;
  agent := window.navigator.useragent;

  if Pos('Edge', agent) > 0 then
    agent := 'Edge'
  else if Pos('Opera', agent) > 0 then
    agent := 'Opera'
  else if Pos('OPR', agent) > 0 then
    agent := 'Opera'
  else if Pos('Chrome', agent) > 0 then
    agent := 'Chrome'
  else if Pos('Safari', agent) > 0 then
    agent := 'Safari'
  else if Pos('Firefox', agent) > 0 then
    agent := 'Firefox'
  else if Pos('Trident', agent) > 0 then
    agent := 'MSIE'
  else
    agent := '';

  FWebSocket.Send(url + ';' + agent);
end;
{$ENDIF}

function TApplication.DoErrorClose(Event: TJSMouseEvent): boolean;
begin
  document.body.removeChild(Event.target.parentElement);
  Result := true;
end;

function TApplication.DoFormAbort(Event: TEventListenerEvent): boolean;
begin
  ShowMessage('Failed to load form HTML template file');
  Result := true;
end;


procedure TApplication.InitFormatSettings(const BrowserLocale: string);
var
  timestr: string;
  i: integer;
  locale: string;
begin
  locale := BrowserLocale;

  FormatSettings.ShortDateFormat := GetLocaleShortDateFormat(locale);

  if (Pos('/',FormatSettings.ShortDateFormat) > 0) then
    FormatSettings.DateSeparator := '/';

  if (Pos('.',FormatSettings.ShortDateFormat) > 0) then
    FormatSettings.DateSeparator := '.';

  if (Pos('-',FormatSettings.ShortDateFormat) > 0) then
    FormatSettings.DateSeparator := '-';

  asm
    var event = new Date('Jan 1, 1980 06:07:08 GMT+00:00');
    var loc = "";
    if (locale == "") { loc = navigator.language; } else
    { loc = locale; }
    timestr = event.toLocaleTimeString(loc);
  end;

  if pos(':',timestr) > 0 then
    FormatSettings.TimeSeparator := ':';

  if pos('.',timestr) > 0 then
    FormatSettings.TimeSeparator := '.';

  if pos('-',timestr) > 0 then
    FormatSettings.TimeSeparator := '-';

  for i := 1 to 7 do
  begin
    ShortDayNames[i] := GetLocaleShortDayName(i,locale);
    LongDayNames[i] := GetLocaleLongDayName(i,locale);
  end;

  for i := 1 to 12 do
  begin
    ShortMonthNames[i] := GetLocaleShortMonthName(i,locale);
    LongMonthNames[i] := GetLocaleLongMonthName(i,locale);
  end;

  FormatSettings.DecimalSeparator := GetLocaleDecimalSeparator;

  if FormatSettings.DecimalSeparator = '.' then
    FormatSettings.ThousandSeparator := ','
  else
    FormatSettings.ThousandSeparator := '.';
end;

{$HINTS OFF}
procedure TApplication.Initialize;
var
  query, token: string;
  p: Integer;
  b: Boolean;
begin
  FCanCreateForm := True;
  FAppInitializing := True;
  FParameters.Clear;
  query := window.location.href;

  InitFormatSettings(GetBrowserLocale);

  FParameters.Delimiter := '&';
  FParameters.StrictDelimiter := true;
  p := Pos('?', query);
  if p > 0 then
    query := Copy(query, p + 1, Length(query));
  FParameters.DelimitedText := query;

  asm
    b = (window.name == "Authentication");
  end;

  if (FParameters.IndexOfName('oauthcallback') <> -1) or b then
  begin
    if Assigned(OnOAuthCallBack) then
      OnOauthCallBack(Self, query);

    FIsRedirect := true;
    document.body.innerHTML := GetAuthorizationPageHTML(True);
    Exit;
  end;

  if FParameters.IndexOfName('code') <> -1 then
  begin
    if Assigned(OnOAuthToken) then
      OnOauthToken(Self, query);
    FIsRedirect := true;
    token := FParameters.Values['code'];
    asm
      if (window.opener && window.opener.processAuthData){
        window.opener.processAuthData(token);
      	window.close();
      }
    end;
  end;

  window.addEventListener('hashchange',@DoHashChange);
  window.addEventListener('online',  @DoUpdateOnlineStatus);
  window.addEventListener('offline', @DoUpdateOnlineStatus);


  if FParameters.IndexOfName('access_token') <> -1 then
  begin
    FIsRedirect := true;
    token := FParameters.Values['access_token'];
    asm
      window.opener.processAuthData(token);
    end;
  	window.close();
  end;
end;

procedure TApplication.InsertCSS(id, href: string);
var
  styleElement: TJSElement;
begin

  styleElement := document.getElementById(id);
  if Assigned(styleElement) then
  begin
    asm
      styleElement.href = href;
    end;
    Exit;
  end;

  styleElement := document.createElement('link');
  styleElement.id := id;

  asm
    styleElement.rel = 'stylesheet';
    styleElement.href = href;
  end;

  document.getElementsByTagName('head')[0].appendChild(styleElement);
end;

{$HINTS ON}

procedure TApplication.Run;
begin
  FCanCreateForm := True;
  FAppInitializing := False;
end;

procedure TApplication.RunScript(Source: string);
begin
  asm
    eval(Source);
  end;
end;

procedure TApplication.ReceiveMessageFromClient(const AMessage: string);
begin
  if Assigned(FClientConnector) then
    FClientConnector.Receive(AMessage);
end;

procedure TApplication.SetLanguage(const Value: TUILanguage);
begin
  FLanguage := Value;
  ReloadForm;
end;

procedure TApplication.ChangeCSS(id, href: string);
begin
  asm
    var styleElement = document.getElementById(id);
    if (styleElement) {
      styleElement.href = href;
      return;
    }
  end;
end;

constructor TApplication.Create(AOwner: TComponent);
begin
  FFormStack := TList.Create;
  FParameters := TStringList.Create;
  FMainForm := nil;
  FActiveForm := nil;
  FInitFormClassName := '';
  FIsRedirect := false;
  FAutoFormRoute := false;
  FThemeColor := $E39E00; //clBlue;
  FThemeTextColor := clWhite;
  {$IFDEF SINGLEINSTANCE}
  FWebSocket := TSocketClient.Create(Self);
  FWebSocket.Port := DefaultPort;
  FWebSocket.OnDataReceived := @DoGetDataReceived;
  FWebSocket.OnConnect := @DoGetConnected;
  FWebSocket.Connect;
  {$ENDIF}

  window.addEventListener('error', @DoHandleError);

  FErrorType := aeSilent;
  {$IFDEF DEBUG}
  FErrorType := aeFooter;
  {$ENDIF}

  {$IFDEF FREEWARE}
  asm
    alert('TMS WEB Core \u00A9 2018 - 2020 tmssoftware.com - Trial Version');
  end;
  {$ENDIF}
end;

destructor TApplication.Destroy;
begin
  {$IFDEF SINGLEINSTANCE}
  FWebSocket.Free;
  {$ENDIF}
  FFormStack.Free;
  FParameters.Free;
  inherited Destroy;
end;

procedure TApplication.LockForm(AForm: TCustomForm);
var
  i: integer;
begin
  for i := 0 to AForm.ControlCount - 1 do
  begin
    AForm.Controls[i].DisableTab;
  end;
end;

procedure TApplication.UnLockForm(AForm: TCustomForm);
var
  i: integer;
begin
  for i := 0 to AForm.ControlCount - 1 do
  begin
    AForm.Controls[i].EnableTab;
  end;
end;

procedure TApplication.PushForm(AForm: TCustomForm);
var
  i: integer;
begin
  // persist all control settings in HTML to be saved
  for i := 0 to AForm.ControlCount - 1 do
  begin
    AForm.Controls[i].PersistInHTML;
  end;

  // save with form
  AForm.FormContent := document.body.innerHTML;

  for i := 0 to AForm.ControlCount - 1 do
  begin
    AForm.Controls[i].DisableTab;
  end;

  FFormStack.Add(AForm);
end;

function TApplication.PopForm: TCustomForm;
var
  i: integer;
  frm: string;
begin
  if (FFormStack.Count > 0) then
  begin
    Result := TCustomForm(FFormStack.Items[FFormStack.Count - 1]);
    frm := Result.FormContent;
    FFormStack.Delete(FFormStack.Count - 1);

    // restore document
    document.body.innerHTML := frm;

    Result.InitFromHTML;

    // rebind form events
    Result.BindEvents;

    // rehook controls
    for i := 0 to Result.ControlCount - 1 do
      Result.Controls[i].HookElement;

    for i := 0 to Result.ControlCount - 1 do
    begin
      Result.Controls[i].RecreateCanvas;
      Result.Controls[i].InternalResize;
    end;

    Application.FActiveForm := Result;
  end;
end;

{ TCustomFrame }

constructor TCustomFrame.Create(AOwner: TComponent);
begin
  inherited Create(AOwner);
  FConstraints := TSizeConstraints.Create(Self);
  FPopupMenu := TPopupMenu.Create(Self);
  FTouchManager := TTouchManager.Create(Self);
end;

destructor TCustomFrame.Destroy;
begin
  FConstraints.Free;
  FPopupMenu.Free;
  FTouchManager.Free;
  inherited Destroy;
end;

procedure TCustomFrame.LoadDFMValues;
begin
end;

procedure TCustomFrame.LoadFromForm;
begin
  LoadDFMValues;
end;

procedure TCustomFrame.setConstraints(const AValue: TSizeConstraints);
begin
  FConstraints.Assign(AValue);
end;

procedure TCustomFrame.SetPopupMenu(AValue: TPopupMenu);
begin
  FPopupMenu.Assign(AValue);
end;

procedure TCustomFrame.SetTouchManager(AValue: TTouchManager);
begin
  FTouchManager.Assign(AValue);
end;

{ TCustomSizeConstraints }

constructor TCustomSizeConstraints.Create(AControl: TControl);
begin
  inherited Create;
  FControl := AControl;
end;

procedure TCustomSizeConstraints.AssignTo(ADest: TPersistent);
begin
  if ADest is TCustomSizeConstraints then
  begin
    with TCustomSizeConstraints(ADest) do
    begin
      FMinHeight := Self.FMinHeight;
      FMaxHeight := Self.FMaxHeight;
      FMinWidth := Self.FMinWidth;
      FMaxWidth := Self.FMaxWidth;
      Change;
    end;
   end
  else
    inherited AssignTo(ADest);
end;

procedure TCustomSizeConstraints.SetConstraints(AIndex: Integer; AValue: TConstraintSize);
begin
  case AIndex of
    0:
      if AValue <> FMaxHeight then
      begin
        FMaxHeight := AValue;
//        if not FControl.FIScaling then
        begin
          if (AValue > 0) and (AValue < FMinHeight) then
            FMinHeight := AValue;
          Change;
        end;
      end;
    1:
      if AValue <> FMaxWidth then
      begin
        FMaxWidth := AValue;
//        if not FControl.FIScaling then
        begin
          if (AValue > 0) and (AValue < FMinWidth) then
            FMinWidth := AValue;
          Change;
        end;
      end;
    2:
      if AValue <> FMinHeight then
      begin
        FMinHeight := AValue;
//        if not FControl.FIScaling then
        begin
          if (FMaxHeight > 0) and (AValue > FMaxHeight) then
            FMaxHeight := AValue;
          Change;
        end;
      end;
    3:
      if AValue <> FMinWidth then
      begin
        FMinWidth := AValue;
 //       if not FControl.FIScaling then
        begin
          if (FMaxWidth > 0) and (AValue > FMaxWidth) then
            FMaxWidth := AValue;
          Change;
        end;
      end;
  end;
end;

procedure TCustomSizeConstraints.Change;
begin
  if Assigned(FOnChange) then
    FOnChange(Self);
end;

{ TDragDockObject }

constructor TDragDockObject.Create(AControl: TControl);
begin
  inherited Create;
  FBrush := TBrush.Create;
end;

destructor TDragDockObject.Destroy;
begin
  FBrush.Free;
  inherited Destroy;
end;

procedure TDragDockObject.Assign(Source: TDragObject);
begin
end;


initialization
begin
  HandShakeScript := TJSHTMLScriptElement(document.createElement('script'));
  HandShakeScript.id := 'HandShakeScript';
  HandShakeScript.type_ := 'text/javascript';
  HandShakeScript.innerHTML :=
  'var IDE = null;'+#13#10+
  'var TMSWEBCoreClientIdentifier = "unknown";'+#13#10+
  'var TMSWEBCoreOAuthCallback = "unknown";'+#13#10+
  'function HandShake(cid){'+#13#10+
  '  TMSWEBCoreClientIdentifier = cid;'+#13#10+
  '}';
  document.body.appendChild(HandShakeScript);

  Application := TApplication.Create(nil);
end;

end.


