{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright © 2016                                        }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCustomSelector;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.Controls
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,UITypes, Generics.Collections, Types
  {$ENDIF}
  {$ENDIF}
  {$IFDEF LCLLIB}
  ,fgl
  {$ENDIF}
  ;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.  

type
  TTMSFNCCustomSelector = class;

  TTMSFNCCustomSelectorItemState = (isNormal, isHover, isDown, isSelected, isDisabled);

  TTMSFNCCustomSelectorItem = class(TCollectionItem)
  private
    FOwner: TTMSFNCCustomSelector;
    FRowSpan: Integer;
    FColumnSpan: Integer;
    FVisible: Boolean;
    FText: String;
    FEnabled: Boolean;
    FSeparator: Boolean;
    FSeparatorHeight: Single;
    FMargins: TTMSFNCMargins;
    FCanDeselect: Boolean;
    FCanSelect: Boolean;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FHint: string;
    FDataBoolean: Boolean;
    FDataString: String;
    FDataObject: TObject;
    FDataInteger: NativeInt;
    FDataPointer: Pointer;
    procedure SetColumnSpan(const Value: Integer);
    procedure SetRowSpan(const Value: Integer);
    procedure SetVisible(const Value: Boolean);
    procedure SetText(const Value: String);
    procedure SetEnabled(const Value: Boolean);
    procedure SetSeparator(const Value: Boolean);
    procedure SetSeparatorHeight(const Value: Single);
    procedure SetMargins(const Value: TTMSFNCMargins);
    procedure SetCanDeselect(const Value: Boolean);
    procedure SetCanSelect(const Value: Boolean);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    function IsSeparatorHeightStored: Boolean;
  protected
    procedure MarginsChanged(Sender: TObject);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
    function State: TTMSFNCCustomSelectorItemState;
    destructor Destroy; override;
    property DataPointer: Pointer read FDataPointer write FDataPointer;
    property DataBoolean: Boolean read FDataBoolean write FDataBoolean;
    property DataObject: TObject read FDataObject write FDataObject;
    property DataString: String read FDataString write FDataString;
    property DataInteger: NativeInt read FDataInteger write FDataInteger;
  published
    property CanSelect: Boolean read FCanSelect write SetCanSelect default True;
    property CanDeselect: Boolean read FCanDeselect write SetCanDeselect default True;
    property ColumnSpan: Integer read FColumnSpan write SetColumnSpan default 1;
    property RowSpan: Integer read FRowSpan write SetRowSpan default 1;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Enabled: Boolean read FEnabled write SetEnabled default True;
    property Separator: Boolean read FSeparator write SetSeparator default False;
    property SeparatorHeight: Single read FSeparatorHeight write SetSeparatorHeight stored IsSeparatorHeightStored nodefault;
    property Margins: TTMSFNCMargins read FMargins write SetMargins;
    property Text: String read FText write SetText;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property Hint: string read FHint write FHint;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCCustomSelectorItems = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCCustomSelectorItems = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCCustomSelectorItem>)
  {$ENDIF}
  private
    FOwner: TTMSFNCCustomSelector;
    function GetItem(Index: Integer): TTMSFNCCustomSelectorItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCCustomSelectorItem);
  protected
    function CreateItemClass: TCollectionItemClass; virtual;
    function GetOwner: TPersistent; override;
  public
    constructor Create(AOwner: TTMSFNCCustomSelector); virtual;
    property Items[Index: Integer]: TTMSFNCCustomSelectorItem read GetItem write SetItem; default;
    function Add: TTMSFNCCustomSelectorItem;
    function Insert(Index: Integer): TTMSFNCCustomSelectorItem;
  end;

  TTMSFNCCustomSelectorAppearance = class(TPersistent)
  private
    FOwner: TTMSFNCCustomSelector;
    FStrokeHover: TTMSFNCGraphicsStroke;
    FFillDown: TTMSFNCGraphicsFill;
    FVerticalSpacing: Single;
    FStrokeDown: TTMSFNCGraphicsStroke;
    FFillSelected: TTMSFNCGraphicsFill;
    FHorizontalSpacing: Single;
    FStrokeSelected: TTMSFNCGraphicsStroke;
    FFill: TTMSFNCGraphicsFill;
    FFillHover: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FFillDisabled: TTMSFNCGraphicsFill;
    FStrokeDisabled: TTMSFNCGraphicsStroke;
    FSeparatorStroke: TTMSFNCGraphicsStroke;
    FFont: TTMSFNCGraphicsFont;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFillDown(const Value: TTMSFNCGraphicsFill);
    procedure SetFillHover(const Value: TTMSFNCGraphicsFill);
    procedure SetFillSelected(const Value: TTMSFNCGraphicsFill);
    procedure SetHorizontalSpacing(const Value: Single);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetStrokeDown(const Value: TTMSFNCGraphicsStroke);
    procedure SetStrokeHover(const Value: TTMSFNCGraphicsStroke);
    procedure SetStrokeSelected(const Value: TTMSFNCGraphicsStroke);
    procedure SetVerticalSpacing(const Value: Single);
    procedure SetFillDisabled(const Value: TTMSFNCGraphicsFill);
    procedure SetStrokeDisabled(const Value: TTMSFNCGraphicsStroke);
    procedure SetSeparatorStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    function IsHorizontalSpacingStored: Boolean;
    function IsVerticalSpacingStored: Boolean;
  protected
    procedure Changed;
    procedure FontChanged(Sender: TObject);
    procedure FillChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create(AOwner: TTMSFNCCustomSelector);
    procedure Assign(Source: TPersistent); override;
    destructor Destroy; override;
  published
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property StrokeHover: TTMSFNCGraphicsStroke read FStrokeHover write SetStrokeHover;
    property FillHover: TTMSFNCGraphicsFill read FFillHover write SetFillHover;
    property StrokeDown: TTMSFNCGraphicsStroke read FStrokeDown write SetStrokeDown;
    property FillDown: TTMSFNCGraphicsFill read FFillDown write SetFillDown;
    property StrokeSelected: TTMSFNCGraphicsStroke read FStrokeSelected write SetStrokeSelected;
    property FillSelected: TTMSFNCGraphicsFill read FFillSelected write SetFillSelected;
    property StrokeDisabled: TTMSFNCGraphicsStroke read FStrokeDisabled write SetStrokeDisabled;
    property FillDisabled: TTMSFNCGraphicsFill read FFillDisabled write SetFillDisabled;
    property VerticalSpacing: Single read FVerticalSpacing write SetVerticalSpacing stored IsVerticalSpacingStored nodefault;
    property HorizontalSpacing: Single read FHorizontalSpacing write SetHorizontalSpacing stored IsHorizontalSpacingStored nodefault;
    property SeparatorStroke: TTMSFNCGraphicsStroke read FSeparatorStroke write SetSeparatorStroke;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
  end;

  TTMSFNCCustomSelectorPositionItem = record
    TileSet: Boolean;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCCustomSelectorPositionItem) b : Boolean;
    {$ENDIF}
  end;

  TTMSFNCCustomSelectorDisplayItem = record
    Rect: TRectF;
    Item: TTMSFNCCustomSelectorItem;
    PageIndex: Integer;
    Column, Row, ColumnSpan, RowSpan: Integer;
    {$IFDEF LCLLIB}
    class operator = (z1, z2 : TTMSFNCCustomSelectorDisplayItem) b : Boolean;
    {$ENDIF}
  end;

  TTMSFNCCustomSelectorItemPosArray = array of array of TTMSFNCCustomSelectorPositionItem;

  TTMSFNCCustomSelectorItemSelected = procedure(Sender: TObject; AItemIndex: Integer) of object;
  TTMSFNCCustomSelectorItemDeselected = procedure(Sender: TObject; AItemIndex: Integer) of object;
  TTMSFNCCustomSelectorItemClick = procedure(Sender: TObject; AItemIndex: Integer) of object;
  TTMSFNCCustomSelectorItemBeforeDrawBackground = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomSelectorItemAfterDrawBackground = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer) of object;
  TTMSFNCCustomSelectorItemBeforeDrawContent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomSelectorItemAfterDrawContent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer) of object;
  TTMSFNCCustomSelectorItemBeforeDrawText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var AText: String; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomSelectorItemAfterDrawText = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; AText: String) of object;
  TTMSFNCCustomSelectorBeforeDraw = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomSelectorAfterDraw = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;

  {$IFDEF WEBLIB}
  TTMSFNCCustomSelectorDisplayList = class(TList)
  private
    function GetItem(Index: Integer): TTMSFNCCustomSelectorDisplayItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCCustomSelectorDisplayItem);
  public
    property Items[Index: Integer]: TTMSFNCCustomSelectorDisplayItem read GetItem write SetItem; default;
  end;
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCCustomSelectorDisplayList = class(TList<TTMSFNCCustomSelectorDisplayItem>);
  {$ENDIF}

  TTMSFNCCustomSelector = class(TTMSFNCCustomControl)
  private
    FDisplayList: TTMSFNCCustomSelectorDisplayList;
    FUpdateCount: Integer;
    FItems: TTMSFNCCustomSelectorItems;
    FRows: Integer;
    FColumns: Integer;
    FPageCount: Integer;
    FAppearance: TTMSFNCCustomSelectorAppearance;
    FSelectedItemIndex, FFocusedItemIndex, FHoveredItemIndex, FDownItemIndex: Integer;
    FOnItemBeforeDrawText: TTMSFNCCustomSelectorItemBeforeDrawText;
    FOnAfterDraw: TTMSFNCCustomSelectorAfterDraw;
    FOnItemAfterDrawBackground: TTMSFNCCustomSelectorItemAfterDrawBackground;
    FOnItemSelected: TTMSFNCCustomSelectorItemSelected;
    FOnItemAfterDrawText: TTMSFNCCustomSelectorItemAfterDrawText;
    FOnBeforeDraw: TTMSFNCCustomSelectorBeforeDraw;
    FOnItemBeforeDrawBackground: TTMSFNCCustomSelectorItemBeforeDrawBackground;
    FOnItemDeselected: TTMSFNCCustomSelectorItemDeselected;
    FOnItemClick: TTMSFNCCustomSelectorItemClick;
    FOnItemBeforeDrawContent: TTMSFNCCustomSelectorItemBeforeDrawContent;
    FOnItemAfterDrawContent: TTMSFNCCustomSelectorItemAfterDrawContent;
    FBlockChange: Boolean;
    FClosedRemotely: Boolean;
    procedure SetItems(const Value: TTMSFNCCustomSelectorItems);
    procedure SetColumns(const Value: Integer);
    procedure SetRows(const Value: Integer);
    procedure SetAppearance(const Value: TTMSFNCCustomSelectorAppearance);
    procedure SetSelectedItemIndex(const Value: Integer);
  protected
    procedure RegisterRuntimeClasses; override;
    function GetHintString: string; override;
    function HasHint: Boolean; override;
    function GetVersion: String; override;
    function GetDisplayItem(AItemIndex: Integer): TTMSFNCCustomSelectorDisplayItem; virtual;
    function GetNextSelectableItem: Integer; virtual;
    function GetPreviousSelectableItem: Integer; virtual;
    function GetNextSelectableRowItem: Integer; virtual;
    function GetPreviousSelectableRowItem: Integer; virtual;
    function GetFirstSelectableItem: Integer; virtual;
    function GetLastSelectableItem: Integer; virtual;
    function CreateItemsCollection: TTMSFNCCustomSelectorItems; virtual;
    procedure CalculateItems; virtual;
    procedure UpdateCalculations; virtual;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    function GetTopOffset: Single; virtual;
    function GetCalculationWidth: Single; virtual;
    function GetCalculationHeight: Single; virtual;
    function GetTotalSeparatorHeight: Single;
    function GetTotalSeparatorCount: Integer;
    procedure DoItemSelected(AItemIndex: Integer); virtual;
    procedure DoItemClick(AItemIndex: Integer); virtual;
    procedure DoItemDeselected(AItemIndex: Integer); virtual;
    procedure DoItemBeforeDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var ADefaultDraw: Boolean); virtual;
    procedure DoItemAfterDrawBackground(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer); virtual;
    procedure DoItemBeforeDrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var ADefaultDraw: Boolean); virtual;
    procedure DoItemAfterDrawContent(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer); virtual;
    procedure DoItemBeforeDrawText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; var AText: String; var ADefaultDraw: Boolean); virtual;
    procedure DoItemAfterDrawText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AItemIndex: Integer; AText: String); virtual;
    procedure DoBeforeDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF; var ADefaultDraw: Boolean); reintroduce; virtual;
    procedure DoAfterDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF); reintroduce; virtual;
    procedure DrawItems(AGraphics: TTMSFNCGraphics); virtual;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;

    procedure DrawItem(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); virtual;
    procedure DrawItemBackGround(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); virtual;
    procedure DrawItemContent(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); virtual;
    procedure DrawItemText(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); virtual;

    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X: Single; Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X: Single; Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X: Single; Y: Single); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure HandleKeyUp(var Key: Word; Shift: TShiftState); override;
    procedure HandleMouseLeave; override;
    procedure ProcessSelection(AItemIndex: Integer);
    property SelectedItemIndex: Integer read FSelectedItemIndex write SetSelectedItemIndex default -1;
    property Rows: Integer read FRows write SetRows default 4;
    property Columns: Integer read FColumns write SetColumns default 4;
    property Version: String read GetVersion;
    property Items: TTMSFNCCustomSelectorItems read FItems write SetItems;
    property Appearance: TTMSFNCCustomSelectorAppearance read FAppearance write SetAppearance;
    property OnItemSelected: TTMSFNCCustomSelectorItemSelected read FOnItemSelected write FOnItemSelected;
    property OnItemDeselected: TTMSFNCCustomSelectorItemDeselected read FOnItemDeselected write FOnItemDeselected;
    property OnItemClick: TTMSFNCCustomSelectorItemClick read FOnItemClick write FOnItemClick;
    property OnItemBeforeDrawBackground: TTMSFNCCustomSelectorItemBeforeDrawBackground read FOnItemBeforeDrawBackground write FOnItemBeforeDrawBackground;
    property OnItemAfterDrawBackground: TTMSFNCCustomSelectorItemAfterDrawBackground read FOnItemAfterDrawBackground write FOnItemAfterDrawBackground;
    property OnItemBeforeDrawContent: TTMSFNCCustomSelectorItemBeforeDrawContent read FOnItemBeforeDrawContent write FOnItemBeforeDrawContent;
    property OnItemAfterDrawContent: TTMSFNCCustomSelectorItemAfterDrawContent read FOnItemAfterDrawContent write FOnItemAfterDrawContent;
    property OnBeforeDraw: TTMSFNCCustomSelectorBeforeDraw read FOnBeforeDraw write FOnBeforeDraw;
    property OnAfterDraw: TTMSFNCCustomSelectorAfterDraw read FOnAfterDraw write FOnAfterDraw;
    property OnItemBeforeDrawText: TTMSFNCCustomSelectorItemBeforeDrawText read FOnItemBeforeDrawText write FOnItemBeforeDrawText;
    property OnItemAfterDrawText: TTMSFNCCustomSelectorItemAfterDrawText read FOnItemAfterDrawText write FOnItemAfterDrawText;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure InvalidateItems;
    property BlockChange: Boolean read FBlockChange write FBlockChange;
    procedure UpdateControlAfterResize; override;
    procedure InitializeDefault; virtual;
    function XYToItem(X, Y: Single): Integer;
    property ClosedRemotely: Boolean read FClosedRemotely write FClosedRemotely;
  end;

  TTMSFNCDefaultSelector = class(TTMSFNCCustomSelector)
  published
    property Fill;
    property Stroke;
    property Version;
  end;

implementation

uses
  Math, WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles;

{ TTMSFNCCustomSelector }

procedure TTMSFNCCustomSelector.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;

  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
    Fill.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundStrokeColor(c) then
    Stroke.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleDefaultButtonFillColor(c) then
    Appearance.Fill.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    Appearance.FillSelected.Color := c;
    Appearance.FillDown.Color := c;
    Appearance.FillHover.Color := Blend(c, Appearance.Fill.Color, 25);
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleDefaultButtonStrokeColor(c) then
  begin
    Appearance.Stroke.Color := c;
    Appearance.StrokeSelected.Color := c;
    Appearance.StrokeDown.Color := c;
    Appearance.StrokeHover.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
    Appearance.Font.Color := c;
end;

procedure TTMSFNCCustomSelector.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCCustomSelector then
  begin
    FItems.Assign((Source as TTMSFNCCustomSelector).Items);
    FAppearance.Assign((Source as TTMSFNCCustomSelector).Appearance);
    FRows := (Source as TTMSFNCCustomSelector).Rows;
    FColumns := (Source as TTMSFNCCustomSelector).Columns;
  end;
end;

procedure TTMSFNCCustomSelector.BeginUpdate;
begin
  inherited;
  Inc(FUpdateCount);
end;

procedure TTMSFNCCustomSelector.CalculateItems;
var
  itposarr: TTMSFNCCustomSelectorItemPosArray;
  r, newr, c, newc: Integer;
  AItemIndex: Integer;
  AItem: TTMSFNCCustomSelectorItem;
  cspan, rspan, newcspan, newrspan: Integer;
  I: Integer;
  K: Integer;
  sepc: Integer;
  f: Boolean;
  APageIndex: Integer;
  hs, vs: Single;
  iw, ih: Single;
  w, h: Single;
  exw, exh: Single;
  tx, ty: Single;
  pw, ph: Single;
  offs: Single;
  itd: TTMSFNCCustomSelectorDisplayItem;

  procedure FindNewPos(AItem: TTMSFNCCustomSelectorItem; var ANewR: Integer; var ANewC: Integer; var AFound: Boolean; ARows , {%H-}ACurRow, AColumns, {%H-}ACurCol: Integer; PosArr: TTMSFNCCustomSelectorItemPosArray);
  var
    i, k: integer;
    cspan, rspan: Integer;
    J, L: Integer;
  begin
    AFound := False;
    for I := ANewr to ARows - 1 do
    begin
      for K := ANewC to AColumns - 1 do
      begin
        cspan := AItem.ColumnSpan;
        cspan := Min(cspan, Columns - ANewC);
        rspan := AItem.RowSpan;
        rspan := Min(rspan, Rows - ANewR);

        AFound := true;
        for J := 0 to rspan - 1 do
        begin
          for L := 0 to cspan - 1 do
          begin
            if PosArr[I + J, K + L].TileSet then
              AFound := False;
          end;
        end;

        if AFound then
          Break;
        Inc(ANewC);
      end;
      if AFound then
        Break;

      ANewC := 0;
      Inc(ANewr);
    end;
  end;
begin
  if (csDestroying in ComponentState) or (FUpdateCount > 0) then
    Exit;

  FDisplayList.Clear;

  if Items.Count = 0 then
    Exit;

  pw := GetCalculationWidth;
  ph := GetCalculationHeight;
  hs := Appearance.HorizontalSpacing;
  vs := Appearance.VerticalSpacing;
  w := pw - vs;
  iw := w;
  if Columns > 0 then
    iw := (w - (Columns * hs)) / Columns;

  h := ph - vs - GetTotalSeparatorHeight;
  sepc := GetTotalSeparatorCount;
  ih := h;
  if (Rows - sepc) > 0 then
    ih := (h - ((Rows - sepc) * vs)) / (Rows - sepc);

  AItemIndex := 0;
  APageIndex := 0;
  offs := 0;
  while AItemIndex <= Items.Count - 1 do
  begin
    c := 0;
    r := 0;
    SetLength(itposarr, 0, 0);
    SetLength(itposarr, Rows, Columns);

    while r < Rows do
    begin
      while (c < Columns) do
      begin
        if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) and (APageIndex = 0) then
        begin
          AItem := Items[AItemIndex];
          if not AItem.Visible then
          begin
            Inc(AItemIndex);
            Continue;
          end;

          if AItem.Separator then
          begin
            cspan := Columns;
            rspan := 1;
          end
          else
          begin
            cspan := AItem.ColumnSpan;
            rspan := AItem.RowSpan;
          end;

          cspan := Min(cspan, Columns - c);
          rspan := Min(rspan, Rows - r);

          exw := iw * cspan + (hs * (cspan - 1));
          if AItem.Separator then
            exh := AItem.SeparatorHeight
          else
            exh := ih * rspan + (vs * (rspan - 1));

          tx := (pw * APageIndex) + hs + iw * c + (hs * c);
          ty := offs + vs + ih * r + (vs * r) + GetTopOffset;

          itd.Rect := RectF(tx + AItem.Margins.Left, ty + AItem.Margins.Top,
            tx + exw - AItem.Margins.Right, ty + exh - AItem.Margins.Bottom);
          itd.Item := AItem;
          itd.PageIndex := APageIndex;
          itd.Column := c;
          itd.Row := r;
          itd.ColumnSpan := cspan;
          itd.RowSpan := rspan;
          FDisplayList.Add(itd);

          newcspan := c;
          newrspan := r;
          newcspan := newcspan + cspan - 1;
          newrspan := newrspan + rspan - 1;

          for I := r to newrspan do
            for K := c to newcspan do
              itposarr[I, K].TileSet := True;

          if AItem.Separator then
            offs := offs + AItem.SeparatorHeight - ih * rspan + (vs * (rspan - 1));
        end;
        Inc(AItemIndex);
        Inc(c);
        newc := c;
        newr := r;
        f := False;
        if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
          FindNewPos(Items[AItemIndex], newr, newc, f, Rows, newr, Columns, newc, itposarr);
        c := newc;
        r := newr;

        if (c >= Columns) or (r >= Rows) then
          Break;
      end;
      c := 0;
      Inc(r);
      newc := c;
      newr := r;
      f := False;
      if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
        FindNewPos(Items[AItemIndex], newr, newc, f, Rows, newr, Columns, newc, itposarr);
      c := newc;
      r := newr;
      if r >= Rows then
        Break;
    end;
    Inc(APageIndex);
  end;

  FPageCount := APageIndex;
  InvalidateItems;
end;

constructor TTMSFNCCustomSelector.Create(AOwner: TComponent);
begin
  inherited;
  FColumns := 4;
  FRows := 4;
  FItems := CreateItemsCollection;
  FDisplayList := TTMSFNCCustomSelectorDisplayList.Create;
  FAppearance := TTMSFNCCustomSelectorAppearance.Create(Self);
  FSelectedItemIndex := -1;
  FFocusedItemIndex := -1;
  FHoveredItemIndex := -1;
  FDownItemIndex := -1;

  if IsDesignTime then
    InitializeDefault;
end;

procedure TTMSFNCCustomSelector.InitializeDefault;
begin

end;

function TTMSFNCCustomSelector.CreateItemsCollection: TTMSFNCCustomSelectorItems;
begin
  Result := TTMSFNCCustomSelectorItems.Create(Self);
end;

destructor TTMSFNCCustomSelector.Destroy;
begin
  FAppearance.Free;
  FDisplayList.Free;
  FItems.Free;
  inherited;
end;

procedure TTMSFNCCustomSelector.DoAfterDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
begin
  if Assigned(OnAfterDraw) then
    OnAfterDraw(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomSelector.DoBeforeDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDraw) then
    OnBeforeDraw(Self, AGraphics, ARect, ADefaultDraw);
end;

procedure TTMSFNCCustomSelector.DoItemAfterDrawBackground(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer);
begin
  if Assigned(OnItemAfterDrawBackground) then
    OnItemAfterDrawBackground(Self, AGraphics, ARect, AItemIndex);
end;

procedure TTMSFNCCustomSelector.DoItemAfterDrawContent(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer);
begin
  if Assigned(OnItemAfterDrawContent) then
    OnItemAfterDrawContent(Self, AGraphics, ARect, AItemIndex);
end;

procedure TTMSFNCCustomSelector.DoItemAfterDrawText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer; AText: String);
begin
  if Assigned(OnItemAfterDrawText) then
    OnItemAfterDrawText(Self, AGraphics, ARect, AItemIndex, AText);
end;

procedure TTMSFNCCustomSelector.DoItemBeforeDrawBackground(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer; var ADefaultDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawBackground) then
    OnItemBeforeDrawBackground(Self, AGraphics, ARect, AItemIndex, ADefaultDraw);
end;

procedure TTMSFNCCustomSelector.DoItemBeforeDrawContent(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer; var ADefaultDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawContent) then
    OnItemBeforeDrawContent(Self, AGraphics, ARect, AItemIndex, ADefaultDraw);
end;

procedure TTMSFNCCustomSelector.DoItemBeforeDrawText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AItemIndex: Integer; var AText: String;
  var ADefaultDraw: Boolean);
begin
  if Assigned(OnItemBeforeDrawText) then
    OnItemBeforeDrawText(Self, AGraphics, ARect, AItemIndex, AText, ADefaultDraw);
end;

procedure TTMSFNCCustomSelector.DoItemSelected(AItemIndex: Integer);
begin
  if Assigned(OnItemSelected) then
    OnItemSelected(Self, AItemIndex);
end;

procedure TTMSFNCCustomSelector.DoItemClick(AItemIndex: Integer);
begin
  if Assigned(OnItemClick) then
    OnItemClick(Self, AItemIndex);
end;

procedure TTMSFNCCustomSelector.DoItemDeselected(AItemIndex: Integer);
begin
  if Assigned(OnItemDeselected) then
    OnItemDeselected(Self, AItemIndex);
end;

procedure TTMSFNCCustomSelector.HandleMouseLeave;
begin
  inherited;
  FHoveredItemIndex := -1;
  InvalidateItems;
end;

procedure TTMSFNCCustomSelector.DrawItem(AGraphics: TTMSFNCGraphics;
  ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
begin
  DrawItemBackGround(AGraphics, ADisplayItem);
  DrawItemContent(AGraphics, ADisplayItem);
  DrawItemText(AGraphics, ADisplayItem);
end;

procedure TTMSFNCCustomSelector.DrawItemBackGround(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  r: TRectF;
  it: TTMSFNCCustomSelectorItem;
  a: Boolean;
  fr: TRectF;
begin
  it := ADisplayItem.Item;
  if Assigned(it) then
  begin
    if not it.Separator then
    begin
      AGraphics.Fill.Assign(Appearance.Fill);
      AGraphics.Stroke.Assign(Appearance.Stroke);
      if it.Enabled then
      begin
        if it.Index = FDownItemIndex then
        begin
          AGraphics.Fill.Assign(Appearance.FillDown);
          AGraphics.Stroke.Assign(Appearance.StrokeDown);
        end
        else if it.Index = FHoveredItemIndex then
        begin
          AGraphics.Fill.Assign(Appearance.FillHover);
          AGraphics.Stroke.Assign(Appearance.StrokeHover);
        end
        else if it.Index = FSelectedItemIndex then
        begin
          AGraphics.Fill.Assign(Appearance.FillSelected);
          AGraphics.Stroke.Assign(Appearance.StrokeSelected);
        end;
      end
      else
      begin
        AGraphics.Fill.Assign(Appearance.FillDisabled);
        AGraphics.Stroke.Assign(Appearance.StrokeDisabled);
      end;
    end
    else
      AGraphics.Stroke.Assign(Appearance.SeparatorStroke);

    r := ADisplayItem.Rect;
    a := True;
    DoItemBeforeDrawBackground(AGraphics, ADisplayItem.Rect, it.Index, a);
    if a then
    begin
      if it.Separator then
        AGraphics.DrawLine(PointF(r.Left, CenterPointEx(r).Y), PointF(r.Right, CenterPointEx(r).Y))
      else
      begin
        AGraphics.DrawRectangle(r);
        if IsFocused and (FFocusedItemIndex = it.Index) then
        begin
          fr := r;
          InflateRectEx(fr, -2, -2);
          AGraphics.DrawFocusRectangle(fr);
        end;
      end;

      DoItemAfterDrawBackground(AGraphics, ADisplayItem.Rect, it.Index);
    end;
  end;
end;

procedure TTMSFNCCustomSelector.DrawItemContent(AGraphics: TTMSFNCGraphics;
  ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  it: TTMSFNCCustomSelectorItem;
  a: Boolean;
begin
  it := ADisplayItem.Item;
  if Assigned(it) then
  begin
    a := True;
    DoItemBeforeDrawContent(AGraphics, ADisplayItem.Rect, it.Index, a);
    if a then
      DoItemAfterDrawContent(AGraphics, ADisplayItem.Rect, it.Index);
  end;
end;

procedure TTMSFNCCustomSelector.DrawItems(AGraphics: TTMSFNCGraphics);
var
  I: Integer;
begin
  for I := 0 to FDisplayList.Count - 1 do
    DrawItem(AGraphics, FDisplayList[I]);
end;

procedure TTMSFNCCustomSelector.DrawItemText(AGraphics: TTMSFNCGraphics;
  ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  r: TRectF;
  it: TTMSFNCCustomSelectorItem;
  str: String;
  a: Boolean;
begin
  it := ADisplayItem.Item;
  if Assigned(it) and (it.Text <> '') then
  begin
    r := ADisplayItem.Rect;
    str := it.Text;
    a := True;
    InflateRectEx(r, -2, -2);
    AGraphics.Font.AssignSource(Appearance.Font);
    DoItemBeforeDrawText(AGraphics, ADisplayItem.Rect, it.Index, str, a);
    if a then
    begin
      AGraphics.DrawText(r, str, False, it.HorizontalTextAlign, it.VerticalTextAlign);
      DoItemAfterDrawText(AGraphics, ADisplayItem.Rect, it.Index, str);
    end;
  end;
end;

procedure TTMSFNCCustomSelector.EndUpdate;
begin
  inherited;
  Dec(FUpdateCount);
  if FUpdateCount = 0 then
    CalculateItems;
end;

function TTMSFNCCustomSelector.GetFirstSelectableItem: Integer;
var
  I: Integer;
  it: TTMSFNCCustomSelectorItem;
begin
  Result := FFocusedItemIndex;
  for I := 0 to FDisplayList.Count - 1 do
  begin
    it := FDisplayList[I].Item;
    if Assigned(it) and it.Enabled and not it.Separator then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomSelector.GetCalculationHeight: Single;
begin
  Result := Height - GetTopOffset;
end;

function TTMSFNCCustomSelector.GetHintString: string;
var
  it: TTMSFNCCustomSelectorItem;
begin
  Result := inherited GetHintString;
  if (FHoveredItemIndex >= 0) and (FHoveredItemIndex <= FItems.Count - 1) then
  begin
    it := FItems[FHoveredItemIndex];
    Result := it.Hint;
  end;
end;

function TTMSFNCCustomSelector.GetLastSelectableItem: Integer;
var
  I: Integer;
  it: TTMSFNCCustomSelectorItem;
begin
  Result := FFocusedItemIndex;
  for I := FDisplayList.Count - 1 downto 0 do
  begin
    it := FDisplayList[I].Item;
    if Assigned(it) and it.Enabled and not it.Separator then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomSelector.GetNextSelectableItem: Integer;
var
  I: Integer;
  it: TTMSFNCCustomSelectorItem;
begin
  Result := FFocusedItemIndex;
  for I := 0 to FDisplayList.Count - 1 do
  begin
    it := FDisplayList[I].Item;
    if Assigned(it) and it.Enabled and not it.Separator and (it.Index > FFocusedItemIndex) then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomSelector.GetNextSelectableRowItem: Integer;
var
  I: Integer;
  it: TTMSFNCCustomSelectorItem;
  disp: TTMSFNCCustomSelectorDisplayItem;
begin
  Result := FFocusedItemIndex;
  if Result = -1 then
  begin
    Result := GetNextSelectableItem;
    Exit;
  end;

  disp := GetDisplayItem(FFocusedItemIndex);
  for I := 0 to FDisplayList.Count - 1 do
  begin
    it := FDisplayList[I].Item;
    if Assigned(it) and it.Enabled and not it.Separator and (it.Index > FFocusedItemIndex) and (disp.Column >= FDisplayList[I].Column) and
      (disp.Column <= FDisplayList[I].Column + (FDisplayList[I].ColumnSpan - 1))
      and (FDisplayList[I].Row > disp.Row) then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomSelector.GetPreviousSelectableItem: Integer;
var
  I: Integer;
  it: TTMSFNCCustomSelectorItem;
begin
  Result := FFocusedItemIndex;
  for I := FDisplayList.Count - 1 downto 0 do
  begin
    it := FDisplayList[I].Item;
    if Assigned(it) and it.Enabled and not it.Separator and (it.Index < FFocusedItemIndex) then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomSelector.GetPreviousSelectableRowItem: Integer;
var
  I: Integer;
  it: TTMSFNCCustomSelectorItem;
  disp: TTMSFNCCustomSelectorDisplayItem;
begin
  Result := FFocusedItemIndex;
  if Result = -1 then
  begin
    Result := GetPreviousSelectableItem;
    Exit;
  end;

  disp := GetDisplayItem(FFocusedItemIndex);
  for I := FDisplayList.Count - 1 downto 0 do
  begin
    it := FDisplayList[I].Item;
    if Assigned(it) and it.Enabled and not (it.Separator) and (it.Index < FFocusedItemIndex) and (disp.Column >= FDisplayList[I].Column) and
      (disp.Column <= FDisplayList[I].Column + (FDisplayList[I].ColumnSpan - 1)) and (FDisplayList[I].Row < disp.Row) then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomSelector.GetTopOffset: Single;
begin
  Result := 0;
end;

function TTMSFNCCustomSelector.GetTotalSeparatorCount: Integer;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to Items.Count - 1 do
  begin
    if Items[I].Separator then
      Inc(Result);
  end;
end;

function TTMSFNCCustomSelector.GetTotalSeparatorHeight: Single;
var
  I: Integer;
begin
  Result := 0;
  for I := 0 to Items.Count - 1 do
  begin
    if Items[I].Separator then
      Result := Result + Items[I].SeparatorHeight + Appearance.VerticalSpacing;
  end;
end;

function TTMSFNCCustomSelector.GetVersion: String;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomSelector.GetCalculationWidth: Single;
begin
  Result := Width;
end;

procedure TTMSFNCCustomSelector.HandleKeyDown(var Key: Word; Shift: TShiftState);
begin
  inherited;
  case Key of
    KEY_UP:
    begin
      FFocusedItemIndex := GetPreviousSelectableRowItem;
      InvalidateItems;
    end;
    KEY_LEFT:
    begin
      FFocusedItemIndex := GetPreviousSelectableItem;
      InvalidateItems;
    end;
    KEY_DOWN:
    begin
      FFocusedItemIndex := GetNextSelectableRowItem;
      InvalidateItems;
    end;
    KEY_RIGHT:
    begin
      FFocusedItemIndex := GetNextSelectableItem;
      InvalidateItems;
    end;
    KEY_HOME:
    begin
      FFocusedItemIndex := GetFirstSelectableItem;
      InvalidateItems;
    end;
    KEY_END:
    begin
      FFocusedItemIndex := GetLastSelectableItem;
      InvalidateItems;
    end;
  end;

  if (Key = KEY_RETURN) then
  begin
    FDownItemIndex := FFocusedItemIndex;
    InvalidateItems;
  end;
end;

procedure TTMSFNCCustomSelector.HandleKeyUp(var Key: Word; Shift: TShiftState);
begin
  inherited;
  if (Key = KEY_RETURN) then
  begin
    FDownItemIndex := -1;
    ProcessSelection(FFocusedItemIndex);
    InvalidateItems;
  end;
end;

procedure TTMSFNCCustomSelector.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  CaptureEx;
  FDownItemIndex := XYToItem(X, Y);
  InvalidateItems;
end;

procedure TTMSFNCCustomSelector.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  h: Integer;
begin
  inherited;
  if FDownItemIndex > -1 then
    Exit;

  h := XYToItem(X, Y);
  if h <> FHoveredItemIndex then
  begin
    FHoveredItemIndex := h;
    CancelHint;
    InvalidateItems;
  end;
end;

procedure TTMSFNCCustomSelector.HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState;
  X, Y: Single);
var
  s: Integer;
begin
  inherited;
  ReleaseCaptureEx;
  s := XYToItem(X, Y);
  if (s = FDownItemIndex) and (FDownItemIndex <> -1) then
  begin
    ProcessSelection(s);
    if s <> -1 then
      DoItemClick(s);
  end;
  FDownItemIndex := -1;
  FHoveredItemIndex := -1;
  InvalidateItems;
end;

function TTMSFNCCustomSelector.HasHint: Boolean;
var
  it: TTMSFNCCustomSelectorItem;
begin
  Result := False;
  if (FHoveredItemIndex >= 0) and (FHoveredItemIndex <= FItems.Count - 1) then
  begin
    it := FItems[FHoveredItemIndex];
    Result := it.Hint <> '';
  end;
end;

procedure TTMSFNCCustomSelector.Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF);
var
  a: Boolean;
begin
  inherited;
  a := True;
  DoBeforeDraw(AGraphics, ARect, a);
  if a then
  begin
    DrawItems(AGraphics);
    DoAfterDraw(AGraphics, ARect);
  end;
end;

procedure TTMSFNCCustomSelector.ProcessSelection(AItemIndex: Integer);
var
  it: TTMSFNCCustomSelectorItem;
  prev: Integer;
begin
  if (AItemIndex >= 0) and (AItemIndex <= Items.Count - 1) then
  begin
    it := FItems[AItemIndex];
    if it.CanSelect then
    begin
      prev := FSelectedItemIndex;
      if it.CanDeselect and (it.Index = FSelectedItemIndex) then
        FSelectedItemIndex := -1
      else
        FSelectedItemIndex := it.Index;

      if FSelectedItemIndex <> -1 then
        FFocusedItemIndex := FSelectedItemIndex;

      if it.CanDeselect and (prev <> -1) then
        DoItemDeSelected(prev);

      if FSelectedItemIndex <> -1 then
        DoItemSelected(FSelectedItemIndex);
    end;
  end;
end;

procedure TTMSFNCCustomSelector.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClass(TTMSFNCCustomSelector);
end;

procedure TTMSFNCCustomSelector.InvalidateItems;
begin
  Invalidate;
end;

procedure TTMSFNCCustomSelector.ResetToDefaultStyle;
begin
  inherited;
  Fill.Kind := gfkSolid;
  Stroke.Kind := gskSolid;
  Fill.Color := gcWhite;
  Stroke.Color := gcSilver;

  Appearance.Fill.Color := Lighter(gcLightgray, 50);
  Appearance.FillHover.Color := Lighter(gcLightslategray, 50);
  Appearance.FillDown.Color := Lighter(gcSlategray, 50);
  Appearance.FillSelected.Color := Lighter(gcSlategray, 50);
  Appearance.FillDisabled.Color := Lighter(gcGray, 50);

  Appearance.Stroke.Color := gcDarkgray;
  Appearance.StrokeHover.Color := gcLightslategray;
  Appearance.StrokeDown.Color := gcSlategray;
  Appearance.StrokeSelected.Color := gcDarkslategray;
  Appearance.StrokeDisabled.Color := gcDarkgray;

  Appearance.Font.Color := gcBlack;

  Appearance.Fill.Kind := gfkSolid;
  Appearance.FillHover.Kind := gfkSolid;
  Appearance.FillDown.Kind := gfkSolid;
  Appearance.FillSelected.Kind := gfkSolid;
  Appearance.FillDisabled.Kind := gfkSolid;

  Appearance.Stroke.Kind := gskSolid;
  Appearance.StrokeHover.Kind := gskSolid;
  Appearance.StrokeDown.Kind := gskSolid;
  Appearance.StrokeSelected.Kind := gskSolid;
  Appearance.StrokeDisabled.Kind := gskSolid;

  Appearance.SeparatorStroke.Kind := gskSolid;
end;

procedure TTMSFNCCustomSelector.UpdateCalculations;
begin

end;

procedure TTMSFNCCustomSelector.UpdateControlAfterResize;
begin
  inherited;
  CalculateItems;
end;

function TTMSFNCCustomSelector.GetDisplayItem(AItemIndex: Integer): TTMSFNCCustomSelectorDisplayItem;
var
  I: Integer;
  it: TTMSFNCCustomSelectorItem;
begin
  Result.Rect := RectF(0, 0, 0, 0);
  Result.Item := nil;
  Result.PageIndex := -1;
  Result.Row := -1;
  Result.Column := -1;
  Result.ColumnSpan := -1;
  Result.RowSpan := -1;
  for I := 0 to FDisplayList.Count - 1 do
  begin
    it := FDisplayList[I].Item;
    if Assigned(it) and (it.Index = AItemIndex) then
    begin
      Result := FDisplayList[I];
      Break;
    end;
  end;
end;

procedure TTMSFNCCustomSelector.SetAppearance(
  const Value: TTMSFNCCustomSelectorAppearance);
begin
  FAppearance.Assign(Value);
end;

procedure TTMSFNCCustomSelector.SetColumns(const Value: Integer);
begin
  if FColumns <> Value then
  begin
    FColumns := Value;
    UpdateCalculations;
    CalculateItems;
  end;
end;

procedure TTMSFNCCustomSelector.SetItems(const Value: TTMSFNCCustomSelectorItems);
begin
  FItems.Assign(Value);
end;

procedure TTMSFNCCustomSelector.SetRows(const Value: Integer);
begin
  if FRows <> Value then
  begin
    FRows := Value;
    UpdateCalculations;
    CalculateItems;
  end;
end;

procedure TTMSFNCCustomSelector.SetSelectedItemIndex(const Value: Integer);
begin
  if FSelectedItemIndex <> Value then
  begin
    FSelectedItemIndex := Value;
    FFocusedItemIndex := Value;
    InvalidateItems;
  end;
end;

function TTMSFNCCustomSelector.XYToItem(X, Y: Single): Integer;
var
  I: Integer;
  it: TTMSFNCCustomSelectorItem;
begin
  Result := -1;
  for I := 0 to FDisplayList.Count - 1 do
  begin
    if PtInRectEx(FDisplayList[I].Rect, PointF(X, Y)) then
    begin
      it := FDisplayList[I].Item;
      if Assigned(it) and it.Enabled and not it.Separator then
      begin
        Result := it.Index;
        Break;
      end;
    end;
  end;
end;

{ TTMSFNCCustomSelectorItem }

procedure TTMSFNCCustomSelectorItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCustomSelectorItem then
  begin
    FRowSpan := (Source as TTMSFNCCustomSelectorItem).RowSpan;
    FColumnSpan := (Source as TTMSFNCCustomSelectorItem).ColumnSpan;
    FVisible := (Source as TTMSFNCCustomSelectorItem).Visible;
    FText := (Source as TTMSFNCCustomSelectorItem).Text;
    FEnabled := (Source as TTMSFNCCustomSelectorItem).Enabled;
    FSeparator := (Source as TTMSFNCCustomSelectorItem).Separator;
    FSeparatorHeight := (Source as TTMSFNCCustomSelectorItem).SeparatorHeight;
    FMargins.Assign((Source as TTMSFNCCustomSelectorItem).Margins);
    FCanDeselect := (Source as TTMSFNCCustomSelectorItem).CanDeselect;
    FCanSelect := (Source as TTMSFNCCustomSelectorItem).CanSelect;
    FHint := (Source as TTMSFNCCustomSelectorItem).Hint;
  end;
end;

constructor TTMSFNCCustomSelectorItem.Create(ACollection: TCollection);
begin
  inherited;
  if Assigned(Collection) then
    FOwner := (Collection as TTMSFNCCustomSelectorItems).FOwner;
  FSeparator := False;
  FColumnSpan := 1;
  FCanDeselect := True;
  FCanSelect := True;
  FRowSpan := 1;
  FMargins := TTMSFNCMargins.Create;
  FMargins.OnChange := @MarginsChanged;
  FEnabled := True;
  FSeparatorHeight := 5;
  FVisible := True;
  if Assigned(FOwner) then
    FOwner.CalculateItems;
end;

destructor TTMSFNCCustomSelectorItem.Destroy;
begin
  FMargins.Free;
  inherited;
  if Assigned(FOwner) then
    FOwner.CalculateItems;
end;

function TTMSFNCCustomSelectorItem.IsSeparatorHeightStored: Boolean;
begin
  Result := SeparatorHeight <> 5;
end;

procedure TTMSFNCCustomSelectorItem.MarginsChanged(Sender: TObject);
begin
  FOwner.CalculateItems;
end;

procedure TTMSFNCCustomSelectorItem.SetCanDeselect(const Value: Boolean);
begin
  FCanDeselect := Value;
end;

procedure TTMSFNCCustomSelectorItem.SetCanSelect(const Value: Boolean);
begin
  FCanSelect := Value;
end;

procedure TTMSFNCCustomSelectorItem.SetColumnSpan(const Value: Integer);
begin
  if FColumnSpan <> Value then
  begin
    FColumnSpan := Value;
    FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomSelectorItem.SetEnabled(const Value: Boolean);
begin
  if FEnabled <> Value then
  begin
    FEnabled := Value;
    FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCCustomSelectorItem.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCCustomSelectorItem.SetMargins(const Value: TTMSFNCMargins);
begin
  FMargins.Assign(Value);
end;

procedure TTMSFNCCustomSelectorItem.SetRowSpan(const Value: Integer);
begin
  if FRowSpan <> Value then
  begin
    FRowSpan := Value;
    FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomSelectorItem.SetSeparator(const Value: Boolean);
begin
  if FSeparator <> Value then
  begin
    FSeparator := Value;
    FOwner.CalculateItems;
  end;
end;

procedure TTMSFNCCustomSelectorItem.SetSeparatorHeight(const Value: Single);
begin
  if FSeparatorHeight <> Value then
  begin
    FSeparatorHeight := Value;
    FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCCustomSelectorItem.SetText(const Value: String);
begin
  if FText <> Value then
  begin
    FText := Value;
    FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCCustomSelectorItem.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    FOwner.InvalidateItems;
  end;
end;

procedure TTMSFNCCustomSelectorItem.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    FOwner.CalculateItems;
  end;
end;

function TTMSFNCCustomSelectorItem.State: TTMSFNCCustomSelectorItemState;
begin
  Result := isNormal;
  if not Separator then
  begin
    if Enabled then
    begin
      if Index = FOwner.FDownItemIndex then
        Result := isDown
      else if Index = FOwner.FHoveredItemIndex then
        Result := isHover
      else if Index = FOwner.FSelectedItemIndex then
        Result := isSelected
    end
    else
      Result := isDisabled;
  end;
end;

{ TTMSFNCCustomSelectorItems }

function TTMSFNCCustomSelectorItems.Add: TTMSFNCCustomSelectorItem;
begin
  Result := TTMSFNCCustomSelectorItem(inherited Add);
end;

constructor TTMSFNCCustomSelectorItems.Create(AOwner: TTMSFNCCustomSelector);
begin
  inherited Create(AOwner, CreateItemClass);
  FOwner := AOwner;
end;

function TTMSFNCCustomSelectorItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCCustomSelectorItem;
end;

function TTMSFNCCustomSelectorItems.GetItem(
  Index: Integer): TTMSFNCCustomSelectorItem;
begin
  Result := TTMSFNCCustomSelectorItem(inherited Items[Index]);
end;

function TTMSFNCCustomSelectorItems.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCCustomSelectorItems.Insert(
  Index: Integer): TTMSFNCCustomSelectorItem;
begin
  Result := TTMSFNCCustomSelectorItem(inherited Insert(Index));
end;

procedure TTMSFNCCustomSelectorItems.SetItem(Index: Integer;
  const Value: TTMSFNCCustomSelectorItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCCustomSelectorAppearance }

procedure TTMSFNCCustomSelectorAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCustomSelectorAppearance then
  begin
    FFill.Assign((Source as TTMSFNCCustomSelectorAppearance).Fill);
    FFillHover.Assign((Source as TTMSFNCCustomSelectorAppearance).FillHover);
    FFillSelected.Assign((Source as TTMSFNCCustomSelectorAppearance).FillSelected);
    FFillDisabled.Assign((Source as TTMSFNCCustomSelectorAppearance).FillDisabled);
    FFillDown.Assign((Source as TTMSFNCCustomSelectorAppearance).FillDown);
    FStroke.Assign((Source as TTMSFNCCustomSelectorAppearance).Stroke);
    FStrokeHover.Assign((Source as TTMSFNCCustomSelectorAppearance).StrokeHover);
    FStrokeSelected.Assign((Source as TTMSFNCCustomSelectorAppearance).StrokeSelected);
    FStrokeDown.Assign((Source as TTMSFNCCustomSelectorAppearance).StrokeDown);
    FStrokeDisabled.Assign((Source as TTMSFNCCustomSelectorAppearance).StrokeDisabled);
    FVerticalSpacing := (Source as TTMSFNCCustomSelectorAppearance).VerticalSpacing;
    FHorizontalSpacing := (Source as TTMSFNCCustomSelectorAppearance).HorizontalSpacing;
    FSeparatorStroke.Assign((Source as TTMSFNCCustomSelectorAppearance).SeparatorStroke);
    FFont.AssignSource((Source as TTMSFNCCustomSelectorAppearance).Font);
  end;
end;

procedure TTMSFNCCustomSelectorAppearance.Changed;
begin
  FOwner.CalculateItems;
end;

constructor TTMSFNCCustomSelectorAppearance.Create(AOwner: TTMSFNCCustomSelector);
begin
  FOwner := AOwner;
  FFill := TTMSFNCGraphicsFill.Create;
  FFill.Color := Lighter(gcLightgray, 50);
  FFillHover := TTMSFNCGraphicsFill.Create;
  FFillHover.Color := Lighter(gcLightslategray, 50);
  FFillDown := TTMSFNCGraphicsFill.Create;
  FFillDown.Color := Lighter(gcSlategray, 50);
  FFillSelected := TTMSFNCGraphicsFill.Create;
  FFillSelected.Color := Lighter(gcSlategray, 50);
  FFillDisabled := TTMSFNCGraphicsFill.Create;
  FFillDisabled.Color := Lighter(gcGray, 50);

  FStroke := TTMSFNCGraphicsStroke.Create;
  FStroke.Color := gcDarkgray;
  FStrokeHover := TTMSFNCGraphicsStroke.Create;
  FStrokeHover.Color := gcLightslategray;
  FStrokeDown := TTMSFNCGraphicsStroke.Create;
  FStrokeDown.Color := gcSlategray;
  FStrokeSelected := TTMSFNCGraphicsStroke.Create;
  FStrokeSelected.Color := gcDarkslategray;
  FStrokeDisabled := TTMSFNCGraphicsStroke.Create;
  FStrokeDisabled.Color := gcDarkgray;

  FSeparatorStroke := TTMSFNCGraphicsStroke.Create;
  FSeparatorStroke.Color := gcDarkGray;

  FFont := TTMSFNCGraphicsFont.Create;
  FFont.OnChanged := @FontChanged;

  FSeparatorStroke.OnChanged := @StrokeChanged;
  FFont.OnChanged := @FontChanged;

  FFill.OnChanged := @FillChanged;
  FFillDown.OnChanged := @FillChanged;
  FFillHover.OnChanged := @FillChanged;
  FFillSelected.OnChanged := @FillChanged;
  FFillDisabled.OnChanged := @FillChanged;

  FStroke.OnChanged := @StrokeChanged;
  FStrokeHover.OnChanged := @StrokeChanged;
  FStrokeDown.OnChanged := @StrokeChanged;
  FStrokeDisabled.OnChanged := @StrokeChanged;
  FStrokeSelected.OnChanged := @StrokeChanged;

  FHorizontalSpacing := 4;
  FVerticalSpacing := 4;
end;

destructor TTMSFNCCustomSelectorAppearance.Destroy;
begin
  FFont.Free;
  FFill.Free;
  FFillDown.Free;
  FFillSelected.Free;
  FFillHover.Free;
  FFillDisabled.Free;
  FSeparatorStroke.Free;
  FStroke.Free;
  FStrokeDown.Free;
  FStrokeSelected.Free;
  FStrokeHover.Free;
  FStrokeDisabled.Free;
  inherited;
end;

procedure TTMSFNCCustomSelectorAppearance.FillChanged(Sender: TObject);
begin
  FOwner.InvalidateItems;
end;

procedure TTMSFNCCustomSelectorAppearance.FontChanged(Sender: TObject);
begin
  FOwner.InvalidateItems;
end;

function TTMSFNCCustomSelectorAppearance.IsHorizontalSpacingStored: Boolean;
begin
  Result := HorizontalSpacing <> 4;
end;

function TTMSFNCCustomSelectorAppearance.IsVerticalSpacingStored: Boolean;
begin
  Result := VerticalSpacing <> 4;
end;

procedure TTMSFNCCustomSelectorAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  FFill.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetFillDisabled(const Value: TTMSFNCGraphicsFill);
begin
  FFillDisabled.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetFillDown(const Value: TTMSFNCGraphicsFill);
begin
  FFillDown.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetFillHover(const Value: TTMSFNCGraphicsFill);
begin
  FFillHover.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetFillSelected(const Value: TTMSFNCGraphicsFill);
begin
  FFillSelected.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetHorizontalSpacing(
  const Value: Single);
begin
  if FHorizontalSpacing <> Value then
  begin
    FHorizontalSpacing := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomSelectorAppearance.SetSeparatorStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  FSeparatorStroke.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  FStroke.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetStrokeDisabled(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStrokeDisabled.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetStrokeDown(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStrokeDown.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetStrokeHover(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStrokeHover.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetStrokeSelected(
  const Value: TTMSFNCGraphicsStroke);
begin
  FStrokeSelected.Assign(Value);
end;

procedure TTMSFNCCustomSelectorAppearance.SetVerticalSpacing(const Value: Single);
begin
  if FVerticalSpacing <> Value then
  begin
    FVerticalSpacing := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomSelectorAppearance.StrokeChanged(Sender: TObject);
begin
  FOwner.InvalidateItems;
end;

{ TTMSFNCGraphicsPathPoint }

{$IFDEF LCLLIB}
class operator TTMSFNCCustomSelectorDisplayItem.=(z1, z2: TTMSFNCCustomSelectorDisplayItem)b: boolean;
begin
  Result := z1 = z2;
end;

class operator TTMSFNCCustomSelectorPositionItem.=(z1, z2: TTMSFNCCustomSelectorPositionItem)b: boolean;
begin
  Result := z1 = z2;
end;
{$ENDIF}

{$IFDEF WEBLIB}
function TTMSFNCCustomSelectorDisplayList.GetItem(Index: Integer): TTMSFNCCustomSelectorDisplayItem;
begin
  Result := TTMSFNCCustomSelectorDisplayItem(inherited Items[Index]);
end;

procedure TTMSFNCCustomSelectorDisplayList.SetItem(Index: Integer; const Value: TTMSFNCCustomSelectorDisplayItem);
var
  v: TTMSFNCCustomSelectorDisplayItem;
begin
  v := Value;
  inherited Items[Index] := v;
end;
{$ENDIF}

initialization
  RegisterClass(TTMSFNCCustomSelector);

end.
