program obadmin;

uses
  WEBLib.Forms,
  admUnit1 in 'admUnit1.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TMainForm, MainForm);
  Application.Run;
end.
