unit Bcl.TypInfo.Common;

{$I Bcl.Inc}

interface

uses
  TypInfo;

type
{$IFDEF PAS2JS}
  PTypeInfo = TypInfo.TTypeInfo;
{$ELSE}
  PTypeInfo = TypInfo.PTypeInfo;
{$ENDIF}

function GetTypeName_(TypeInfo: PTypeInfo): string;
function GetEnumName_(TypeInfo: PTypeInfo; Value: Integer): string;
function GetEnumValue_(TypeInfo: PTypeInfo; const Name: string): Integer;


implementation

function GetTypeName_(TypeInfo: PTypeInfo): string;
begin
  {$IFDEF PAS2JS}
  Result := TypeInfo.Name;
  {$ELSE}
  Result := TypInfo.GetTypeName(TypeInfo);
  {$ENDIF}
end;

function GetEnumName_(TypeInfo: PTypeInfo; Value: Integer): string;
begin
  {$IFDEF PAS2JS}
  Result := TTypeInfoEnum(TypeInfo).EnumType.IntToName[Value];
  {$ELSE}
  Result := TypInfo.GetEnumName(TypeInfo, Value);
  {$ENDIF}
end;

function GetEnumValue_(TypeInfo: PTypeInfo; const Name: string): Integer;
begin
  {$IFDEF PAS2JS}
  Result := TTypeInfoEnum(TypeInfo).EnumType.NameToInt[Name];
  {$ELSE}
  Result := TypInfo.GetEnumValue(TypeInfo, Name);
  {$ENDIF}
end;

end.