unit admUnit1;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.ExtCtrls, XData.Web.Client, XData.Web.Connection, DB,
  XData.Web.JsonDataset, XData.Web.Dataset,
  WEBLib.Grids, WEBLib.DBCtrls, WEBLib.TMSFNCTypes, WEBLib.TMSFNCUtils,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.DB,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCCustomPicker, WEBLib.TMSFNCDatePicker,
  WEBLib.Imaging.pngimage;

const
  cStorageTokenName = 'gardadmin-user-token';

type
  TMainForm = class(TForm)
    LoginPanel: TPanel;
    WebButton1: TButton;
    edtLogin: TEdit;
    edtPassword: TEdit;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    DataPanel: TPanel;
    WebPanel1: TPanel;
    WebLabel3: TLabel;
    SiteLabel: TLabel;
    WebLabel5: TLabel;
    UserLabel: TLabel;
    failedLoginPanel: TPanel;
    DatePicker: TTMSFNCDatePicker;
    xdConnection: TXDataWebConnection;
    XDataClient: TXDataWebClient;
    xdsobhodi: TXDataWebDataSet;
    WebImageControl1: TImageControl;
    WebImageControl2: TImageControl;
    xdsobhodiidr: TIntegerField;
    xdsobhodiregdatum: TDateTimeField;
    xdsobhodiregura: TDateTimeField;
    xdsobhodilokacija: TStringField;
    xdsobhodiipv4: TStringField;
    xdsobhodisite: TStringField;
    xdsobhodinaslov: TStringField;
    xdsobhodilongi: TStringField;
    xdsobhodilati: TStringField;
    xdsobhodiuporabnik: TStringField;
    dsobhodi: TDataSource;
    WebDBGrid1: TDBGrid;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure xdConnectionRequest(Args: TXDataWebConnectionRequest);
    procedure UserLabelClick(Sender: TObject);
    procedure DatePickerDateSelected(Sender: TObject; ADate: TDate);
    procedure WebTimer1Timer(Sender: TObject);
    procedure xduporabnikAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  protected
    procedure LoadDFMValues; override;
  public
    { Public declarations }
    Username : string;
    Sitename : string;
    function GetToken: string;
    procedure CheckLogin;
    procedure PrepareData;
    procedure Logout;
  end;

var
  MainForm: TMainForm;

implementation

procedure TMainForm.CheckLogin;
var t : string;
begin
  t:=GetToken;
  if t = '' then begin
    //Showmessage('Not logged in!')
    failedLoginPanel.Visible:=True;
  end
  else
  begin
    //EnableTasks;
    //Showmessage('User:' + t);
    Username:=edtLogin.Text;
    LoginPanel.Visible:=False;
    failedLoginPanel.Visible:=False;
    DataPanel.Top:=0;
    DataPanel.Left:=0;
    DataPanel.Visible:=True;
    UserLabel.Caption:=Username+' - Odjava';
    DataPanel.Align:=alClient;
    PrepareData;
  end;
end;

function TMainForm.GetToken: string;
begin
  if not JS.IsNull(window.localStorage.getItem(cStorageTokenName)) then
    Result := window.localStorage.getItem(cStorageTokenName)
  else
    Result := '';
end;

procedure TMainForm.Logout;
begin
  LoginPanel.Visible:=True;
  DataPanel.Visible:=False;
  window.localStorage.RemoveItem(cStorageTokenName);
  if xdsobhodi.Active then xdsobhodi.Active:=False;
  //if xduporabnik.Active then xduporabnik.Active:=False;
  edtLogin.Text:='';
  edtPassword.Text:='';
end;

procedure TMainForm.PrepareData;
begin
  XDataClient.RawInvoke('IAuthService.GetSite', [Username],
    procedure(Response: TXDataClientResponse)
    var
      Site: string;
    begin
      //Showmessage(JS.ToString(JS.ToObject(Response.Result)['value']));
      Sitename := JS.ToString(JS.ToObject(Response.Result)['value']);
      SiteLabel.Caption:=Sitename;
      DatePicker.SelectedDate:=Date;
      DatePickerDateSelected(Self,Date);
    end
  );
  //https://xdata.tpclucija.si/xdata/uporabniki?$filter=username%20eq%20%27admin%27&$expand=site
end;

procedure TMainForm.DatePickerDateSelected(Sender: TObject; ADate: TDate);
var fs, ds : string;
begin
  //
  if DataPanel.visible then begin
     if xdsobhodi.Active then xdsobhodi.Active:=False;
     ds:=formatdatetime('yyyy-mm-dd', ADate);
     fs:='$filter=regdatum eq '+QuotedStr(ds);
     if Sitelabel.Caption<>'ALL' then
       fs:=fs+'and site eq '+QuotedStr(Sitelabel.Caption);
     xdsobhodi.QueryString:=fs;
     xdsobhodi.Load;
  end;
end;

procedure TMainForm.UserLabelClick(Sender: TObject);
begin
  Logout;
end;

procedure TMainForm.WebButton1Click(Sender: TObject);
begin
  XDataClient.RawInvoke('IAuthService.Login', [edtLogin.Text, edtPassword.Text],
    procedure(Response: TXDataClientResponse)
    var
      Token: string;
    begin
      Token := JS.ToString(JS.ToObject(Response.Result)['value']);
      window.localStorage.setItem(cStorageTokenName, Token);
      CheckLogin;
    end
  );
end;

procedure TMainForm.WebFormCreate(Sender: TObject);
var w,h : Longint;
begin
  w:=window.innerwidth;
  h:=window.innerheight;
  LoginPanel.Left:=(w div 2)-(LoginPanel.Width div 2);
  LoginPanel.Top:=(h div 2)-(LoginPanel.Height div 2);
  //DatePicker.SelectedDate:=Date;
end;

procedure TMainForm.WebTimer1Timer(Sender: TObject);
begin
//  Webtimer1.Enabled:=False;
end;

procedure TMainForm.xdConnectionRequest(Args: TXDataWebConnectionRequest);
begin
  // BREZ TEGA NE DELA AVTORIZACIJA
  if GetToken<>'' then
     Args.Request.Headers.SetValue('Authorization', 'Bearer ' + GetToken);
end;

procedure TMainForm.xduporabnikAfterOpen(DataSet: TDataSet);
begin
  // xdsuporabnik.
  //showmessage(xduporabnik.FieldByName('site.idsite').AsString);
end;

procedure TMainForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DataPanel := TPanel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  SiteLabel := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  UserLabel := TLabel.Create(Self);
  WebImageControl2 := TImageControl.Create(Self);
  DatePicker := TTMSFNCDatePicker.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  LoginPanel := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebButton1 := TButton.Create(Self);
  edtLogin := TEdit.Create(Self);
  edtPassword := TEdit.Create(Self);
  failedLoginPanel := TPanel.Create(Self);
  xdConnection := TXDataWebConnection.Create(Self);
  XDataClient := TXDataWebClient.Create(Self);
  xdsobhodi := TXDataWebDataSet.Create(Self);
  xdsobhodiidr := TIntegerField.Create(Self);
  xdsobhodiregdatum := TDateTimeField.Create(Self);
  xdsobhodiregura := TDateTimeField.Create(Self);
  xdsobhodilokacija := TStringField.Create(Self);
  xdsobhodiipv4 := TStringField.Create(Self);
  xdsobhodisite := TStringField.Create(Self);
  xdsobhodinaslov := TStringField.Create(Self);
  xdsobhodilongi := TStringField.Create(Self);
  xdsobhodilati := TStringField.Create(Self);
  xdsobhodiuporabnik := TStringField.Create(Self);
  dsobhodi := TDataSource.Create(Self);

  DataPanel.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  SiteLabel.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  UserLabel.BeforeLoadDFMValues;
  WebImageControl2.BeforeLoadDFMValues;
  DatePicker.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  LoginPanel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  edtLogin.BeforeLoadDFMValues;
  edtPassword.BeforeLoadDFMValues;
  failedLoginPanel.BeforeLoadDFMValues;
  xdConnection.BeforeLoadDFMValues;
  XDataClient.BeforeLoadDFMValues;
  xdsobhodi.BeforeLoadDFMValues;
  xdsobhodiidr.BeforeLoadDFMValues;
  xdsobhodiregdatum.BeforeLoadDFMValues;
  xdsobhodiregura.BeforeLoadDFMValues;
  xdsobhodilokacija.BeforeLoadDFMValues;
  xdsobhodiipv4.BeforeLoadDFMValues;
  xdsobhodisite.BeforeLoadDFMValues;
  xdsobhodinaslov.BeforeLoadDFMValues;
  xdsobhodilongi.BeforeLoadDFMValues;
  xdsobhodilati.BeforeLoadDFMValues;
  xdsobhodiuporabnik.BeforeLoadDFMValues;
  dsobhodi.BeforeLoadDFMValues;
  try
    Name := 'MainForm';
    Left := 0;
    Top := 0;
    Width := 1175;
    Height := 775;
    CSSLibrary := cssBootstrap;
    ElementClassName := 'login-form';
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Tahoma';
    Font.Style := [];
    TabOrder := 1;
    SetEvent(Self, Self, 'OnCreate', 'WebFormCreate');
    DataPanel.SetParentComponent(Self);
    DataPanel.Name := 'DataPanel';
    DataPanel.Left := 8;
    DataPanel.Top := 8;
    DataPanel.Width := 1137;
    DataPanel.Height := 759;
    DataPanel.BorderColor := clSilver;
    DataPanel.BorderStyle := bsSingle;
    DataPanel.ChildOrder := 1;
    DataPanel.Visible := False;
    WebPanel1.SetParentComponent(DataPanel);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1137;
    WebPanel1.Height := 60;
    WebPanel1.Align := alTop;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ElementBodyClassName := 'card';
    WebPanel1.ElementFont := efCSS;
    WebLabel3.SetParentComponent(WebPanel1);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 88;
    WebLabel3.Top := 16;
    WebLabel3.Width := 63;
    WebLabel3.Height := 19;
    WebLabel3.Caption := 'Lokacija:';
    SiteLabel.SetParentComponent(WebPanel1);
    SiteLabel.Name := 'SiteLabel';
    SiteLabel.Left := 176;
    SiteLabel.Top := 16;
    SiteLabel.Width := 63;
    SiteLabel.Height := 19;
    SiteLabel.Caption := 'SiteLabel';
    WebLabel5.SetParentComponent(WebPanel1);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 890;
    WebLabel5.Top := 16;
    WebLabel5.Width := 79;
    WebLabel5.Height := 19;
    WebLabel5.Anchors := [akTop,akRight];
    WebLabel5.Caption := 'Uporabnik:';
    UserLabel.SetParentComponent(WebPanel1);
    UserLabel.Name := 'UserLabel';
    UserLabel.Left := 991;
    UserLabel.Top := 16;
    UserLabel.Width := 69;
    UserLabel.Height := 19;
    UserLabel.Anchors := [akTop,akRight];
    UserLabel.Caption := 'UserLabel';
    UserLabel.Font.Charset := DEFAULT_CHARSET;
    UserLabel.Font.Color := clWindowText;
    UserLabel.Font.Height := -16;
    UserLabel.Font.Name := 'Tahoma';
    UserLabel.Font.Style := [fsUnderline];
    UserLabel.ParentFont := False;
    SetEvent(UserLabel, Self, 'OnClick', 'UserLabelClick');
    WebImageControl2.SetParentComponent(WebPanel1);
    WebImageControl2.Name := 'WebImageControl2';
    WebImageControl2.Left := 16;
    WebImageControl2.Top := 9;
    WebImageControl2.Width := 48;
    WebImageControl2.Height := 48;
    WebImageControl2.ChildOrder := 5;
    WebImageControl2.Picture.LoadFromFile('admUnit1.WebPanel1.WebImageControl2.Picture.png');
    DatePicker.SetParentComponent(WebPanel1);
    DatePicker.Name := 'DatePicker';
    DatePicker.Left := 400;
    DatePicker.Top := 16;
    DatePicker.Width := 137;
    DatePicker.Height := 22;
    DatePicker.AdaptToStyle := True;
    DatePicker.ParentDoubleBuffered := False;
    DatePicker.DoubleBuffered := True;
    DatePicker.TabOrder := 0;
    DatePicker.FirstDay := 1;
    DatePicker.BadgeSize := 14;
    DatePicker.Header.Fill.Color := 15000804;
    DatePicker.Header.Font.Charset := DEFAULT_CHARSET;
    DatePicker.Header.Font.Color := clWindowText;
    DatePicker.Header.Font.Height := -11;
    DatePicker.Header.Font.Name := 'Tahoma';
    DatePicker.Header.Font.Style := [];
    DatePicker.Footer.Fill.Color := 15000804;
    DatePicker.Footer.Font.Charset := DEFAULT_CHARSET;
    DatePicker.Footer.Font.Color := clWindowText;
    DatePicker.Footer.Font.Height := -11;
    DatePicker.Footer.Font.Name := 'Tahoma';
    DatePicker.Footer.Font.Style := [];
    DatePicker.DropDownWidth := 200.000000000000000000;
    DatePicker.DropDownHeight := 180.000000000000000000;
    SetEvent(DatePicker, Self, 'OnDateSelected', 'DatePickerDateSelected');
    DatePicker.SelectedDate := 43885.000000000000000000;
    DatePicker.WeekNumberAppearance.Font.Charset := DEFAULT_CHARSET;
    DatePicker.WeekNumberAppearance.Font.Color := clWindowText;
    DatePicker.WeekNumberAppearance.Font.Height := -11;
    DatePicker.WeekNumberAppearance.Font.Name := 'Tahoma';
    DatePicker.WeekNumberAppearance.Font.Style := [];
    DatePicker.WeekNumberAppearance.Fill.Color := -1;
    DatePicker.WeekNumberAppearance.Stroke.Color := -1;
    DatePicker.DateAppearance.Fill.Color := -1;
    DatePicker.DateAppearance.Font.Charset := DEFAULT_CHARSET;
    DatePicker.DateAppearance.Font.Color := clWindowText;
    DatePicker.DateAppearance.Font.Height := -11;
    DatePicker.DateAppearance.Font.Name := 'Tahoma';
    DatePicker.DateAppearance.Font.Style := [];
    DatePicker.DateAppearance.Stroke.Color := -1;
    DatePicker.DateAppearance.SelectedFill.Color := 16748574;
    DatePicker.DateAppearance.SelectedFont.Charset := DEFAULT_CHARSET;
    DatePicker.DateAppearance.SelectedFont.Color := clWhite;
    DatePicker.DateAppearance.SelectedFont.Height := -11;
    DatePicker.DateAppearance.SelectedFont.Name := 'Tahoma';
    DatePicker.DateAppearance.SelectedFont.Style := [];
    DatePicker.DateAppearance.SelectedStroke.Color := 16748574;
    DatePicker.DateAppearance.DisabledFill.Color := -1;
    DatePicker.DateAppearance.DisabledFont.Charset := DEFAULT_CHARSET;
    DatePicker.DateAppearance.DisabledFont.Color := 13882323;
    DatePicker.DateAppearance.DisabledFont.Height := -11;
    DatePicker.DateAppearance.DisabledFont.Name := 'Tahoma';
    DatePicker.DateAppearance.DisabledFont.Style := [];
    DatePicker.DateAppearance.DisabledStroke.Color := -1;
    DatePicker.DateAppearance.DateBeforeFill.Color := -1;
    DatePicker.DateAppearance.DateBeforeFont.Charset := DEFAULT_CHARSET;
    DatePicker.DateAppearance.DateBeforeFont.Color := clMedGray;
    DatePicker.DateAppearance.DateBeforeFont.Height := -11;
    DatePicker.DateAppearance.DateBeforeFont.Name := 'Tahoma';
    DatePicker.DateAppearance.DateBeforeFont.Style := [];
    DatePicker.DateAppearance.DateBeforeStroke.Color := -1;
    DatePicker.DateAppearance.DateAfterFill.Color := -1;
    DatePicker.DateAppearance.DateAfterFont.Charset := DEFAULT_CHARSET;
    DatePicker.DateAppearance.DateAfterFont.Color := clMedGray;
    DatePicker.DateAppearance.DateAfterFont.Height := -11;
    DatePicker.DateAppearance.DateAfterFont.Name := 'Tahoma';
    DatePicker.DateAppearance.DateAfterFont.Style := [];
    DatePicker.DateAppearance.DateAfterStroke.Color := -1;
    DatePicker.DateAppearance.TodayFill.Color := -1;
    DatePicker.DateAppearance.TodayFont.Charset := DEFAULT_CHARSET;
    DatePicker.DateAppearance.TodayFont.Color := clRed;
    DatePicker.DateAppearance.TodayFont.Height := -11;
    DatePicker.DateAppearance.TodayFont.Name := 'Tahoma';
    DatePicker.DateAppearance.TodayFont.Style := [];
    DatePicker.DateAppearance.TodayStroke.Color := -1;
    DatePicker.DateAppearance.BadgeFill.Color := clRed;
    DatePicker.DateAppearance.BadgeFont.Charset := DEFAULT_CHARSET;
    DatePicker.DateAppearance.BadgeFont.Color := clWhite;
    DatePicker.DateAppearance.BadgeFont.Height := -9;
    DatePicker.DateAppearance.BadgeFont.Name := 'Tahoma';
    DatePicker.DateAppearance.BadgeFont.Style := [];
    DatePicker.DateAppearance.BadgeStroke.Color := clRed;
    DatePicker.DayNameAppearance.Fill.Color := -1;
    DatePicker.DayNameAppearance.Font.Charset := DEFAULT_CHARSET;
    DatePicker.DayNameAppearance.Font.Color := clWindowText;
    DatePicker.DayNameAppearance.Font.Height := -11;
    DatePicker.DayNameAppearance.Font.Name := 'Tahoma';
    DatePicker.DayNameAppearance.Font.Style := [];
    DatePicker.DayNameAppearance.Stroke.Color := -1;
    WebDBGrid1.SetParentComponent(DataPanel);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 0;
    WebDBGrid1.Top := 60;
    WebDBGrid1.Width := 1105;
    WebDBGrid1.Height := 699;
    WebDBGrid1.Align := alLeft;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'idr';
      Title := 'ID';
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'regdatum';
      Title := 'Datum';
      Width := 85;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'regura';
      Title := 'Ura';
      Width := 97;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'lokacija';
      Title := 'Lokacija';
      Width := 70;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'site';
      Title := 'Objekt';
      Width := 170;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'naslov';
      Title := 'Naslov';
      Width := 170;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'longi';
      Title := 'GPS Lon';
      Width := 100;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'lati';
      Title := 'GPS Lati';
      Width := 100;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'uporabnik';
      Title := 'Uporabnik';
      Width := 100;
    end;
    WebDBGrid1.DataSource := dsobhodi;
    WebDBGrid1.FixedCols := 1;
    WebDBGrid1.TabOrder := 1;
    WebDBGrid1.ColWidths[0] := 20;
    WebDBGrid1.ColWidths[1] := 64;
    WebDBGrid1.ColWidths[2] := 85;
    WebDBGrid1.ColWidths[3] := 97;
    WebDBGrid1.ColWidths[4] := 70;
    WebDBGrid1.ColWidths[5] := 170;
    WebDBGrid1.ColWidths[6] := 170;
    WebDBGrid1.ColWidths[7] := 100;
    WebDBGrid1.ColWidths[8] := 100;
    WebDBGrid1.ColWidths[9] := 100;
    LoginPanel.SetParentComponent(Self);
    LoginPanel.Name := 'LoginPanel';
    LoginPanel.Left := 464;
    LoginPanel.Top := 404;
    LoginPanel.Width := 479;
    LoginPanel.Height := 269;
    LoginPanel.Anchors := [];
    LoginPanel.BorderColor := clSilver;
    LoginPanel.BorderStyle := bsSingle;
    LoginPanel.ChildOrder := 5;
    LoginPanel.ElementFont := efCSS;
    WebLabel1.SetParentComponent(LoginPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 144;
    WebLabel1.Top := 67;
    WebLabel1.Width := 77;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'Username:';
    WebLabel1.ElementClassName := 'bottom-text';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.ParentFont := False;
    WebLabel2.SetParentComponent(LoginPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 144;
    WebLabel2.Top := 99;
    WebLabel2.Width := 73;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'Password:';
    WebLabel2.ElementClassName := 'bottom-text';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.ParentFont := False;
    WebImageControl1.SetParentComponent(LoginPanel);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 24;
    WebImageControl1.Top := 56;
    WebImageControl1.Width := 100;
    WebImageControl1.Height := 89;
    WebImageControl1.ChildOrder := 6;
    WebImageControl1.Picture.LoadFromFile('admUnit1.LoginPanel.WebImageControl1.Picture.png');
    WebButton1.SetParentComponent(LoginPanel);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 176;
    WebButton1.Top := 152;
    WebButton1.Width := 129;
    WebButton1.Height := 49;
    WebButton1.Caption := 'Login';
    WebButton1.ChildOrder := 4;
    WebButton1.ElementClassName := 'btn btn-warning shadow';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -16;
    WebButton1.Font.Name := 'Tahoma';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    edtLogin.SetParentComponent(LoginPanel);
    edtLogin.Name := 'edtLogin';
    edtLogin.Left := 240;
    edtLogin.Top := 64;
    edtLogin.Width := 153;
    edtLogin.Height := 24;
    edtLogin.ChildOrder := 4;
    edtLogin.ElementClassName := 'txtb';
    edtLogin.Font.Charset := DEFAULT_CHARSET;
    edtLogin.Font.Color := clWindowText;
    edtLogin.Font.Height := -16;
    edtLogin.Font.Name := 'Tahoma';
    edtLogin.Font.Style := [];
    edtLogin.ParentFont := False;
    edtPassword.SetParentComponent(LoginPanel);
    edtPassword.Name := 'edtPassword';
    edtPassword.Left := 240;
    edtPassword.Top := 96;
    edtPassword.Width := 153;
    edtPassword.Height := 24;
    edtPassword.ChildOrder := 1;
    edtPassword.ElementClassName := 'txtb';
    edtPassword.Font.Charset := DEFAULT_CHARSET;
    edtPassword.Font.Color := clWindowText;
    edtPassword.Font.Height := -16;
    edtPassword.Font.Name := 'Tahoma';
    edtPassword.Font.Style := [];
    edtPassword.ParentFont := False;
    edtPassword.PasswordChar := '*';
    failedLoginPanel.SetParentComponent(LoginPanel);
    failedLoginPanel.Name := 'failedLoginPanel';
    failedLoginPanel.Left := 72;
    failedLoginPanel.Top := 224;
    failedLoginPanel.Width := 345;
    failedLoginPanel.Height := 36;
    failedLoginPanel.BorderColor := clSilver;
    failedLoginPanel.BorderStyle := bsSingle;
    failedLoginPanel.Caption := 'Login failed !';
    failedLoginPanel.ChildOrder := 5;
    failedLoginPanel.Color := 8421631;
    failedLoginPanel.Visible := False;
    xdConnection.SetParentComponent(Self);
    xdConnection.Name := 'xdConnection';
    xdConnection.URL := 'https://xdata.tpclucija.si/xdata/';
    xdConnection.Connected := True;
    SetEvent(xdConnection, Self, 'OnRequest', 'xdConnectionRequest');
    xdConnection.Left := 56;
    xdConnection.Top := 136;
    XDataClient.SetParentComponent(Self);
    XDataClient.Name := 'XDataClient';
    XDataClient.Connection := xdConnection;
    XDataClient.Left := 144;
    XDataClient.Top := 137;
    xdsobhodi.SetParentComponent(Self);
    xdsobhodi.Name := 'xdsobhodi';
    xdsobhodi.EntitySetName := 'registracije';
    xdsobhodi.Connection := xdConnection;
    xdsobhodi.Left := 144;
    xdsobhodi.Top := 232;
    xdsobhodiidr.SetParentComponent(xdsobhodi);
    xdsobhodiidr.Name := 'xdsobhodiidr';
    xdsobhodiidr.FieldName := 'idr';
    xdsobhodiidr.Required := True;
    xdsobhodiregdatum.SetParentComponent(xdsobhodi);
    xdsobhodiregdatum.Name := 'xdsobhodiregdatum';
    xdsobhodiregdatum.FieldName := 'regdatum';
    xdsobhodiregdatum.Required := True;
    xdsobhodiregdatum.DisplayFormat := 'dd.mm.yyyy';
    xdsobhodiregura.SetParentComponent(xdsobhodi);
    xdsobhodiregura.Name := 'xdsobhodiregura';
    xdsobhodiregura.FieldName := 'regura';
    xdsobhodiregura.Required := True;
    xdsobhodiregura.DisplayFormat := 'hh:nn';
    xdsobhodilokacija.SetParentComponent(xdsobhodi);
    xdsobhodilokacija.Name := 'xdsobhodilokacija';
    xdsobhodilokacija.FieldName := 'lokacija';
    xdsobhodilokacija.Required := True;
    xdsobhodilokacija.Size := 50;
    xdsobhodiipv4.SetParentComponent(xdsobhodi);
    xdsobhodiipv4.Name := 'xdsobhodiipv4';
    xdsobhodiipv4.FieldName := 'ipv4';
    xdsobhodiipv4.Size := 50;
    xdsobhodisite.SetParentComponent(xdsobhodi);
    xdsobhodisite.Name := 'xdsobhodisite';
    xdsobhodisite.FieldName := 'site';
    xdsobhodisite.Size := 50;
    xdsobhodinaslov.SetParentComponent(xdsobhodi);
    xdsobhodinaslov.Name := 'xdsobhodinaslov';
    xdsobhodinaslov.FieldName := 'naslov';
    xdsobhodinaslov.Size := 50;
    xdsobhodilongi.SetParentComponent(xdsobhodi);
    xdsobhodilongi.Name := 'xdsobhodilongi';
    xdsobhodilongi.FieldName := 'longi';
    xdsobhodilongi.Size := 50;
    xdsobhodilati.SetParentComponent(xdsobhodi);
    xdsobhodilati.Name := 'xdsobhodilati';
    xdsobhodilati.FieldName := 'lati';
    xdsobhodilati.Size := 50;
    xdsobhodiuporabnik.SetParentComponent(xdsobhodi);
    xdsobhodiuporabnik.Name := 'xdsobhodiuporabnik';
    xdsobhodiuporabnik.FieldName := 'uporabnik';
    xdsobhodiuporabnik.Size := 50;
    dsobhodi.SetParentComponent(Self);
    dsobhodi.Name := 'dsobhodi';
    dsobhodi.DataSet := xdsobhodi;
    dsobhodi.Left := 144;
    dsobhodi.Top := 288;
  finally
    DataPanel.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    SiteLabel.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    UserLabel.AfterLoadDFMValues;
    WebImageControl2.AfterLoadDFMValues;
    DatePicker.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    LoginPanel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    edtLogin.AfterLoadDFMValues;
    edtPassword.AfterLoadDFMValues;
    failedLoginPanel.AfterLoadDFMValues;
    xdConnection.AfterLoadDFMValues;
    XDataClient.AfterLoadDFMValues;
    xdsobhodi.AfterLoadDFMValues;
    xdsobhodiidr.AfterLoadDFMValues;
    xdsobhodiregdatum.AfterLoadDFMValues;
    xdsobhodiregura.AfterLoadDFMValues;
    xdsobhodilokacija.AfterLoadDFMValues;
    xdsobhodiipv4.AfterLoadDFMValues;
    xdsobhodisite.AfterLoadDFMValues;
    xdsobhodinaslov.AfterLoadDFMValues;
    xdsobhodilongi.AfterLoadDFMValues;
    xdsobhodilati.AfterLoadDFMValues;
    xdsobhodiuporabnik.AfterLoadDFMValues;
    dsobhodi.AfterLoadDFMValues;
  end;
end;

end.
